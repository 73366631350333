import React, { Component } from "react";
import {
  Input,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Container,
} from "reactstrap";
import * as Constdata from "../../Constants/ApiConstants";
// import Header from "../HeaderComponent/Header";
import SerraError from "../CommonComponent/Error";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import SerraLoader from "../CommonComponent/Loader";
import { connect } from "react-redux";
import * as message from "../../Constants/MessageConstant";

const options = [
  {
    label: "SELECT",
    value: "SELECT",
  },
  {
    label: "AWS",
    value: "AWS",
  },
  {
    label: "AZURE",
    value: "AZURE",
  },
];

class Signin extends Component {
  constructor(props) {
    super(props);
    this.processResponse = this.processResponse.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleAliasChange = this.handleAliasChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onShowAlert = this.onShowAlert.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleLoginRetry = this.toggleLoginRetry.bind(this);
    this.handleForgot = this.handleForgot.bind(this);
    this.handleFotgotResponse = this.handleFotgotResponse.bind(this);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.handleSendEmailRequest = this.handleSendEmailRequest.bind(this);
    this.handleProviderChange = this.handleProviderChange.bind(this);
    this.onTermsAndConditionsClick = this.onTermsAndConditionsClick.bind(this);
    this.onPrivacyPolicyClick = this.onPrivacyPolicyClick.bind(this);
    this.state = {
      aliasUsername: "",
      username: "",
      password: "",
      check: false,
      hidden: true,
      visible: "",
      message: "",
      alertMsg: false,
      errorMsg: "Email ID and/or Password is invalid",
      data: "",
      modal: false,
      errormessage: "",
      errorPopup: false,
      Changeusername: "",
      disableSignIn: true,
      modalLoader: false,
      showLoginRetry: false,
      forceLogout: false,
      providerName: "",
      loggedInUser: "",
    };
  }
  componentDidMount() {
    console.log("location");
    ReactGA.initialize(message.MeasurementId);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Sign In",
    });
    this.props.registerListners();
    fetch(Constdata.SESSIONACTIVE_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          fetch(Constdata.ISPLANSELECTED_URL, {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json;charset=UTF-8",
            },
          })
            .then((response) => response.json())
            .then((responseJson) => {
              this.planprocessResponse(responseJson);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      })
      .catch((error) => {});
  }

  planprocessResponse = (response) => {
    if (response.success === true && response.data.landingPage !== "card") {
      this.setState({ data: response.data });

      if (response.data.landingPage === "report") {
        this.props.history.push({
          pathname: `/${response.data.landingPage}`,
          state: { data: this.state.data.data },
        });
      } else {
        this.props.history.push({
          pathname: `/${response.data.landingPage}`,
          state: { data: this.state.data },
        });
      }
    } else if (response.success === false) {
      this.setState({ errorMsg: response.message });
    }
  };

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  toggleLoginRetry = () => {
    this.setState((prevState) => ({
      showLoginRetry: !prevState.showLoginRetry,
    }));
    this.setState({ disableSignIn: false });
  };
  handleForgot = (event) => {
    const { Changeusername } = this.state;
    event.preventDefault();
    const payload = {
      username: Changeusername,
    };
    fetch(Constdata.RESETPASSWORD_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.handleFotgotResponse(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  handleFotgotResponse = (response) => {
    if (response.success === true) {
      this.setState({ errorPopup: true, errormessage: response.message });
    } else {
      this.setState({ errorPopup: true, errormessage: response.message });
    }
  };
  handlePasswordChange = async (e) => {
    await this.setState({ password: e.target.value });
    var usernamePresent = false;
    var passPresent = false;
    if (this.state.username) {
      usernamePresent = true;
    }
    if (this.state.password) {
      passPresent = true;
    }
    await this.setState({ disableSignIn: !(usernamePresent && passPresent) });
  };

  handleAliasChange = async (e) => {
    await this.setState({ aliasUsername: e.target.value });
    var usernamePresent = false;
    var passPresent = false;
    if (this.state.username) {
      usernamePresent = true;
    }
    if (this.state.password) {
      passPresent = true;
    }
    await this.setState({ disableSignIn: !(usernamePresent && passPresent) });
  };

  handleEmailChange = async (e) => {
    await this.setState({
      username: e.target.value,
      Changeusername: e.target.value,
    });
    var usernamePresent = false;
    var passPresent = false;
    if (this.state.username) {
      usernamePresent = true;
    }
    if (this.state.password) {
      passPresent = true;
    }
    await this.setState({ disableSignIn: !(usernamePresent && passPresent) });
  };
  handleSendEmailRequest = (e) => {
    this.setState({ Changeusername: e.target.value });
  };
  handleCheck = () => {
    this.setState({ check: true });
  };
  handleProviderChange = (e) => {
    console.log("Provider Selected!!");
    let name = e.target.value;
    console.log(" provider name: " + name);
    this.setState({ providerName: name });
  };

  handleSubmit = (event) => {
    this.setState({ disableSignIn: true, modalLoader: true });
    console.log("State: " + this.state.providerName);
    const { username, password, forceLogout, providerName } = this.state;
    if (event !== undefined) {
      event.preventDefault();
    }
    const payload = {
      username: username.toLowerCase(),
      password: password,
      forceLogout: forceLogout,
      providerName: this.state.providerName,
      aliasUsername: this.state.aliasUsername.toLowerCase(),
    };

    fetch(Constdata.LOGIN_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.processResponse(responseJson);

        payload.forceLogout = false;
        window.localStorage.setItem(
          "userPayload",
          btoa(JSON.stringify(payload))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };
  handleLoginRetry = async () => {
    await this.setState({ forceLogout: true });
    await this.handleSubmit();
  };
  processResponse = async (response) => {
    if (response.success === true) {
      await this.props.processLogin(true);
      window.localStorage.setItem(
        "loggedInUser",
        this.state.username.toLowerCase()
      );
      this.setState({
        visible: response.success,
        data: response.data,
      });
      this.state.aliasUsername !== ""
        ? this.setState({ loggedInUser: this.state.aliasUsername })
        : this.setState({ loggedInUser: this.state.loggedInUser });

      if (
        (response.orgInfo.plan === "Free Trial" ||
          response.orgInfo.plan === "FREE_TRIAL") &&
        response.orgInfo.loggedInUser !== "support@serralabs.com" &&
        response.orgInfo.userName !== "support@serralabs.com"
      ) {
        fetch(Constdata.GETALLACCOUNT_URL, {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
          .then((response) => response.json())
          .then(async (responseJson) => {
            this.setState({
              modalLoader: false,
            });

            let newDSList;
            const isEmptyAnalysis =
              responseJson.orgInfo.freeTrialAnalysisList.every(
                (type) => type === ""
              );
            if (responseJson.orgInfo.providerName === "AWS") {
              newDSList = responseJson.data.awsConfigs;
              if (newDSList && newDSList.length > 0 && !isEmptyAnalysis) {
                this.props.history.push({
                  pathname: `/dashboard`,
                  state: {
                    providerName: response.orgInfo.providerName,
                    step: 1,
                  },
                });
              } else {
                this.props.history.push({
                  pathname: `/free-trial`,
                  state: {
                    providerName: response.orgInfo.providerName,
                    step:
                      newDSList && newDSList.length > 0 && isEmptyAnalysis
                        ? 3
                        : 1,
                  },
                });
              }
            } else {
              newDSList = responseJson.data.configs;

              if (newDSList && newDSList.length > 0 && !isEmptyAnalysis) {
                this.props.history.push({
                  pathname: `/dashboard`,
                  state: {
                    providerName: response.orgInfo.providerName,
                    step: 1,
                  },
                });
              } else {
                this.props.history.push({
                  pathname: `/free-trial`,
                  state: {
                    providerName: response.orgInfo.providerName,
                    step:
                      newDSList && newDSList.length > 0 && isEmptyAnalysis
                        ? 3
                        : 1,
                  },
                });
              }
            }
          });
      } else {
        this.setState({
          modalLoader: false,
        });
        if (response.data.landingPage === "report") {
          this.props.history.push({
            pathname: `/${
              response.orgInfo.loggedInUser === "support@serralabs.com"
                ? "dashboard"
                : response.data.landingPage
            }`,
            state: { data: this.state.data.data },
          });
        } else {
          this.props.history.push({
            pathname: `/${
              response.orgInfo.loggedInUser === "support@serralabs.com"
                ? "dashboard"
                : response.data.landingPage
            }`,
            state: { data: this.state.data },
          });
        }
      }
    } else {
      if (response.message === "Another user session is already active.") {
        this.setState({
          errorMsg: response.message,
          modalLoader: false,
          showLoginRetry: true,
        });
      } else {
        await this.setState({
          errorMsg: response.message,
          visible: response.success,
          disableSignIn: false,
          modalLoader: false,
        });
        await this.onShowAlert();
      }
    }
  };
  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  };
  onShowAlert = () => {
    this.setState({ alertMsg: true }, () => {
      window.setTimeout(() => {
        this.setState({ alertMsg: false });
      }, 5000);
    });
  };
  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event });
    this.toggle();
  };

  onTermsAndConditionsClick() {
    window.open(this.props.companyDetails.TermsAndConditions);
  }

  onPrivacyPolicyClick() {
    window.open(this.props.companyDetails.PrivacyPolicy);
  }

  render() {
    const { visible, Changeusername } = this.state;
    const message =
      visible === false ? (
        <Alert color="danger" isOpen={this.state.alertMsg}>
          {this.state.errorMsg}
        </Alert>
      ) : (
        ""
      );

    const Logo = <img src={this.props.companyDetails.LOGO} alt="" />;

    return (
      <>
        {/* <Header processLogout={this.props.processLogut} registerListners={this.props.registerListners} providerName = {this.state.providerName}/> */}
        <Container fluid style={{ paddingLeft: "0", paddingRight: "0" }}>
          <Row>
            <Col
              className={
                this.props.companyDetails.CompanyName === "Serra Labs"
                  ? "signInImg"
                  : "signInNoSerra"
              }
              sm="8"
            >
              <div className="textPosition">
                {this.props.companyDetails.ServiceNameFullName}
                {this.props.companyDetails.CompanyName === "Serra Labs" ? (
                  <div className="textPositionHP">
                    OPTIMIZE YOUR CLOUD <br /> REDUCE COST, IMPROVE PERFORMANCE
                  </div>
                ) : null}
              </div>
            </Col>
            <Col sm="4" className="signInForm">
              <div>
                {this.props.companyDetails.CompanyName === "Serra Labs" ? (
                  <div className="signInLogo">
                    <a href={this.props.companyDetails.Website} target="blank">
                      {Logo}
                    </a>
                  </div>
                ) : (
                  <div className="signInLogo" style={{ display: "block" }}>
                    {Logo}
                    {process.env.REACT_APP_IS_PoweredByName === "true" ? (
                      <div className="powerByDiv">Powered by Serra Labs</div>
                    ) : null}
                  </div>
                )}

                <div className="HeadingTitleSignIn">
                  {this.props.companyDetails.ServiceName} Sign In
                </div>
                {/* <div className="outer-container"> */}
                <div className="container">
                  <div>{message}</div>
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                      <label>
                        Email ID:<span className="requiredColor">*</span>
                      </label>
                      <Input
                        type="email"
                        className="form-control"
                        id="username"
                        placeholder="Enter Email ID"
                        value={this.state.username}
                        onChange={this.handleEmailChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        Password:<span className="requiredColor">*</span>
                      </label>
                      <InputGroup>
                        <Input
                          type={this.state.hidden ? "password" : "text"}
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="Enter Password"
                          value={this.state.password}
                          onChange={this.handlePasswordChange}
                          required
                        />
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i
                              className="fa fa-eye-slash"
                              onClick={this.toggleShow}
                            ></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </div>
                    <div className="form-group">
                      <label>
                        Provider Name:<span className="requiredColor">*</span>
                      </label>
                      <select
                        className="form-control"
                        value={this.state.fruit}
                        onChange={this.handleProviderChange}
                      >
                        {options.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>
                        Login As Another Email ID:<span>&nbsp;</span>
                        <span className="info-icon">
                          <i
                            className="fa fa-info-circle"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Login as allows a user to login as another user in the same organization"
                          />
                        </span>
                      </label>
                      <Input
                        type="email"
                        className="form-control"
                        id="aliasUsername"
                        placeholder="Enter Another Email ID"
                        value={this.state.aliasUsername}
                        onChange={this.handleAliasChange}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContents: "center",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div
                          className="chk_container"
                          style={{ marginTop: "4px" }}
                        >
                          <Input
                            className="form-check-input-nontable"
                            type="checkbox"
                            value={this.state.check}
                            onChange={this.handleCheck}
                          />
                          <span className="custom-checkbox"></span>{" "}
                        </div>
                        <label style={{ paddingLeft: "5px" }}>
                          Remember Me
                        </label>
                      </div>
                      <div
                        className="abc"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <Link to="" onClick={this.toggle}>
                          Forgot Password?
                        </Link>{" "}
                      </div>
                    </div>

                    <Button
                      type="submit"
                      id={
                        this.state.disableSignIn
                          ? "btnSinginSignup_disabled"
                          : "btnSinginSignup"
                      }
                      disabled={this.state.disableSignIn}
                      block
                    >
                      Sign In
                    </Button>
                  </form>
                  <div className="info-container">
                    Don't have an account?{" "}
                    <span className="sign-up-link">
                      <Link to={"/signup"}>Sign Up.</Link>
                    </span>
                  </div>
                  <div className="info-container">
                    By clicking the Sign in button, you agree to the{" "}
                    {this.props.companyDetails.CompanyName}{" "}
                    <span className="sign-up-link">
                      <Link onClick={this.onTermsAndConditionsClick}>
                        Terms &amp; Conditions
                      </Link>
                    </span>{" "}
                    and{" "}
                    <span className="sign-up-link">
                      <Link onClick={this.onPrivacyPolicyClick}>
                        Privacy Policy.
                      </Link>
                    </span>
                  </div>
                  <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    backdrop={"static"}
                    centered={true}
                  >
                    <ModalHeader
                      id="modalheader_list"
                      cssModule={{ "modal-title": "modalHeader" }}
                      toggle={this.toggle}
                    >
                      <div
                        className="modalCompanyName"
                        dangerouslySetInnerHTML={{
                          __html: message.SerraLabsModalHeader,
                        }}
                      />
                      <div className="modalTitle">Forgot Password?</div>
                    </ModalHeader>
                    <ModalBody>
                      <form onSubmit={this.handleForgot}>
                        <div className="form-group">
                          <Input
                            type="email"
                            className="form-control"
                            id="Changeusername"
                            placeholder="Enter Your Email Id"
                            value={this.state.Changeusername}
                            onChange={this.handleSendEmailRequest}
                            required
                          />
                        </div>
                        <Button
                          type="submit"
                          id={
                            !Changeusername
                              ? "btnSinginSignup_disabled"
                              : "btnSinginSignup"
                          }
                          value="Submit"
                          disabled={!Changeusername}
                          block
                        >
                          Send Email
                        </Button>
                      </form>
                    </ModalBody>
                  </Modal>
                  <Modal
                    size="lg"
                    isOpen={this.state.showLoginRetry}
                    toggle={this.toggleLoginRetry}
                    backdrop={"static"}
                    centered={true}
                  >
                    <ModalHeader
                      id="modalheader_list"
                      cssModule={{ "modal-title": "modalHeader" }}
                      toggle={this.toggleLoginRetry}
                    >
                      <div
                        className="modalCompanyName"
                        dangerouslySetInnerHTML={{
                          __html: message.SerraLabsModalHeader,
                        }}
                      />
                      <div className="modalTitle">Active Session</div>
                    </ModalHeader>
                    <ModalBody>
                      <div className="box-titel" id="modaldis_list">
                        <div>
                          <p>
                            There is another active session for user{" "}
                            {this.state.aliasUsername !== ""
                              ? this.state.aliasUsername
                              : this.state.username}
                          </p>
                        </div>
                      </div>
                      <form onSubmit={this.handleForgot}>
                        <div align="center">
                          <Button
                            id="confirmbtn_list"
                            style={{ width: "375px" }}
                            onClick={this.handleLoginRetry}
                          >
                            Terminate other session and continue
                          </Button>
                          <Button
                            id="canclebtn_list"
                            onClick={() => {
                              this.toggleLoginRetry();
                            }}
                          >
                            Will try again later
                          </Button>
                        </div>
                      </form>
                    </ModalBody>
                  </Modal>
                </div>
                {/* </div> */}
              </div>
            </Col>
          </Row>
        </Container>

        {this.state.errorPopup === true ? (
          <SerraError
            Errordata={this.state.errormessage}
            ErrorPopup={this.state.errorPopup}
            setErrorMethod={this.setErrorPopupState}
          ></SerraError>
        ) : (
          ""
        )}
        {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companyDetails: state.companyDetails.data,
  };
};

export default connect(mapStateToProps, null)(Signin);
