import React from "react";
import {
  Navbar,
  Nav,
  Modal,
  Form,
  FormGroup,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import ManageAccount from "../PPUComponent/MetricsDataSource";
import Preference from "../PPUComponent/Preferences";
import VMParkingPreferences from "../Parking/VMParkingPreferences";
import MegaMenu from "./MegaMenu";
import SerraError from "../CommonComponent/Error";
import SerraLoader from "../CommonComponent/Loader";
import { connect } from "react-redux";
import { setDataSourceRes } from "../../reducers/dataSourceResSlice";
import { Collapse } from "reactstrap";
import { getStringDate } from "../../Constants/CommonConstant";
import VMRecommendationPreferences from "../PPUComponent/VMRecommendationPreferences";
import OrganizationDetails from "../Organization/OrganizationDetails";
import OrganizationList from "../Organization/OrganizationList";
import OrganizationAnalysisHistory from "../Organization/OrganizationAnalysisHistory";
import CurrencySelection from "../PPUComponent/CurrencySelection";

const style = {
  marginLeft: "6px",
  width: "404px",
  fontSize: "20px",
};
class Header extends React.Component {
  constructor(props) {
    super(props);
    console.log("providername in props: " + this.props.providerName);
    this.checkRefresh = this.checkRefresh.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.redirectToHome = this.redirectToHome.bind(this);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.toggleRequest = this.toggleRequest.bind(this);
    this.toggleRequestResponse = this.toggleRequestResponse.bind(this);
    this.toggleIsRequestResponse = this.toggleIsRequestResponse.bind(this);
    this.toggleisRequest = this.toggleisRequest.bind(this);
    this.toggleManageModal = this.toggleManageModal.bind(this);
    this.togglePreferenceModal = this.togglePreferenceModal.bind(this);
    this.toggleParkingPreferenceModal =
      this.toggleParkingPreferenceModal.bind(this);
    this.toggleRecommendationPreferenceModal =
      this.toggleRecommendationPreferenceModal.bind(this);
    this.addDataSourcesResponse = this.addDataSourcesResponse.bind(this);
    this.addDataSourcesAzureResponse =
      this.addDataSourcesAzureResponse.bind(this);
    this.handleRecommendationPreferenceRequest =
      this.handleRecommendationPreferenceRequest.bind(this);
    this.toggleOrganizationModal = this.toggleOrganizationModal.bind(this);
    this.toggleOrganizationListModal =
      this.toggleOrganizationListModal.bind(this);
    this.deleteOrg = this.deleteOrg.bind(this);
    this.toggleOrganizationListStatusModal =
      this.toggleOrganizationListStatusModal.bind(this);
    this.viewOrgStatus = this.viewOrgStatus.bind(this);
    this.handleDeleteRedirect = this.handleDeleteRedirect.bind(this);
    this.isMenuShow = this.isMenuShow.bind(this);
    this.handleRecommendationDurationPreferenceRequest =
      this.handleRecommendationDurationPreferenceRequest.bind(this);
    this.freeTrialPlanSupport = this.freeTrialPlanSupport.bind(this);
    this.isSupportLogin = this.isSupportLogin.bind(this);
    this.toggleUpdateProviderModal = this.toggleUpdateProviderModal.bind(this);
    this.toggleCurrencySelectionModal =
      this.toggleCurrencySelectionModal.bind(this);
    this.handleCurrencySelectionRequest =
      this.handleCurrencySelectionRequest.bind(this);

    this.state = {
      isOpen: false,
      modal: false,
      deleteModal: false,
      errorPopup: false,
      errormessage: "",
      modalLoader: false,
      isErrorHeader: true,
      showManageDs: false,
      password: "",
      isRedirect: false,
      PPURequest: false,
      PPUisRequest: false,
      prefModal: false,
      parkingPerfModal: false,
      email: "",
      PPURequestSubmit: false,
      Confirm: "",
      isAdminUser: false,
      isOpenMenu: [],
      providerName: this.props.providerName,
      dashHeight: 0,
      isPlan: false,
      organizationModal: false,
      orgId: null,
      orgName: null,
      organizationListModal: false,
      deleteOrgId: null,
      organizationListStatusModal: false,
      organizationHistoryModal: false,
      deleteUserName: null,
      loggedInUser: this.props.loggedInUser,
      isFreeTrial: false,
      userName: false,
      updateProviderModal: false,
      loginProvider: this.props.providerName
        ? this.props.providerName.toUpperCase() === "AZURE"
          ? "AWS"
          : "AZURE"
        : "",
    };
  }
  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    });
  };
  toggleModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  toggleDeleteModal = () => {
    this.setState((prevState) => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  toggleManageModal = () => {
    this.setState((prevState) => ({
      showManageDs: !prevState.showManageDs,
    }));
  };

  togglePreferenceModal = () => {
    this.setState((prevState) => ({
      prefModal: !prevState.prefModal,
    }));
  };

  toggleParkingPreferenceModal = () => {
    this.setState((prevState) => ({
      parkingPerfModal: !prevState.parkingPerfModal,
    }));
  };

  toggleRecommendationPreferenceModal = () => {
    this.setState((prevState) => ({
      recommendationPerfModal: !prevState.recommendationPerfModal,
    }));
  };

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleCurrencySelectionModal = () => {
    this.setState((prevState) => ({
      currencySelectionModal: !prevState.currencySelectionModal,
    }));
  };

  handleLogout = async () => {
    this.setState({ modalLoader: true });
    await fetch(Constdata.LOGOUT_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.processResponse(responseJson);
        console.log(responseJson);
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
        });
      });
  };
  handleAccountDelete = () => {
    this.toggleModal();
    this.setState({ deleteModal: false, modalLoader: true });
    const payload = {
      secretPass: this.state.password,
      orgId: this.state.deleteOrgId,
    };
    fetch(Constdata.DELETE_ACCOUNT_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.processAccountDeleteResponse(responseJson);
        console.log(responseJson);
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
        });
      });
  };
  processResponse = (response) => {
    if (response.success === true) {
      if (this.props.processLogout) {
        this.props.processLogout();
      }
      this.props.history.push("/");
      this.setState({ modalLoader: false });
    } else if (response.success === false) {
      this.setState({
        isRedirect: true,
        errorPopup: true,
        errormessage: response.message,
        modalLoader: false,
      });
    } else if (response.status === 403 || response.status === 401) {
      if (this.props.processLogout) {
        this.props.processLogout();
      }
      this.props.history.push("/");
    } else {
      if (this.props.processLogout) {
        this.props.processLogout();
      }
      this.setState({
        modalLoader: false,
        errorPopup: true,
        errormessage: message.ServerError,
        isErrorHeader: false,
      });
    }
  };
  processAccountDeleteResponse = (response) => {
    if (response.success === true) {
      this.setState({
        modalLoader: false,
        deleteModal: true,
        errormessage: response.message,
      });
    } else if (response.success === false) {
      this.setState({
        isRedirect: true,
        deleteModal: false,
        modalLoader: false,
        errorPopup: true,
        errormessage: response.message,
      });
    } else if (response.status === 403 || response.status === 401) {
      if (this.props.processLogout) {
        this.props.processLogout();
      }
      this.setState({
        modalLoader: false,
        errorPopup: true,
        errormessage: message.SessionExpiredText,
        isErrorHeader: false,
      });
    } else {
      if (this.props.processLogout) {
        this.props.processLogout();
      }
      this.setState({
        modalLoader: false,
        errorPopup: true,
        errormessage: message.ServerError,
        isErrorHeader: false,
      });
    }
  };
  redirectToHome() {
    this.props.history.push("/");
  }
  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event });
    if (this.state.isRedirect === false) {
      this.props.history.push("/");
    }
  };
  toggleisRequest = () => {
    this.setState({ PPUisRequest: false });
    fetch(Constdata.ISPPUREQUESTED_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.toggleIsRequestResponse(responseJson);
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
        });
      });
  };
  toggleIsRequestResponse = (response) => {
    if (response.success === true) {
      this.setState({ Confirm: response.message, PPURequestSubmit: true });
    } else if (response.success === false && response.data !== null) {
      this.setState({ email: response.data, PPUisRequest: true });
    } else if (response.status === 403 || response.status === 401) {
      this.props.history.push("/");
    } else {
      this.setState({
        modalLoader: false,
        errorPopup: true,
        errormessage: message.ServerError,
        isErrorHeader: false,
      });
    }
  };
  toggleRequest = () => {
    this.setState({ PPURequest: false });
    fetch(Constdata.PPUREQUEST_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.toggleRequestResponse(responseJson);
        console.log(responseJson);
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
        });
      });
  };
  toggleRequestResponse = (response) => {
    if (response.success === true) {
      this.setState({ PPURequest: true, PPUisRequest: false });
    } else if (response.success === false) {
      this.setState({ errorPopup: true, errormessage: response.message });
    } else if (response.status === 403 || response.status === 401) {
      this.props.history.push("/");
    } else {
      this.setState({
        modalLoader: false,
        errorPopup: true,
        errormessage: message.ServerError,
        isErrorHeader: false,
      });
    }
  };

  componentDidMount() {
    this.setState({
      providerName: this.props.providerName,
      loggedInUser: this.props.loggedInUser,
    });
    var width = document.getElementById("logoWidth").offsetWidth;
    if (width > 60) {
      document
        .getElementById("titleCenter")
        .setAttribute("style", "padding-right:" + (width - 60) + "px;");
    }

    if (this.props.match.path === "/dashboard") {
      this.setState({ isOpenMenu: ["dashboard"] });
    } else if (
      this.props.match.path === "/manageOptimizations" ||
      this.props.match.path === "/manageParking" ||
      this.props.match.path === "/manageCleanup"
    ) {
      this.setState({ isOpenMenu: ["analysis"] });
    }

    var x = window.location.href;

    console.log("x", x);
    var noLogin = false;
    var pageAnc = document.getElementById("pageRef");
    var dashAnc = document.getElementById("dashRef");
    // var optAnc = document.getElementById("manageRef");
    // var optAncCon = document.getElementById("manageRefCont");
    // var dsRef = document.getElementById("dsRef");
    if (x.toLowerCase().includes("dashboard")) {
      dashAnc.style.display = "flex";
      pageAnc.style.display = "none";
      dashAnc.className = "menu-selected";
      //   dsRef.className = "manage-data-cont";
    } else if (
      x.toLowerCase().includes("manageoptimizations") ||
      x.toLowerCase().includes("manageparking") ||
      x.toLowerCase().includes("managecleanup")
    ) {
      dashAnc.style.display = "none";
      pageAnc.style.display = "flex";
      dashAnc.className = "menu-tab";
      //   dsRef.className = "manage-data-cont";
    } else if (x.toLowerCase().includes("managedatasource")) {
      dashAnc.style.display = "flex";
      pageAnc.style.display = "none";
      dashAnc.className = "menu-tab";
      //   dsRef.className = "manage-data-cont-selected";
    } else {
      dashAnc.style.display = "none";
      pageAnc.style.display = "flex";
      dashAnc.className = "menu-tab";
      noLogin = true;
      //   dsRef.className = "manage-data-cont";
    }

    fetch(Constdata.SESSIONACTIVE_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        console.log("session", responseJson);
        this.setState({
          isAdminUser: responseJson.orgInfo.isAdminUser,
          loggedInUser: responseJson.orgInfo.loggedInUser,
          orgId: responseJson.orgInfo.orgId,
          providerName: responseJson.orgInfo.providerName,
          isFreeTrial: responseJson.orgInfo.plan === "FREE_TRIAL",
          userName: responseJson.orgInfo.userName,
        });
        if (responseJson.success === true) {
          this.setState({
            isPlan:
              responseJson.orgInfo.plan !== undefined &&
              responseJson.orgInfo.plan !== ""
                ? true
                : false,
          });
          if (
            responseJson.orgInfo.plan !== undefined &&
            responseJson.orgInfo.plan !== "" &&
            responseJson.orgInfo.numConfigs !== null &&
            responseJson.orgInfo.numConfigs !== undefined &&
            responseJson.orgInfo.numConfigs === 0
          ) {
            if (
              noLogin === false &&
              (this.state.userName !== "support@serralabs.com" ||
                this.state.loggedInUser !== "support@serralabs.com")
            ) {
              await this.toggleManageModal();
            }
          }
        }
      })
      .catch((error) => {});
  }

  checkRefresh = async (type) => {
    if (
      this.props.checkRefresh !== undefined &&
      this.props.checkRefresh !== null
    ) {
      await this.props.checkRefresh(type);
    }
  };

  showList = (fnName) => {
    fetch(Constdata.SET_FUNCTION_URL + "/" + fnName, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ modalLoader: false });
        if (responseJson.success === true) {
          if (fnName === "rightsizing") {
            this.props.history.push({
              pathname: "/manageOptimizations",
              providerName: responseJson.orgInfo.providerName,
            });
          } else if (fnName === "cleanup") {
            this.props.history.push({ pathname: "/manageCleanup" });
          } else if (fnName === "parking") {
            this.props.history.push({ pathname: "/manageParking" });
          }
        } else if (responseJson.success === false) {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: responseJson.message,
          });
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.setState({
            isRedirect: true,
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        } else {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.ServerError,
            isErrorHeader: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
    return false;
  };

  redirectToDashboard = () => {
    this.props.history.push("/dashboard");
  };

  addDataSourcesResponse = (response, type) => {
    response.testConfig = false;
    if (type === "testConfig") {
      response.testConfig = true;
    }
    this.props.onDataSourceRes(response);
  };

  addDataSourcesAzureResponse = (response, type) => {
    response.testConfig = false;
    if (type === "testConfig") {
      response.testConfig = true;
    }
    this.props.onDataSourceRes(response);
  };

  setErrorPopupStateDataSource = (event) => {
    this.setState({ errorPopup: event, errorPopupDataSource: false });
    if (this.state.isRedirect) {
      this.props.history.push("/");
    }
  };

  toggleMenu = (menu) => {
    if (this.state.isOpenMenu.find((val) => val == menu)) {
      const removed = this.state.isOpenMenu.filter((val1) => val1 !== menu);
      this.setState({ isOpenMenu: removed });
    } else {
      this.setState({ isOpenMenu: [...this.state.isOpenMenu, menu] });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.providerName !== this.props.providerName) {
      this.setState({
        providerName: this.props.providerName,
        loginProvider: this.props.providerName
          ? this.props.providerName.toUpperCase() === "AZURE"
            ? "AWS"
            : "AZURE"
          : null,
      });
    }
  }

  handleRecommendationPreferenceRequest = (responseJson) => {
    if (responseJson.success === true) {
      this.toggleRecommendationPreferenceModal();
      this.setState({
        isRedirect: true,
        errorPopup: true,
        errormessage: responseJson.message,
        isErrorHeader: true,
      });
    } else if (responseJson.success === false) {
      this.setState({
        isRedirect: true,
        errorPopup: true,
        errormessage: responseJson.message,
      });
    } else if (responseJson.status === 403 || responseJson.status === 401) {
      this.setState({
        isRedirect: true,
        errorPopup: true,
        errormessage: message.SessionExpiredText,
        isErrorHeader: false,
      });
    } else {
      this.setState({
        isRedirect: true,
        errorPopup: true,
        errormessage: message.ServerError,
        isErrorHeader: false,
      });
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (state.dashHeight === 0) {
      setTimeout(() => {
        var width = document.getElementById("dashHeight");
        var planNav = document.getElementById("planNav");
        if (width && width.offsetHeight >= 65) {
          document
            .getElementById("optionDisplay")
            .setAttribute("style", "height:" + width.offsetHeight + "px;");
          if (
            document.getElementsByClassName("container-verf") &&
            document.getElementsByClassName("container-verf")[0]
          ) {
            document.getElementsByClassName(
              "container-verf"
            )[0].style.marginTop = "80px";
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }
          if (planNav) {
            document
              .getElementById("planNav")
              .setAttribute(
                "style",
                "margin-top:80px;text-align: center;align-content: center;"
              );
          }
        } else {
          document.getElementById("optionDisplay") &&
            document
              .getElementById("optionDisplay")
              .setAttribute("style", "height: auto;");
          if (
            document.getElementsByClassName("container-verf") &&
            document.getElementsByClassName("container-verf")[0]
          ) {
            document.getElementsByClassName(
              "container-verf"
            )[0].style.marginTop = "50px";
          }
          if (planNav) {
            document
              .getElementById("planNav")
              .setAttribute(
                "style",
                "margin-top:50px;text-align: center;align-content: center;"
              );
          }
        }
      }, 300);
    }
  }

  toggleOrganizationModal = () => {
    this.setState((prevState) => ({
      organizationModal: !prevState.organizationModal,
    }));
  };
  toggleOrganizationListModal = () => {
    this.setState((prevState) => ({
      organizationListModal: !prevState.organizationListModal,
    }));
  };

  deleteOrg = (id, loggedInUser) => {
    this.setState({ deleteOrgId: id, deleteUserName: loggedInUser });
    this.toggleModal();
  };

  toggleOrganizationListStatusModal = () => {
    this.setState((prevState) => ({
      organizationListStatusModal: !prevState.organizationListStatusModal,
    }));
  };
  viewOrgStatus = (id, orgName) => {
    this.setState({ orgId: id });
    this.setState({ orgName: orgName });
    this.toggleOrganizationModal();
  };
  handleDeleteRedirect = () => {
    if (this.state.deleteUserName === localStorage.getItem("loggedInUser")) {
      this.setState({
        modalLoader: false,
        deleteModal: false,
        deleteUserName: null,
        errormessage: null,
      });
      this.handleLogout();
    } else {
      this.setState({
        modalLoader: false,
        deleteModal: false,
        errormessage: null,
        deleteUserName: null,
      });
      this.toggleOrganizationListModal();
    }
  };

  isMenuShow = () => {
    if (
      this.state.isPlan ||
      this.state.loggedInUser === "support@serralabs.com" ||
      this.state.userName === "support@serralabs.com"
    ) {
      return true;
    } else {
      return false;
    }
  };

  toggleOrganizationHistoryModal = () => {
    this.setState((prevState) => ({
      organizationHistoryModal: !prevState.organizationHistoryModal,
    }));
  };

  handleRecommendationDurationPreferenceRequest = (responseJson) => {
    if (responseJson.success === true) {
      this.togglePreferenceModal();
      this.setState({
        isRedirect: true,
        errorPopup: true,
        errormessage: responseJson.message,
        isErrorHeader: true,
      });
    } else if (responseJson.success === false) {
      this.setState({
        isRedirect: true,
        errorPopup: true,
        errormessage: responseJson.message,
      });
    } else if (responseJson.status === 403 || responseJson.status === 401) {
      this.setState({
        isRedirect: true,
        errorPopup: true,
        errormessage: message.SessionExpiredText,
        isErrorHeader: false,
      });
    } else {
      this.setState({
        isRedirect: true,
        errorPopup: true,
        errormessage: message.ServerError,
        isErrorHeader: false,
      });
    }
  };
  freeTrialPlanSupport() {
    if (
      ((this.state.isPlan && this.state.isAdminUser) ||
        this.state.loggedInUser === "support@serralabs.com") &&
      (!this.state.isFreeTrial ||
        this.state.userName === "support@serralabs.com")
    ) {
      return true;
    } else {
      return false;
    }
  }

  isSupportLogin() {
    if (
      this.state.loggedInUser === "support@serralabs.com" ||
      this.state.userName === "support@serralabs.com"
    ) {
      return true;
    } else {
      return false;
    }
  }

  toggleUpdateProviderModal = () => {
    this.setState((prevState) => ({
      updateProviderModal: !prevState.updateProviderModal,
    }));
  };

  handleChangedProvider = () => {
    this.setState({ modalLoader: true });
    const payload = JSON.parse(
      atob(window.localStorage.getItem("userPayload"))
    );
    payload.providerName = this.state.loginProvider;

    fetch(Constdata.LOGOUT_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success === true) {
          fetch(Constdata.LOGIN_URL, {
            method: "POST",
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(payload),
          })
            .then((responsel) => responsel.json())
            .then(async (responseJsonl) => {
              if (responseJsonl.success === true) {
                this.setState({
                  visible: responseJsonl.success,
                  data: responseJsonl.data,
                });
                this.state.aliasUsername !== ""
                  ? this.setState({ loggedInUser: this.state.aliasUsername })
                  : this.setState({ loggedInUser: this.state.loggedInUser });

                if (
                  (responseJsonl.orgInfo.plan === "Free Trial" ||
                    responseJsonl.orgInfo.plan === "FREE_TRIAL") &&
                  responseJsonl.orgInfo.loggedInUser !==
                    "support@serralabs.com" &&
                  responseJsonl.orgInfo.userName !== "support@serralabs.com"
                ) {
                  fetch(Constdata.GETALLACCOUNT_URL, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json;charset=UTF-8",
                    },
                  })
                    .then((responseg) => responseg.json())
                    .then(async (responseJsong) => {
                      this.setState({
                        modalLoader: false,
                      });

                      let newDSList;
                      const isEmptyAnalysis =
                        responseJsong.orgInfo.freeTrialAnalysisList.every(
                          (type) => type === ""
                        );

                      if (responseJsong.orgInfo.providerName === "AWS") {
                        newDSList = responseJsong.data.awsConfigs;

                        if (
                          newDSList &&
                          newDSList.length > 0 &&
                          !isEmptyAnalysis
                        ) {
                          this.props.history.push({
                            pathname: `/dashboard`,
                            state: {
                              providerName: responseJsong.orgInfo.providerName,
                              step:
                                newDSList &&
                                newDSList.length > 0 &&
                                isEmptyAnalysis
                                  ? 3
                                  : 1,
                            },
                          });
                          window.location.reload();
                        } else {
                          this.props.history.push({
                            pathname: `/free-trial`,
                            state: {
                              providerName: responseJsong.orgInfo.providerName,
                              step: 1,
                            },
                          });
                        }
                      } else {
                        newDSList = responseJsong.data.configs;

                        if (
                          newDSList &&
                          newDSList.length > 0 &&
                          !isEmptyAnalysis
                        ) {
                          this.props.history.push({
                            pathname: `/dashboard`,
                            state: {
                              providerName: responseJsong.orgInfo.providerName,
                              step: 1,
                            },
                          });
                          window.location.reload();
                        } else {
                          this.props.history.push({
                            pathname: `/free-trial`,
                            state: {
                              providerName: responseJsong.orgInfo.providerName,
                              step:
                                newDSList &&
                                newDSList.length > 0 &&
                                isEmptyAnalysis
                                  ? 3
                                  : 1,
                            },
                          });
                        }
                      }
                    });
                } else {
                  this.setState({
                    modalLoader: false,
                  });
                  if (responseJsonl.data.landingPage === "report") {
                    this.props.history.push({
                      pathname: `/${
                        responseJsonl.orgInfo.loggedInUser ===
                        "support@serralabs.com"
                          ? "dashboard"
                          : responseJsonl.data.landingPage
                      }`,
                      state: { data: this.state.data.data },
                    });
                  } else {
                    this.props.history.push({
                      pathname: `/${
                        responseJsonl.orgInfo.loggedInUser ===
                        "support@serralabs.com"
                          ? "dashboard"
                          : responseJsonl.data.landingPage
                      }`,
                      state: { data: this.state.data },
                    });
                  }
                  window.location.reload();
                }
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else if (responseJson.success === false) {
          this.clearOldTimeout();
          this.setState({ isLogin: false });
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.clearOldTimeout();
          this.setState({ isLogin: false, showWarning: false });
        } else {
          this.clearOldTimeout();
          this.setState({ isLogin: false, showWarning: false });
        }
      })
      .catch((error) => {
        this.clearOldTimeout();
        this.setState({ isLogin: false, showWarning: false });
      });
  };

  handleCurrencySelectionRequest = (responseJson) => {
    if (responseJson.success === true) {
      this.toggleCurrencySelectionModal();
      this.setState({
        isRedirect: true,
        errorPopup: true,
        errormessage: "Successfully set currency.",
        isErrorHeader: true,
      });
      window.location.reload();
    } else if (responseJson.success === false) {
      this.setState({
        isRedirect: true,
        errorPopup: true,
        errormessage: responseJson.message,
      });
    } else if (responseJson.status === 403 || responseJson.status === 401) {
      this.setState({
        isRedirect: true,
        errorPopup: true,
        errormessage: message.SessionExpiredText,
        isErrorHeader: false,
      });
    } else {
      this.setState({
        isRedirect: true,
        errorPopup: true,
        errormessage: message.ServerError,
        isErrorHeader: false,
      });
    }
  };

  render() {
    const Logo = <img src={this.props.companyDetails.LOGO} alt="" />;
    const ManagegDT = (
      <img src={require("../../images/DataSource_Icon.svg")} alt="" />
    );
    const OPEN = (
      <img
        src={require("../../images/OpenArrow_01.png")}
        alt=""
        style={{ maxWidth: "12px", maxHeight: "10px", marginTop: "8px" }}
      />
    );
    const CLOSE = (
      <img
        src={require("../../images/CloseArrow_01.png")}
        alt=""
        style={{ maxWidth: "12px", maxHeight: "10px", marginTop: "8px" }}
      />
    );

    return (
      <div>
        <div className="filler">&nbsp;</div>
        <div className="head-section">
          <div className="navbarHeader" id="optionDisplay">
            {this.props.companyDetails.CompanyName === "Serra Labs" ? (
              <div id="logoWidth" className="logo-container">
                {Logo}
              </div>
            ) : (
              <div
                id="logoWidth"
                className="logo-container"
                style={{
                  display:
                    process.env.REACT_APP_IS_PoweredByName === "true"
                      ? "block"
                      : "flex",
                  minWidth: "140px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {Logo}
                {process.env.REACT_APP_IS_PoweredByName === "true" ? (
                  <div className="powerByDiv">Powered by Serra Labs</div>
                ) : null}
              </div>
            )}

            <div
              id="menuDisplay"
              style={{ width: "100%", display: "inline-flex" }}
            >
              <div
                id="providerNameDiv"
                style={{
                  whiteSpace: "nowrap",
                  backgroundColor: "#ffffff",
                  fontSize: "16px",
                  color: "#2500A1",
                  height: "60px",
                  paddingTop: "17px",
                }}
              >
                <span
                  style={{
                    fontWeight: "500",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  {" "}
                  {this.state.providerName
                    ? "Provider: " + this.state.providerName
                    : ""}
                </span>
                <div style={{ color: "white", lineHeight: "0.5" }}>
                  {this.state.loggedInUser}
                </div>
              </div>

              <div id="titleCenter" className="menu-cont">
                <Link id="dashRef" className="menu-tab" to={"dashboard"}>
                  {this.props.companyDetails.ServiceName} Dashboard
                </Link>
                <a
                  id="pageRef"
                  className="menu-selected"
                  style={{
                    display: "none",
                    fontSize: "22px",
                    color: "#610058",
                    marginBottom: "1px",
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        textAlign: "center",
                        fontSize: "22px",
                        color: "#610058",
                      }}
                    >
                      Specific Report for Selected {this.props.resourceType}{" "}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "15px",
                        color: "#610058",
                      }}
                    >
                      {getStringDate(this.props.datetime)}{" "}
                    </div>
                  </div>
                </a>
              </div>

              <div
                id="loggedInUser"
                style={{
                  whiteSpace: "nowrap",
                  // backgroundColor: "#ffffff",
                  fontSize: "16px",
                  color: "#2500A1",
                  // height: "60px",
                  paddingTop: "17px",
                  // position: "absolute",
                  // right: "0px",
                  // paddingLeft: "10px",
                  // paddingRight: "10px",
                }}
              >
                {this.state.loggedInUser}
              </div>
            </div>
            <div className="manage-option-btn" id="selectDisplay">
              {/* <div className="manage-data-cont" id="dsRef">
                <div
                  className="manage-data-btn"
                  onClick={this.toggleManageModal}
                ></div>
              </div> */}
              <div className="divcenter">
                <div className="option-dropdown">
                  <div className="icon-border">
                    <i
                      style={{ fontSize: "25px", color: "#2500A1" }}
                      className="fa fa-bars"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div className="dropdown-content">
                    <ul className="dropdown-menus">
                      {this.isMenuShow() ? (
                        <li
                          onClick={() => this.toggleMenu("dashboard")}
                          className="dropdown-menu2"
                        >
                          Dashboards{" "}
                          <div
                            className="arrow"
                            style={{ paddingRight: "12px" }}
                          >
                            {!this.state.isOpenMenu.find(
                              (val) => val == "dashboard"
                            )
                              ? CLOSE
                              : OPEN}
                          </div>
                        </li>
                      ) : null}
                      <Collapse
                        isOpen={this.state.isOpenMenu.find(
                          (val) => val == "dashboard"
                        )}
                        className="collapseTransitionMenu"
                      >
                        <li
                          className="dropdown-menu1"
                          id="mddp"
                          onClick={(e) => {
                            this.redirectToDashboard();
                          }}
                        >
                          <div
                            className={
                              this.props.match.path === "/dashboard"
                                ? "dropdown-list-active"
                                : "dropdown-list"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {this.props.companyDetails.ServiceName} Dashboard
                          </div>
                        </li>
                      </Collapse>
                      {this.isMenuShow() ? (
                        <li
                          onClick={() => this.toggleMenu("configuration")}
                          className="dropdown-menu2"
                        >
                          Configuration{" "}
                          <div
                            className="arrow"
                            style={{ paddingRight: "12px" }}
                          >
                            {!this.state.isOpenMenu.find(
                              (val) => val == "configuration"
                            )
                              ? CLOSE
                              : OPEN}
                          </div>
                        </li>
                      ) : null}
                      <Collapse
                        isOpen={this.state.isOpenMenu.find(
                          (val) => val == "configuration"
                        )}
                        className="collapseTransitionMenu"
                      >
                        <li
                          className="dropdown-menu1"
                          id="mddp"
                          onClick={this.toggleManageModal}
                          style={{
                            display: this.state.isOpenMenu.find(
                              (val) => val == "configuration"
                            )
                              ? "block"
                              : "none",
                          }}
                        >
                          <div
                            className="dropdown-list"
                            style={{ cursor: "pointer" }}
                          >
                            Manage Data Source
                          </div>
                        </li>
                      </Collapse>
                      {this.isMenuShow() ? (
                        <li
                          onClick={() => this.toggleMenu("analysis")}
                          className="dropdown-menu2"
                        >
                          Analysis and Recommendation{" "}
                          <div
                            className="arrow"
                            style={{ paddingRight: "12px" }}
                          >
                            {!this.state.isOpenMenu.find(
                              (val) => val == "analysis"
                            )
                              ? CLOSE
                              : OPEN}
                          </div>
                        </li>
                      ) : null}
                      <Collapse
                        isOpen={this.state.isOpenMenu.find(
                          (val) => val == "analysis"
                        )}
                        className="collapseTransitionMenu"
                      >
                        <li
                          className="dropdown-menu1"
                          id="mddp"
                          onClick={(e) => {
                            this.showList("rightsizing");
                          }}
                        >
                          <div
                            className={
                              this.props.match.path === "/manageOptimizations"
                                ? "dropdown-list-active"
                                : "dropdown-list"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            Rightsizing
                          </div>
                        </li>
                        <li
                          className="dropdown-menu1"
                          id="mddp"
                          onClick={(e) => {
                            this.showList("parking");
                          }}
                        >
                          <div
                            className={
                              this.props.match.path === "/manageParking"
                                ? "dropdown-list-active"
                                : "dropdown-list"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            Parking
                          </div>
                        </li>
                        <li
                          className="dropdown-menu1"
                          id="mddp"
                          onClick={(e) => {
                            this.showList("cleanup");
                          }}
                        >
                          <div
                            className={
                              this.props.match.path === "/manageCleanup"
                                ? "dropdown-list-active"
                                : "dropdown-list"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            Cleanup
                          </div>
                        </li>
                      </Collapse>
                      {this.isMenuShow() ? (
                        <li
                          onClick={() => this.toggleMenu("settings")}
                          className="dropdown-menu2"
                        >
                          Settings{" "}
                          <div
                            className="arrow"
                            style={{ paddingRight: "12px" }}
                          >
                            {!this.state.isOpenMenu.find(
                              (val) => val == "settings"
                            )
                              ? CLOSE
                              : OPEN}
                          </div>
                        </li>
                      ) : null}
                      <Collapse
                        isOpen={this.state.isOpenMenu.find(
                          (val) => val == "settings"
                        )}
                        className="collapseTransitionMenu"
                      >
                        <li className="dropdown-menu1" id="mddp">
                          <div
                            className="dropdown-list"
                            style={{ cursor: "pointer" }}
                            onClick={this.togglePreferenceModal}
                          >
                            Metric Data Duration Preference
                          </div>
                        </li>
                        <li className="dropdown-menu1" id="vmpp">
                          <div
                            className="dropdown-list"
                            style={{ cursor: "pointer" }}
                            onClick={this.toggleParkingPreferenceModal}
                          >
                            VM Parking Preference
                          </div>
                        </li>
                        {/* {this.state.providerName === "AZURE" ? ( */}
                        <li className="dropdown-menu1" id="rp">
                          <div
                            className="dropdown-list"
                            style={{ cursor: "pointer" }}
                            onClick={this.toggleRecommendationPreferenceModal}
                          >
                            Recommendation Preference
                          </div>
                        </li>
                        {/* ) : null} */}
                        <li className="dropdown-menu1" id="rp">
                          <div
                            className="dropdown-list"
                            style={{ cursor: "pointer" }}
                            onClick={this.toggleCurrencySelectionModal}
                          >
                            Currency Selection
                          </div>
                        </li>
                      </Collapse>
                      <li
                        onClick={() => this.toggleMenu("providers")}
                        className="dropdown-menu2"
                      >
                        Switch Provider{" "}
                        <div className="arrow" style={{ paddingRight: "12px" }}>
                          {!this.state.isOpenMenu.find(
                            (val) => val == "providers"
                          )
                            ? CLOSE
                            : OPEN}
                        </div>
                      </li>
                      <Collapse
                        isOpen={this.state.isOpenMenu.find(
                          (val) => val == "providers"
                        )}
                        className="collapseTransitionMenu"
                      >
                        <li className="dropdown-menu1">
                          <div
                            className="dropdown-list"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.toggleUpdateProviderModal()}
                          >
                            {this.state.loginProvider}
                          </div>
                        </li>
                      </Collapse>
                      {this.freeTrialPlanSupport() ? (
                        <li
                          onClick={() => this.toggleMenu("organization")}
                          className="dropdown-menu2"
                        >
                          Organization{" "}
                          <div
                            className="arrow"
                            style={{ paddingRight: "12px" }}
                          >
                            {!this.state.isOpenMenu.find(
                              (val) => val == "organization"
                            )
                              ? CLOSE
                              : OPEN}
                          </div>
                        </li>
                      ) : null}
                      <Collapse
                        isOpen={this.state.isOpenMenu.find(
                          (val) => val == "organization"
                        )}
                        className="collapseTransitionMenu"
                      >
                        <li className="dropdown-menu1" id="rp">
                          <div
                            className="dropdown-list"
                            style={{ cursor: "pointer" }}
                            onClick={
                              this.isSupportLogin()
                                ? this.toggleOrganizationListStatusModal
                                : this.toggleOrganizationModal
                            }
                          >
                            Organization Status
                          </div>
                        </li>
                        {this.isSupportLogin() ? (
                          <li className="dropdown-menu1" id="rp">
                            <div
                              className="dropdown-list"
                              style={{ cursor: "pointer" }}
                              onClick={this.toggleOrganizationHistoryModal}
                            >
                              Organization Analysis History
                            </div>
                          </li>
                        ) : null}
                        {/* {this.state.loggedInUser == this.props.companyDetails.Support ? (
                          <li className="dropdown-menu1" id="rp">
                            <div
                              className={
                                this.props.match.path === "/orgAnalysisHistory"
                                  ? "dropdown-list-active"
                                  : "dropdown-list"
                              }
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.props.history.push({
                                  pathname: "/orgAnalysisHistory",
                                })
                              }
                            >
                              Organization Analysis History
                            </div>
                          </li>
                         ) : null}  */}
                      </Collapse>
                      <li
                        onClick={() => this.toggleMenu("account")}
                        className="dropdown-menu2"
                      >
                        Account{" "}
                        <div className="arrow" style={{ paddingRight: "12px" }}>
                          {!this.state.isOpenMenu.find(
                            (val) => val == "account"
                          )
                            ? CLOSE
                            : OPEN}
                        </div>
                      </li>
                      <Collapse
                        isOpen={this.state.isOpenMenu.find(
                          (val) => val == "account"
                        )}
                        className="collapseTransitionMenu"
                      >
                        {/* {(this.state.isPlan && this.state.isAdminUser) ||
                        this.state.loggedInUser === this.props.companyDetails.Support ? (
                          <li className="dropdown-menu1" id="rp">
                            <div
                              className="dropdown-list"
                              style={{ cursor: "pointer" }}
                              onClick={
                                this.state.loggedInUser ===
                                this.props.companyDetails.Support
                                  ? this.toggleOrganizationListStatusModal
                                  : this.toggleOrganizationModal
                              }
                            >
                              Manage
                            </div>
                          </li>
                        ) : null} */}

                        <li className="dropdown-menu1">
                          <div
                            className="dropdown-list"
                            style={{ cursor: "pointer" }}
                            onClick={this.handleLogout}
                          >
                            Logout
                          </div>
                        </li>

                        {this.isSupportLogin() ? (
                          <li className="dropdown-menu1">
                            <div
                              className="dropdown-list"
                              style={{ cursor: "pointer" }}
                              onClick={this.toggleOrganizationListModal}
                            >
                              Delete Account
                            </div>
                          </li>
                        ) : null}
                      </Collapse>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.isFreeTrial ? (
          <Navbar
            id="planNav"
            className="NavHeader"
            style={{ overflow: "hidden", display: "none" }}
          >
            <div style={{ display: "inline-flex" }}>
              <div className="currentPlan">Free Trial</div>
              <div>
                <Button
                  id="btnCards"
                  onClick={this.toggleisRequest}
                  style={{ width: "200px", marginLeft: "20px" }}
                >
                  Upgrade to Paid Plan
                </Button>
              </div>
            </div>
          </Navbar>
        ) : null}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          size="lg"
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            toggle={this.toggleModal}
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Delete Account</div>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="box-titel">
                {" "}
                Please input admin password to proceed with account deletion.
                {<br />}
                This will remove all your account data.
              </div>
              <div className="DeleteForm">
                <div htmlFor="exampleInputPassword1"> Admin Password:</div>
                <div>
                  <Input
                    type="password"
                    style={style}
                    id="exampleInputPassword1"
                    placeholder="Admin Password"
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    required
                  />
                </div>
              </div>
              <Form>
                <FormGroup>
                  <div align="center">
                    <Button
                      style={{ padding: "0px" }}
                      id="confirmbtn_list"
                      size="lg"
                      onClick={this.handleAccountDelete}
                    >
                      Yes
                    </Button>
                    <Button
                      style={{ padding: "0px" }}
                      id="canclebtn_list"
                      size="lg"
                      onClick={() => {
                        this.toggleModal();
                      }}
                    >
                      No
                    </Button>
                  </div>
                </FormGroup>
              </Form>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.deleteModal}
          toggle={this.toggleDeleteModal}
          size="lg"
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Delete Account Successful</div>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="box-titel">
                {this.state.deleteUserName ===
                localStorage.getItem("loggedInUser")
                  ? "Account delete is successful, you will be now logged out"
                  : "User account deleted succesfully"}
              </div>
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button
              id="confirmbtn_list"
              onClick={() => {
                this.handleDeleteRedirect();
              }}
            >
              OK
            </Button>{" "}
          </ModalFooter>
        </Modal>

        {this.state.errorPopup === true ? (
          <SerraError
            Errordata={this.state.errormessage}
            ErrorPopup={this.state.errorPopup}
            setErrorMethod={this.setErrorPopupState}
            isError={this.state.isErrorHeader}
          ></SerraError>
        ) : (
          ""
        )}
        {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}
        <Modal
          isOpen={this.state.PPUisRequest}
          toggle={this.toggleisRequest}
          size="lg"
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Paid Plan Request</div>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="box-titel">
                <p style={{ textAlign: "left" }}>
                  Thank you for your interest in the Paid Plan. After you click
                  the "Request Paid Plan" button, we will process your selection
                  and update you through your email ({this.state.email}).
                </p>{" "}
              </div>
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button
              id="confirmbtn_list"
              style={{ width: "250px" }}
              onClick={this.toggleRequest}
            >
              Request Paid Plan
            </Button>
            <Button
              id="confirmbtn_list"
              onClick={(e) => {
                this.setState({ PPUisRequest: false });
              }}
            >
              Cancel
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.PPURequest}
          toggle={this.toggleRequest}
          size="lg"
          backdrop={"static"}
        >
          <ModalHeader
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Paid Plan Request Confirmation</div>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="box-titel">
                <p>
                  Your authorization request has been successfully sent. Please
                  await further communication to your login email (
                  {this.state.email}).
                </p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button
              id="confirmbtn_list"
              onClick={(e) => {
                this.setState({ PPURequest: false });
              }}
            >
              OK
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.PPURequestSubmit}
          size="lg"
          backdrop={"static"}
        >
          <ModalHeader
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Paid Plan Request Status</div>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="box-titel">{this.state.Confirm}</div>
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Button
              id="confirmbtn_list"
              onClick={(e) => {
                this.setState({ PPURequestSubmit: false });
              }}
            >
              OK
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Modal
          backdrop="static"
          size="xl"
          style={{ maxWidth: "1600px", width: "70%", margin: "100px auto" }}
          isOpen={this.state.showManageDs}
          toggle={this.toggleManageModal}
        >
          <ModalHeader
            toggle={this.toggleManageModal}
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">
              Manage Metrics and Metadata Data Sources
            </div>
          </ModalHeader>
          <ModalBody>
            <ManageAccount
              addDataSourcesAzureResponse={this.addDataSourcesAzureResponse}
              addDataSourcesResponse={this.addDataSourcesResponse}
              registerListners={this.props.registerListners}
              processLogut={this.props.processLogout}
              toggle={this.toggleManageModal}
              checkRefresh={this.checkRefresh}
              isDSRefresh={this.props.isDSRefresh}
              history={this.props.history}
            />
          </ModalBody>
        </Modal>
        <Modal
          backdrop="static"
          size="xl"
          isOpen={this.state.prefModal}
          toggle={this.togglePreferenceModal}
        >
          <ModalHeader
            toggle={this.togglePreferenceModal}
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Metrics Data Duration Preference</div>
          </ModalHeader>
          <ModalBody>
            <Preference
              registerListners={this.props.registerListners}
              processLogut={this.props.processLogout}
              toggle={this.togglePreferenceModal}
              handleRecommendationDurationPreference={
                this.handleRecommendationDurationPreferenceRequest
              }
              providerName={this.state.providerName}
              userName={this.state.userName}
              isFreeTrial={this.state.isFreeTrial}
            />
          </ModalBody>
        </Modal>
        <Modal
          backdrop="static"
          size="xl"
          isOpen={this.state.parkingPerfModal}
          toggle={this.toggleParkingPreferenceModal}
        >
          <ModalHeader
            toggle={this.toggleParkingPreferenceModal}
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">VM Parking Preference</div>
          </ModalHeader>
          <ModalBody>
            <VMParkingPreferences
              registerListners={this.props.registerListners}
              processLogut={this.props.processLogout}
              toggle={this.toggleParkingPreferenceModal}
            />
          </ModalBody>
        </Modal>
        <Modal
          backdrop="static"
          size="xl"
          isOpen={this.state.recommendationPerfModal}
          toggle={this.toggleRecommendationPreferenceModal}
        >
          <ModalHeader
            toggle={this.toggleRecommendationPreferenceModal}
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">VM Recommendation Preference</div>
          </ModalHeader>
          <ModalBody>
            <VMRecommendationPreferences
              registerListners={this.props.registerListners}
              processLogut={this.props.processLogout}
              toggle={this.toggleRecommendationPreferenceModal}
              handleRecommendationPreference={
                this.handleRecommendationPreferenceRequest
              }
              providerName={this.state.providerName}
            />
          </ModalBody>
        </Modal>

        <Modal
          style={{ maxWidth: "1600px", width: "70%", margin: "100px auto" }}
          backdrop="static"
          size="xl"
          isOpen={this.state.organizationModal}
          toggle={this.toggleOrganizationModal}
        >
          <ModalHeader
            toggle={this.toggleOrganizationModal}
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Organization Status</div>
          </ModalHeader>
          <ModalBody>
            <OrganizationDetails
              registerListners={this.props.registerListners}
              processLogut={this.props.processLogout}
              toggle={this.toggleOrganizationModal}
              orgId={this.state.orgId}
              orgName={this.state.orgName}
            />
          </ModalBody>
        </Modal>
        <Modal
          style={{ maxWidth: "1600px", width: "70%", margin: "100px auto" }}
          backdrop="static"
          size="xl"
          isOpen={this.state.organizationListModal}
          toggle={this.toggleOrganizationListModal}
        >
          <ModalHeader
            toggle={this.toggleOrganizationListModal}
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Delete Organization</div>
          </ModalHeader>
          <ModalBody>
            <OrganizationList
              deleteOrg={this.deleteOrg}
              processLogut={this.props.processLogout}
              toggle={this.toggleOrganizationListModal}
              isDelete="true"
            />
          </ModalBody>
        </Modal>
        <Modal
          style={{ maxWidth: "1600px", width: "70%", margin: "100px auto" }}
          backdrop="static"
          size="xl"
          isOpen={this.state.organizationListStatusModal}
          toggle={this.toggleOrganizationListStatusModal}
        >
          <ModalHeader
            toggle={this.toggleOrganizationListStatusModal}
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Organization List</div>
          </ModalHeader>
          <ModalBody>
            <OrganizationList
              deleteOrg={this.deleteOrg}
              viewOrgStatus={this.viewOrgStatus}
              processLogut={this.props.processLogout}
              toggle={this.toggleOrganizationListStatusModal}
              organizationListStatusModal={
                this.state.organizationListStatusModal
              }
            />
          </ModalBody>
        </Modal>

        <Modal
          style={{ maxWidth: "1600px", width: "70%", margin: "100px auto" }}
          backdrop="static"
          size="xl"
          isOpen={this.state.organizationHistoryModal}
          toggle={this.toggleOrganizationHistoryModal}
        >
          <ModalHeader
            toggle={this.toggleOrganizationHistoryModal}
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Organazation Analysis History</div>
          </ModalHeader>
          <ModalBody>
            <OrganizationAnalysisHistory />
            {/* <OrganizationList
              deleteOrg={this.deleteOrg}
              viewOrgStatus={this.viewOrgStatus}
              processLogut={this.props.processLogout}
              toggle={this.toggleOrganizationListStatusModal}
              organizationListStatusModal={
                this.state.organizationListStatusModal
              }
            /> */}
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.updateProviderModal}
          toggle={this.toggleUpdateProviderModal}
          size="lg"
          backdrop="static"
        >
          <ModalHeader
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Switch Provider</div>
          </ModalHeader>
          <ModalBody>
            <div>
              <div className="box-titel">
                Are you sure you want to switch to {this.state.loginProvider} ?
              </div>
            </div>
          </ModalBody>
          <ModalFooter id="knowMoreFooter">
            <Form>
              <FormGroup>
                <div align="center">
                  <Button
                    style={{ padding: "0px" }}
                    id="confirmbtn_list"
                    size="lg"
                    onClick={this.handleChangedProvider}
                  >
                    Yes
                  </Button>
                  <Button
                    style={{ padding: "0px" }}
                    id="canclebtn_list"
                    size="lg"
                    onClick={() => {
                      this.toggleUpdateProviderModal();
                    }}
                  >
                    No
                  </Button>
                </div>
              </FormGroup>
            </Form>
          </ModalFooter>
        </Modal>

        <Modal
          backdrop="static"
          size="xl"
          isOpen={this.state.currencySelectionModal}
          toggle={this.toggleCurrencySelectionModalModal}
        >
          <ModalHeader
            toggle={this.toggleCurrencySelectionModalModal}
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">Currency Selection</div>
          </ModalHeader>
          <ModalBody>
            <CurrencySelection
              registerListners={this.props.registerListners}
              processLogut={this.props.processLogout}
              toggle={this.toggleCurrencySelectionModal}
              handleCurrencySelectionRequest={
                this.handleCurrencySelectionRequest
              }
              providerName={this.state.providerName}
              userName={this.state.userName}
              isFreeTrial={this.state.isFreeTrial}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onDataSourceRes: (data) => dispatch(setDataSourceRes(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    dataSourceResponse: state.dataSourceRes.dataSourceResponse,
    companyDetails: state.companyDetails.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
