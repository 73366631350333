import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Button, Col, Row } from "reactstrap";
import AwsDS from "./AwsDS";
import AzureDS from "./AzureDS";
import { useHistory } from "react-router-dom";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import SerraLoader from "../CommonComponent/Loader";
import SerraError from "../CommonComponent/Error";

const GetStarted = ({ processLogut, registerListners }) => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [provider, setProvider] = useState(
    history.location.state?.providerName
  );
  const [DSList, setDSList] = useState(null);
  const [isFetching, setFetching] = useState(true);
  const [isAnyPricFetching, setIsAnyPricFetching] = useState(true);
  const [modalLoader, setModalLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState(false);
  const [orgInfo, setorgInfo] = useState(null);
  const [awsDSResponse, setAwsDSResponse] = useState(true);
  const [priceThreadStatus, setPriceThreadStatus] = useState(null);

  let timeoutId;
  let timeoutIdFetchDS;

  useEffect(() => {
    registerListners(true);
    checkSessionStatus();
    setStep(history.location.state?.step);

    if (history.location.state?.step === 3 || history.location.state?.forward) {
      fetchAllConfig();
      if (history.location.state?.isDSAdded) {
        setModalLoader(true);
      }
    }
  }, [history.location.state?.step]);

  const checkSessionStatus = () => {
    fetch(Constdata.SESSIONACTIVE_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => {
        if (response.redirected) {
          window.location.href = "/";
        } else {
          return response.json();
        }
      })
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          setorgInfo(responseJson.orgInfo);
          if (responseJson.orgInfo && responseJson.orgInfo.isUserVerified) {
            window.localStorage.removeItem("resendEmail");
          }
        }
      })
      .catch((error) => {
        console.log("Session check failed:", error);
      });
  };

  useEffect(() => {
    setProvider(history.location.state?.providerName);
  }, [history.location.state?.providerName]);

  const handlePage = (step, ds = false, forward = false) => {
    history.push("free-trial", {
      step: step,
      providerName: provider,
      isDSAdded: ds,
      forward: forward,
    });
  };

  const fetchAllConfig = () => {
    fetch(Constdata.GETALLACCOUNT_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          let newDSList;

          if (responseJson.orgInfo.providerName === "AWS") {
            newDSList = responseJson.data.awsConfigs;
          } else {
            newDSList = responseJson.data.configs;
          }

          await setDSList(newDSList[0]);

          let isFetching = false;
          let isAnyPricFetching = false;

          if (newDSList.length > 0) {
            setModalLoader(false);

            if (!history.location.state?.forward) {
              handlePage(3);
            }

            setFetching(false);
            setIsAnyPricFetching(false);
          }
          setPriceThreadStatus(null);
          newDSList.map(async (key) => {
            if (responseJson.orgInfo.providerName === "AWS") {
              isAnyPricFetching = false;
            } else {
              if (key.azureSubscriptions.length > 0) {
                key.azureSubscriptions.map((Sb, i) => {
                  if (
                    Sb.priceThreadStatus === null ||
                    Sb.priceThreadStatus.toLowerCase().includes("fetching")
                  ) {
                    isAnyPricFetching = true;
                  }
                });

                const isPriceThreadStatus = key.azureSubscriptions.every(
                  (sb) => sb.priceThreadStatus === "fail"
                );
                if (isPriceThreadStatus) {
                  isAnyPricFetching = false;
                  setPriceThreadStatus("fail");
                }
              } else {
                isAnyPricFetching = true;
              }
            }
            if (key.status.toLowerCase().includes("fetching")) {
              isFetching = true;
              isAnyPricFetching = true;
              setFetching(true);
            }

            if (isAnyPricFetching) {
              isFetching = true;
              setIsAnyPricFetching(true);
            } else {
              setIsAnyPricFetching(false);
            }
          });

          if (timeoutIdFetchDS) {
            clearTimeout(timeoutIdFetchDS);
          }

          if (isFetching || history.location.state?.isDSAdded) {
            timeoutIdFetchDS = setTimeout(() => {
              fetchAllConfig();
            }, 5000);
          }
        } else if (
          responseJson.status === 302 ||
          responseJson.status === 403 ||
          responseJson.status === 401
        ) {
          dsError({
            isRedirect: true,
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: true,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const dsError = (error) => {
    setError(true);
    setErrorData(error);
  };

  const setErrorPopupState = (event, status = true) => {
    if (!event && status) {
      handlePage(2);
      setModalLoader(false);
    }
    setError(event);
  };

  const dsAdded = (isAdded) => {
    setAwsDSResponse(isAdded);
  };

  const isStaledsVms = (Vm) => {
    return (
      Vm.isstaleds === false ||
      (Vm.isstaleds === true && Vm.status === "ANALYZED")
    );
  };
  const beginAnalysis_old = () => {
    setModalLoader(true);
    let payload = {
      accountList: [DSList?.accountname],
      filterList: [
        "NOT ANALYZED",
        "ANALYZING",
        "ANALYZED",
        "ANALYSIS ISSUE",
        "VALIDATING",
        "VALIDATED",
        "VALIDATION ERROR",
        "OPTIMIZING",
        "OPTIMALLY SIZED",
        "CUSTOM-SIZED",
        "RESETTING",
        "ANALYSIS INELIGIBLE",
      ],
      start: 0,
      max: 2147483647,
      searchStr: "",
    };

    fetch(Constdata.PPUVMLIST_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          if (responseJson.data === true) {
            if (timeoutId) {
              clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
              beginAnalysis();
            }, 5000);
          } else {
            if (timeoutId) {
              clearTimeout(timeoutId);
            }
            let listID = responseJson.data
              .filter((val) => val.iseligible === true)
              .map((val) => {
                if (isStaledsVms(val)) {
                  return val.basicvm_id;
                }
              });

            if (listID.length > 0) {
              history.push("start-analysis", { basicVMId: listID });
              setModalLoader(false);
            } else {
              timeoutId = setTimeout(() => {
                beginAnalysis();
              }, 5000);
            }
          }
        }
      });
  };

  const hideIneligibleVmsInMainTbl = (key) => {
    return (
      key.status === "ANALYSIS INELIGIBLE" &&
      (key.metricsPlatform === "Host" ||
        (key.metricsPlatform === "Host and Guest" &&
          key.analysisFailReason === null))
    );
  };

  const beginAnalysis = async () => {
    setModalLoader(true);
    let payload = {
      accountList: [DSList?.accountname],
      filterList: [
        "NOT ANALYZED",
        "ANALYZING",
        "ANALYZED",
        "ANALYSIS ISSUE",
        "VALIDATING",
        "VALIDATED",
        "VALIDATION ERROR",
        "OPTIMIZING",
        "OPTIMALLY SIZED",
        "CUSTOM-SIZED",
        "RESETTING",
        "ANALYSIS INELIGIBLE",
      ],
      start: 0,
      max: 2147483647,
      searchStr: "",
    };

    let cleanupList = await fetch(Constdata.CLEANUP_LIST_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          if (responseJson.data === true) {
            if (timeoutId) {
              clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
              beginAnalysis();
            }, 5000);
          } else {
            if (timeoutId) {
              clearTimeout(timeoutId);
            }
            let listIDP = responseJson.data.map((val) => {
              if (isStaledsVms(val)) {
                return val.id;
              }
            });
            return listIDP;
          }
        }
      });

    let parkingList = await fetch(Constdata.PARKING_VM_LIST_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          if (responseJson.data === true) {
            if (timeoutId) {
              clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
              beginAnalysis();
            }, 5000);
          } else {
            if (timeoutId) {
              clearTimeout(timeoutId);
            }
            let listIDP = responseJson.data
              .filter(
                (val) =>
                  val.iseligible === true && !hideIneligibleVmsInMainTbl(val)
              )
              .map((val) => {
                if (isStaledsVms(val)) {
                  return val.basicvm_id;
                }
              });
            return listIDP;
          }
        }
      });

    let vmlist = await fetch(Constdata.PPUVMLIST_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          if (responseJson.data === true) {
            if (timeoutId) {
              clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
              beginAnalysis();
            }, 5000);
          } else {
            if (timeoutId) {
              clearTimeout(timeoutId);
            }
            let listID = responseJson.data
              .filter((val) => val.iseligible === true)
              .map((val) => {
                if (isStaledsVms(val)) {
                  return val.basicvm_id;
                }
              });
            return listID;
          }
        }
      });

    if (vmlist.length > 0 || parkingList.length > 0 || cleanupList.length > 0) {
      history.push("start-analysis", {
        basicVMId: vmlist,
        basicVMParkingId: parkingList,
        basicASPIDList: cleanupList,
      });
      setModalLoader(false);
    }
  };

  const resendVerification = () => {
    fetch(Constdata.RESEND_ACTIVATION_LINK + "?t_id=" + orgInfo?.loggedInUser, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success === true) {
          window.localStorage.setItem("resendEmail", true);
          dsError({
            modalLoader: false,
            errorPopup: true,
            errormessage: responseJson.message,
            isErrorHeader: true,
          });
        } else {
          dsError({
            isRedirect: true,
            modalLoader: false,
            errorPopup: true,
            errormessage: responseJson.message,
            isErrorHeader: true,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSelectProvider = () => {
    if (history.location.state?.providerName === provider) {
      handlePage(2);
    } else {
      handleChangedProvider();
    }
  };

  const handleChangedProvider = () => {
    setModalLoader(true);
    const payload = JSON.parse(
      atob(window.localStorage.getItem("userPayload"))
    );
    payload.providerName = provider;

    fetch(Constdata.LOGOUT_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success === true) {
          fetch(Constdata.LOGIN_URL, {
            method: "POST",
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(payload),
          })
            .then((responsel) => responsel.json())
            .then(async (responseJsonl) => {
              if (responseJsonl.success === true) {
                if (
                  responseJsonl.orgInfo.plan === "Free Trial" ||
                  responseJsonl.orgInfo.plan === "FREE_TRIAL"
                ) {
                  fetch(Constdata.GETALLACCOUNT_URL, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json;charset=UTF-8",
                    },
                  })
                    .then((responseg) => responseg.json())
                    .then(async (responseJsong) => {
                      setModalLoader(false);

                      let newDSList;
                      const isEmptyAnalysis =
                        responseJsong.orgInfo.freeTrialAnalysisList.every(
                          (type) => type === ""
                        );

                      if (responseJsong.orgInfo.providerName === "AWS") {
                        newDSList = responseJsong.data.awsConfigs;

                        if (
                          newDSList &&
                          newDSList.length > 0 &&
                          !isEmptyAnalysis
                        ) {
                          history.push({
                            pathname: `/dashboard`,
                            state: {
                              providerName: responseJsong.orgInfo.providerName,
                            },
                          });
                        } else {
                          history.push({
                            pathname: `/free-trial`,
                            state: {
                              providerName: responseJsong.orgInfo.providerName,
                              step:
                                newDSList &&
                                newDSList.length > 0 &&
                                isEmptyAnalysis
                                  ? 3
                                  : 2,
                            },
                          });
                          // window.location.reload();
                        }
                      } else {
                        newDSList = responseJsong.data.configs;

                        if (
                          newDSList &&
                          newDSList.length > 0 &&
                          !isEmptyAnalysis
                        ) {
                          history.push({
                            pathname: `/dashboard`,
                            state: {
                              providerName: responseJsong.orgInfo.providerName,
                            },
                          });
                        } else {
                          history.push({
                            pathname: `/free-trial`,
                            state: {
                              providerName: responseJsong.orgInfo.providerName,
                              step:
                                newDSList &&
                                newDSList.length > 0 &&
                                isEmptyAnalysis
                                  ? 3
                                  : 2,
                            },
                          });
                          // window.location.reload();
                        }
                      }
                    });
                } else {
                  history.push({
                    pathname: `/dashboard`,
                    state: {
                      providerName: responseJsonl.orgInfo.providerName,
                    },
                  });
                }
              }
            })
            .catch((error) => {
              setModalLoader(false);
              console.error(error);
            });
        } else if (responseJson.success === false) {
          setModalLoader(false);
          dsError({
            modalLoader: false,
            errorPopup: true,
            errormessage: responseJson.message,
            isErrorHeader: true,
          });
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          setModalLoader(false);
          dsError({
            modalLoader: false,
            errorPopup: true,
            errormessage: responseJson.message,
            isErrorHeader: true,
          });
        } else {
          dsError({
            modalLoader: false,
            errorPopup: true,
            errormessage: responseJson.message,
            isErrorHeader: true,
          });
          setModalLoader(false);
        }
      })
      .catch((error) => {
        setModalLoader(false);
        dsError({
          isRedirect: true,
          modalLoader: false,
          errorPopup: true,
          errormessage: message.SessionExpiredText,
          isErrorHeader: true,
        });
      });
  };

  return (
    <>
      <Header getStarted={true} orgInfo={orgInfo} processLogut={processLogut} />
      <div className="container-verf container-verf-padding">
        <div className="boxShadow" style={{ marginTop: "6rem" }}>
          {orgInfo &&
          !orgInfo.isUserVerified &&
          window.localStorage.getItem("resendEmail") !== "true" ? (
            <Row className="pageBgHeader">
              <Col sm="12" style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                    color: "#ffffff",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                >
                  An email has been sent to {orgInfo?.loggedInUser}. Please take
                  a moment to confirm your email.
                </div>
                <div
                  style={{
                    width: "20%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    className=""
                    id="PPU-btn"
                    onClick={() => resendVerification()}
                  >
                    Resend Email
                  </Button>
                </div>
              </Col>
            </Row>
          ) : null}

          {step === 1 ? (
            <div className="p-5">
              <h2 style={{ fontWeight: "600" }}>
                Step 1 of 3: Select Cloud Provider
              </h2>

              <p className="mt-4 mb-3" style={{ fontSize: "18px" }}>
                Please select cloud provider.
              </p>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="AWS"
                  id="aws"
                  checked={provider === "AWS" ? true : false}
                  onClick={() => setProvider("AWS")}
                />
                <label class="form-check-label pl-2" for="aws">
                  AWS
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="Azure"
                  id="azure"
                  checked={provider === "AZURE" ? true : false}
                  onClick={() => setProvider("AZURE")}
                />
                <label class="form-check-label pl-2" for="azure">
                  Azure
                </label>
              </div>

              <Button
                className="mt-5"
                id="PPU-btn"
                onClick={() => handleSelectProvider()}
              >
                Next
              </Button>
            </div>
          ) : step === 2 ? (
            <Row>
              <Col>
                {" "}
                <div className="p-5">
                  <h2 style={{ fontWeight: "600" }}>
                    Step 2 of 3: Collect Cloud Provider {provider} Data
                  </h2>

                  <p className="mt-5" style={{ fontSize: "18px" }}>
                    Provide read-only credentials to the data source you want to
                    use in this trial. Be sure to test the credentials before
                    continuing.
                  </p>

                  <p className="my-4" style={{ fontSize: "18px" }}>
                    Not ready to add a data source?{" "}
                    <a
                      href="https://www.serralabs.com/get-a-demo"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Schedule a demo
                    </a>{" "}
                    or{" "}
                    <a
                      href="mailto:support@serralabs.com?subject=Request%20Assistance"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      request assistance
                    </a>
                    .
                  </p>

                  {provider === "AWS" ? (
                    <AwsDS
                      handlePage={handlePage}
                      dsError={dsError}
                      dsAdded={dsAdded}
                      forward={!!DSList}
                    />
                  ) : (
                    <AzureDS
                      handlePage={handlePage}
                      dsError={dsError}
                      forward={!!DSList}
                    />
                  )}
                </div>
              </Col>
              <Col className="col-1 d-flex align-items-center">
                {" "}
                <hr class="vertical-hr" />
              </Col>
              <Col className="d-flex flex-column justify-content-center">
                <h5>Help Documentation</h5>
                {/* <video width="677" height="392" controls>
                  <source src="#" type="video/webm" />
                  <source src="#" type="video/mp4" />
                  Your browser does not support the video tag.
                </video> */}

                <iframe
                  src={
                    provider === "AWS"
                      ? `${require("../../docs/AWS_Configuration_for_2024-07-22.pdf")}#navpanes=0`
                      : `${require("../../docs/Azure_Configuration_d_2024-07-15.pdf")}#navpanes=0`
                  }
                  width="95%"
                  height="600px"
                  title="PDF Viewer"
                />

                {/* <p className="mt-3" style={{ fontSize: "16px" }}>
                  View{" "}
                  <a
                    style={{ whiteSpace: "nowrap" }}
                    href={
                      provider === "AWS" ? PdfDSMetricsAWS : PdfDSMetricsAzure
                    }
                    target="_blank"
                  >
                    written instructions
                  </a>{" "}
                  if you need help connecting to your {provider} {` `}
                  environment.
                </p> */}
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className="col-sm-8 p-5">
                <h2 style={{ fontWeight: "600" }}>
                  Step 3 of 3: Start Analysis
                </h2>

                <p className="mt-5" style={{ fontSize: "18px" }}>
                  Please wait while we gather required information from your
                  account.
                </p>

                {DSList && DSList?.accountname ? (
                  <>
                    <p className="my-4" style={{ fontSize: "18px" }}>
                      Data Source: <b>{DSList?.accountname}</b>
                    </p>

                    <div className="d-flex align-items-center mb-2">
                      <p className="mb-0" style={{ fontSize: "18px" }}>
                        Confirming data source access
                      </p>

                      {DSList ? (
                        isFetching ||
                        history.location.state?.isDSAdded ||
                        !awsDSResponse ? (
                          <div
                            class="spinner-border spinner-border-sm text-primary ml-3"
                            role="status"
                          ></div>
                        ) : (
                          <div className="pl-3" style={{ minWidth: "20px" }}>
                            <img
                              src={require("../../images/Optimized_01.png")}
                              width={18}
                              alt="Optimized"
                            />
                          </div>
                        )
                      ) : null}
                    </div>
                    <div className="d-flex align-items-center">
                      <p className="mb-0" style={{ fontSize: "18px" }}>
                        Downloading price list
                      </p>

                      {DSList ? (
                        isFetching ||
                        isAnyPricFetching ||
                        history.location.state?.isDSAdded ||
                        !awsDSResponse ? (
                          <div
                            class="spinner-border spinner-border-sm text-primary ml-3"
                            role="status"
                          ></div>
                        ) : (
                          <div className="pl-3" style={{ minWidth: "20px" }}>
                            <img
                              src={
                                priceThreadStatus === "fail"
                                  ? require("../../images/ValidationResult_N.png")
                                  : require("../../images/Optimized_01.png")
                              }
                              width={18}
                              alt="Optimized"
                            />
                          </div>
                        )
                      ) : null}
                    </div>
                  </>
                ) : null}

                {DSList &&
                !isAnyPricFetching &&
                !isFetching &&
                awsDSResponse &&
                priceThreadStatus !== "fail" ? (
                  <>
                    {" "}
                    <div className="mt-5">
                      <h5>Free Trial Benefit</h5>
                      <p className="mt-4" style={{ fontSize: "18px" }}>
                        The free trial will perform rightsizing analysis,
                        parking analysis and cleanup analysis of all applicable
                        resources. You will then get access to the overall
                        summary, detailed rightsizing result for one randomly
                        selected VM, detailed parking result for one randomly
                        selected VM, and detailed cleanup analysis of the data
                        source just configured.
                      </p>
                    </div>
                    {/* <div className="form-group my-4 w-50">
                      <label>See detailed rightsizing analysis for:</label>
                      <select
                        className="form-control"
                        value={analysisID}
                        onChange={(e) => setAnalysisID(e.target.value)}
                      >
                        <option value="">select</option>
                        {vmList.length > 0 &&
                          vmList.map((vm, key) => {
                            if (vm.iseligible === true && isStaledsVms(vm)) {
                              return (
                                <option key={key} value={vm.basicvm_id}>
                                  {vm.name}
                                </option>
                              );
                            }
                          })}
                      </select>
                    </div> */}
                  </>
                ) : null}

                <div className="d-flex mt-5">
                  <Button
                    disabled={
                      step === 3 && DSList && priceThreadStatus !== "fail"
                    }
                    id={
                      step === 3 && DSList && priceThreadStatus !== "fail"
                        ? "btncancel_disabled"
                        : "btnaccount_cancel"
                    }
                    style={{ width: "initial" }}
                    onClick={() =>
                      step === 3 &&
                      DSList &&
                      priceThreadStatus === "fail" &&
                      handlePage(2)
                    }
                  >
                    Back
                  </Button>

                  <Button
                    id={
                      isAnyPricFetching ||
                      isFetching ||
                      !awsDSResponse ||
                      priceThreadStatus === "fail"
                        ? "PPU_listdisabled"
                        : "PPU-btn"
                    }
                    onClick={() =>
                      // history.push("start-analysis", { basicVMId: analysisID })
                      beginAnalysis()
                    }
                    disabled={
                      isAnyPricFetching ||
                      isFetching ||
                      !awsDSResponse ||
                      priceThreadStatus === "fail"
                    }
                  >
                    Begin Analysis
                  </Button>
                </div>
              </Col>
            </Row>
          )}
          {modalLoader === true ? <SerraLoader></SerraLoader> : ""}
          {error === true ? (
            <SerraError
              Errordata={errorData.errormessage}
              ErrorPopup={error}
              setErrorMethod={(e) => setErrorPopupState(e, false)}
              isError={errorData.isErrorHeader}
            ></SerraError>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default GetStarted;
