import React, { Component } from "react";
import { Table, Row, Col } from "reactstrap";
import ResingBenefitTextComponent from "./ResingBenefitTextComponent";
class ResizeGaiBenefitTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAsp: this.props.isAsp !== undefined ? this.props.isAsp : false,
      entityType:
        this.props.entityType !== undefined
          ? this.props.entityType
          : this.props.isAsp !== undefined && this.props.isAsp === true
          ? "asp"
          : "vm",
      providerName: this.props.providerName,
    };
  }
  render() {
    const { data } = this.props;
    return (
      <div>
        <Row>
          <div className="overviewTextCompParentExpanded">
            {data.summary.costSummaryText !== undefined &&
            data.summary.costSummaryText !== null ? (
              <div className="maindiv-text-overview maindiv-text-overview-grad">
                <ResingBenefitTextComponent
                  title={data.summary.costSummaryText}
                  data={data.summary.costSummaryDescription}
                />
              </div>
            ) : (
              ""
            )}
            {data !== undefined &&
            data.summary.perfSummaryText !== undefined &&
            data.summary.perfSummaryText !== null ? (
              <div className="maindiv-text-overview maindiv-text-overview-grad">
                <ResingBenefitTextComponent
                  data={data.summary.perfSummaryDescription}
                  title={data.summary.perfSummaryText}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </Row>
        <div className="panel">
          <Row>
            <Col>
              <Table id="tableSizing" className="tableTextCenter">
                <thead>
                  <tr>
                    <th></th>
                    <th>Original Spec</th>
                    <th>GAI Recommended Spec</th>
                    <th colSpan="2">Original Limits</th>
                    <th>GAI Recommended Limits</th>
                    <th>GAI Cost Changes</th>
                    <th>GAI Performance Health Changes</th>
                  </tr>
                </thead>
                <tbody>
                  {data.vm.map((key, i) => {
                    return (
                      <tr>
                        {i === 0 ? (
                          <td rowspan={data.vm.length}>
                            <div
                              className={
                                this.state.entityType === "db"
                                  ? "DbCpuClass"
                                  : "CpuClass"
                              }
                            ></div>
                            <Col className="tableLeftHead">
                              {this.state.entityType === "db" ? "vCore" : "CPU"}
                            </Col>
                          </td>
                        ) : (
                          ""
                        )}
                        {i === 0 ? (
                          <td
                            className="textfield"
                            rowSpan={
                              data.vm.length *
                              (this.state.isAsp ||
                              this.state.entityType === "db"
                                ? 4
                                : 3)
                            }
                          >
                            {key.current_vmsize_name}{" "}
                            {key.hypervgeneration
                              ? " (" + key.hypervgeneration + ")"
                              : null}
                          </td>
                        ) : (
                          ""
                        )}
                        {i === 0 ? (
                          <td
                            className="textfield"
                            rowspan={
                              data.vm.length *
                              (this.state.isAsp ||
                              this.state.entityType === "db"
                                ? 4
                                : 3)
                            }
                          >
                            {key.recommended_vmsize_name}{" "}
                            {key.resize_hypervgeneration
                              ? " (" + key.resize_hypervgeneration + ")"
                              : null}
                          </td>
                        ) : (
                          ""
                        )}
                        <td className="textfield">
                          <table style={{ width: "100%" }}>
                            {this.state.isAsp ? (
                              ""
                            ) : (
                              <tr>
                                <span className="labelColor">
                                  {this.state.entityType === "db"
                                    ? "Num vCore"
                                    : "CPU"}
                                </span>
                              </tr>
                            )}
                            {this.state.isAsp ? (
                              <span className="labelColor">CPU ACUs</span>
                            ) : this.state.entityType === "db" ? null : (
                              <tr>
                                <span className="labelColor">CPU Speed</span>
                              </tr>
                            )}
                          </table>
                        </td>
                        <td className="numberfield">
                          <table style={{ width: "100%" }}>
                            {this.state.isAsp ? (
                              ""
                            ) : (
                              <tr>{key.current_vcpu}</tr>
                            )}
                            {this.state.entityType === "db" ? null : (
                              <tr>{key.current_speed}</tr>
                            )}
                          </table>
                        </td>
                        <td className="numberfield">
                          <table style={{ width: "100%" }}>
                            {this.state.isAsp ? (
                              ""
                            ) : (
                              <tr>
                                {key.recommended_vcpu
                                  ? key.recommended_vcpu
                                  : "-"}
                              </tr>
                            )}
                            {this.state.entityType === "db" ? null : (
                              <tr>
                                {key.recommended_speed
                                  ? key.recommended_speed
                                  : "-"}
                              </tr>
                            )}
                          </table>
                        </td>

                        {i === 0 ? (
                          <td
                            className="textfield"
                            rowspan={
                              data.vm.length *
                              (this.state.isAsp ||
                              this.state.entityType === "db"
                                ? 4
                                : 3)
                            }
                          >
                            {key.cost_change ? key.cost_change : "-"}
                          </td>
                        ) : (
                          ""
                        )}
                        <td className="textfield">{key.vcpu_benefit}</td>
                      </tr>
                    );
                  })}

                  {this.state.entityType === "db"
                    ? data.osdisk.map((key, i) => {
                        return (
                          <tr>
                            {i === 0 ? (
                              <td rowSpan={data.osdisk.length}>
                                <div className="DbOsClass"></div>
                                <Col className="tableLeftHead">MEMORY</Col>
                              </td>
                            ) : (
                              ""
                            )}
                            <td className="textfield">
                              <div>
                                <span className="labelColor">Size</span>
                              </div>
                            </td>
                            <td className="numberfield">
                              <div>{key.current_os_disk_memory} GB</div>
                            </td>
                            <td className="numberfield">
                              <div>
                                {key.recommended_os_disk_memory
                                  ? key.recommended_os_disk_memory
                                  : "-"}{" "}
                                GB
                              </div>
                            </td>
                            <td className="textfield">
                              <div>{key.os_disk_benefit}</div>
                            </td>
                          </tr>
                        );
                      })
                    : null}

                  {data.vm.map((key, i) => {
                    return (
                      <tr>
                        {i === 0 ? (
                          <td rowSpan={data.vm.length}>
                            <div
                              className={
                                this.state.entityType === "db"
                                  ? "DbMemoryClass"
                                  : "MemoryClass"
                              }
                            ></div>
                            <Col className="tableLeftHead">
                              {this.state.entityType === "db"
                                ? "DATA DISK"
                                : "MEMORY"}
                            </Col>
                          </td>
                        ) : (
                          ""
                        )}
                        <td className="textfield">
                          <span className="labelColor">
                            {this.state.entityType === "db" ? "IOPS" : "Size"}
                          </span>
                        </td>
                        <td className="numberfield">
                          <div>
                            {parseFloat(key.current_memory).toFixed(2)}{" "}
                            {this.state.providerName === "AWS" ? "GiB" : "GB"}
                          </div>
                        </td>
                        <td className="numberfield">
                          <div>
                            {key.recommended_memory ? (
                              <>
                                {parseFloat(key.recommended_memory).toFixed(2)}{" "}
                                {this.state.providerName === "AWS"
                                  ? "GiB"
                                  : "GB"}
                              </>
                            ) : (
                              "-"
                            )}
                          </div>
                        </td>
                        <td className="textfield">{key.memory_benefit}</td>
                      </tr>
                    );
                  })}
                  {data.vm.map((key, i) => {
                    return (
                      <tr>
                        {i === 0 ? (
                          <td rowSpan={data.vm.length}>
                            <div
                              className={
                                this.state.entityType === "db"
                                  ? "DbNetworkClass"
                                  : "NetworkClass"
                              }
                            ></div>
                            <Col className="tableLeftHead">
                              {this.state.entityType === "db"
                                ? "LOG DISK"
                                : "NETWORK"}
                            </Col>
                          </td>
                        ) : (
                          ""
                        )}
                        <td className="textfield">
                          <span className="labelColor">
                            {this.state.entityType === "db"
                              ? "Log Rate"
                              : "Bandwidth"}
                          </span>
                        </td>
                        <td className="numberfield">
                          <div>{key.current_network_bandwidth}</div>
                        </td>
                        <td className="numberfield">
                          <div>
                            {key.recommended_network_bandwidth
                              ? key.recommended_network_bandwidth
                              : "-"}
                          </div>
                        </td>
                        <td className="textfield">{key.network_benefit}</td>
                      </tr>
                    );
                  })}
                  {this.state.entityType !== "db"
                    ? data.osdisk.map((key, i) => {
                        return (
                          <tr>
                            {i === 0 ? (
                              <td rowSpan={data.osdisk.length}>
                                <div className="OsClass"></div>
                                <Col className="tableLeftHead">
                                  {this.state.isAsp ? "STORAGE" : "OS Disk"}
                                </Col>
                              </td>
                            ) : (
                              ""
                            )}
                            {this.state.isAsp ? (
                              ""
                            ) : (
                              <td className="textfield">
                                {key.current_os_disk_name.toUpperCase()}
                                {<br />}({key.current_os_disk_tier})
                              </td>
                            )}
                            {this.state.isAsp ? (
                              ""
                            ) : (
                              <td className="textfield">
                                {key.recommended_os_disk_name.toUpperCase()}
                                {<br />}
                                {key.recommended_os_disk_tier
                                  ? key.recommended_os_disk_tier
                                  : ""}
                              </td>
                            )}
                            <td className="textfield">
                              <table style={{ width: "100%" }}>
                                {this.state.isAsp ? (
                                  ""
                                ) : (
                                  <tr>
                                    <span className="labelColor">
                                      Throughput
                                    </span>
                                  </tr>
                                )}
                                {this.state.isAsp ? (
                                  ""
                                ) : (
                                  <tr>
                                    <span className="labelColor">IOPS</span>
                                  </tr>
                                )}
                                {this.state.isAsp ? (
                                  <span className="labelColor">Size</span>
                                ) : (
                                  <tr>
                                    <span className="labelColor">Size</span>
                                  </tr>
                                )}
                              </table>
                            </td>
                            <td className="numberfield">
                              <table style={{ width: "100%" }}>
                                {this.state.isAsp ? (
                                  ""
                                ) : (
                                  <tr>{key.current_os_disk_bandwidth}</tr>
                                )}
                                {this.state.isAsp ? (
                                  ""
                                ) : (
                                  <tr>{key.current_os_disk_iops}</tr>
                                )}
                                <tr>{key.current_os_disk_memory}</tr>
                              </table>
                            </td>
                            <td className="numberfield">
                              <table style={{ width: "100%" }}>
                                {this.state.isAsp ? (
                                  ""
                                ) : (
                                  <tr>
                                    {key.recommended_os_disk_bandwidth
                                      ? key.recommended_os_disk_bandwidth
                                      : "-"}
                                  </tr>
                                )}
                                {this.state.isAsp ? (
                                  ""
                                ) : (
                                  <tr>
                                    {key.recommended_os_disk_iops
                                      ? key.recommended_os_disk_iops
                                      : "-"}
                                  </tr>
                                )}
                                <tr>
                                  {key.recommended_os_disk_memory
                                    ? key.recommended_os_disk_memory
                                    : "-"}
                                </tr>
                              </table>
                            </td>
                            {this.state.isAsp ? (
                              ""
                            ) : (
                              <td className="numberfield">
                                <div>
                                  {key.os_disk_cost_change
                                    ? key.os_disk_cost_change
                                    : "-"}
                                </div>
                              </td>
                            )}
                            <td className="textfield">
                              <div>{key.os_disk_benefit}</div>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                  {this.state.entityType !== "db"
                    ? Object.keys(data.dataDisk).map((key) => {
                        return data.dataDisk[key].map((value, i) => {
                          return (
                            <tr>
                              {i === 0 ? (
                                <td
                                  rowSpan={data.dataDisk[key].length}
                                  className="textfield"
                                >
                                  <div className="OsClass"></div>
                                  <Col className="tableLeftHead">
                                    DISK {key}
                                  </Col>{" "}
                                </td>
                              ) : (
                                ""
                              )}
                              <td className="textfield">
                                {value.current_data_disk_name.toUpperCase()}
                                {<br />}({value.current_data_disk_tier})
                              </td>
                              <td className="textfield">
                                {value.recommended_data_disk_name.toUpperCase()}{" "}
                                {<br />}
                                {value.recommended_data_disk_tier
                                  ? value.recommended_data_disk_tier
                                  : ""}
                              </td>
                              <td className="textfield">
                                <table style={{ width: "100%" }}>
                                  <tr>
                                    <span className="labelColor">
                                      Throughput
                                    </span>
                                  </tr>
                                  <tr>
                                    <span className="labelColor">IOPS</span>
                                  </tr>
                                  <tr>
                                    <span className="labelColor">Size</span>
                                  </tr>
                                </table>
                              </td>
                              <td className="numberfield">
                                <table style={{ width: "100%" }}>
                                  <tr>{value.current_data_disk_bandwidth}</tr>
                                  <tr>{value.current_data_disk_iops}</tr>
                                  <tr>{value.current_data_disk_memory}</tr>
                                </table>
                              </td>
                              <td className="numberfield">
                                <table style={{ width: "100%" }}>
                                  <tr>
                                    {value.recommended_data_disk_bandwidth
                                      ? value.recommended_data_disk_bandwidth
                                      : "-"}
                                  </tr>
                                  <tr>
                                    {value.recommended_data_disk_iops
                                      ? value.recommended_data_disk_iops
                                      : "-"}
                                  </tr>
                                  <tr>
                                    {value.recommended_data_disk_memory
                                      ? value.recommended_data_disk_memory
                                      : "-"}
                                  </tr>
                                </table>
                              </td>
                              <td className="numberfield">
                                {" "}
                                <div>
                                  {value.data_disk_cost_change
                                    ? value.data_disk_cost_change
                                    : "-"}
                                </div>
                              </td>
                              <td className="textfield">
                                {value.data_disk_benefit}
                              </td>
                            </tr>
                          );
                        });
                      })
                    : null}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default ResizeGaiBenefitTable;
