import React from "react";
import { Table, Row, Col } from "reactstrap";
import * as message from "../../Constants/MessageConstant";
import { priceDisplay } from "../../Constants/CommonConstant";
// import './CostHealthDetailsTable.css';
class CostHealthDetails extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      isAsp: this.props.isAsp !== undefined ? this.props.isAsp : false,
      hostOnly: this.props.data.metricsPlatform === "Host",
      entityType:
        this.props.entityType !== undefined
          ? this.props.entityType
          : this.props.isAsp !== undefined && this.props.isAsp === true
          ? "asp"
          : "vm",
      providerName: this.props.providerName,
    };
  }
  getTrendImage(trendValue) {
    if (trendValue === "Sharply Decreasing") {
      return (
        <img id="ArrowImage" src={require("../../images/Low_01.png")} alt="" />
      );
    } else if (trendValue === "Decreasing") {
      return (
        <img id="ArrowImage" src={require("../../images/Low_01.png")} alt="" />
      );
    } else if (trendValue === "Sharply Increasing") {
      return (
        <img id="ArrowImage" src={require("../../images/High_01.png")} alt="" />
      );
    } else if (trendValue === "Increasing") {
      return (
        <img id="ArrowImage" src={require("../../images/High_01.png")} alt="" />
      );
    } else if (trendValue === "Flat") {
      return (
        <img
          id="ArrowImage"
          src={require("../../images/Normal_01.png")}
          alt=""
        />
      );
    }
  }
  render() {
    const { data, type } = this.props;
    return (
      <div className="panel" id="DetailsPanel" style={{ padding: "15px 0px" }}>
        <Row>
          <Col>
            <div
              id="TableHeader"
              align="Center"
              style={{ borderRadius: "10px 10px 0px 0px" }}
            >
              {type}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table id="tableBorder">
              <thead id="ColumnHeader">
                <tr className="text-left">
                  <th style={{ width: "12.5%", verticalAlign: "middle" }}>
                    Resource Metrics
                  </th>
                  <th style={{ width: "12.5%", verticalAlign: "middle" }}>
                    Original Spec{" "}
                  </th>
                  <th style={{ width: "12.5%", verticalAlign: "middle" }}>
                    Original Limit
                  </th>
                  <th style={{ width: "12.5%", verticalAlign: "middle" }}>
                    Original Threshold & Trend
                  </th>
                  <th style={{ width: "12.5%", verticalAlign: "middle" }}>
                    Adjusted Threshold
                  </th>
                  <th style={{ width: "12.5%", verticalAlign: "middle" }}>
                    {this.state.entityType === "db"
                      ? "Max Cost"
                      : this.state.entityType === "asp"
                      ? "Cost per ASP Instance"
                      : "Cost*"}
                  </th>
                  <th style={{ width: "12.5%", verticalAlign: "middle" }}>
                    Utilization
                  </th>
                  <th style={{ width: "12.5%", verticalAlign: "middle" }}>
                    Health
                  </th>
                </tr>
              </thead>
              <tbody style={{ backgroundColor: "white" }}>
                {data.vm.map((key, i) => {
                  return (
                    <tr>
                      {i === 0 ? (
                        <td id="InnerRow" rowspan={data.vm.length}>
                          <Table>
                            <tr>
                              <td id="InnerRow">
                                {" "}
                                {this.state.entityType === "db"
                                  ? "vCore CPU Usage"
                                  : "CPU Usage"}
                              </td>
                            </tr>
                          </Table>
                        </td>
                      ) : (
                        ""
                      )}
                      {i === 0 ? (
                        <td
                          id="InnerRow"
                          rowspan={
                            data.vm.length *
                            (this.state.isAsp || this.state.entityType === "db"
                              ? 4
                              : 3)
                          }
                        >
                          {" "}
                          <Table>
                            <tr>
                              <td id="InnerRow">
                                {key.current_vmsize_name}
                                {key.hypervgeneration
                                  ? " (" + key.hypervgeneration + ")"
                                  : null}
                              </td>
                            </tr>
                          </Table>
                        </td>
                      ) : (
                        ""
                      )}
                      <td id="InnerRow">
                        <Table>
                          <tr>
                            <td>
                              {this.state.entityType === "db"
                                ? parseFloat(key.current_vcpu).toFixed(1)
                                : key.current_speed}{" "}
                              {this.state.isAsp
                                ? "ACUs"
                                : this.state.entityType === "db"
                                ? "vCore"
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {this.state.entityType === "db"
                                ? key.current_speed
                                : null}
                            </td>
                          </tr>
                        </Table>
                      </td>
                      <td>
                        <Table>
                          <tr>
                            <td id="DoubleInnerRow">
                              {key.cpuused}{" "}
                              {this.state.isAsp
                                ? "ACUs"
                                : this.state.entityType === "db"
                                ? "GHz"
                                : "GHz"}
                              &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                            </td>
                            <td id="InnerRow">
                              {this.getTrendImage(key.current_vcpu_trends)}
                            </td>
                          </tr>
                        </Table>
                      </td>
                      <td>
                        <Table>
                          <tr>
                            <td id="DoubleInnerRow">
                              {key.adjusted_cpuused}{" "}
                              {this.state.isAsp
                                ? "ACUs"
                                : this.state.entityType === "db"
                                ? "GHz"
                                : "GHz"}
                            </td>
                          </tr>
                        </Table>
                      </td>
                      <td
                        id="InnerRow"
                        rowspan={
                          data.vm.length *
                          (this.state.isAsp || this.state.entityType === "db"
                            ? 4
                            : 3)
                        }
                      >
                        <Table>
                          <tr>
                            <td id="InnerRow">
                              {" "}
                              {priceDisplay(
                                key.current_vm_price,
                                this.props.userCurrency
                              )}
                              /mo
                            </td>
                          </tr>
                        </Table>
                      </td>

                      <td id="veticalAlignment">
                        <Table>
                          <tr>
                            <td id="InnerRow">
                              {key.current_vcpu_utilisation}
                            </td>
                          </tr>
                          <tr>
                            <td id="InnerRow">
                              {this.state.entityType === "db"
                                ? "Cpu Cycles Used"
                                : "CPU Usage"}{" "}
                              {parseFloat(
                                key.current_vcpu_percent_utilisation
                              ).toFixed(2)}
                              %
                            </td>
                          </tr>
                        </Table>
                      </td>
                      <td id="veticalAlignment">
                        <Table>
                          <tr>
                            <td id="InnerRow">{key.current_vcpu_health}</td>
                          </tr>
                          <tr>
                            {this.state.isAsp ? (
                              <td id="InnerRow">
                                Response Time (
                                {parseFloat(
                                  key.current_cpu_health_value
                                ).toFixed(4)}
                                )
                              </td>
                            ) : this.state.entityType === "db" ? (
                              <td id="InnerRow">
                                Server Process Core Percentage (
                                {parseFloat(
                                  key.current_cpu_health_value
                                ).toFixed(4)}
                                %)
                              </td>
                            ) : (
                              <td id="InnerRow">
                                {this.state.hostOnly
                                  ? "CPU Saturation"
                                  : "CPU Stolen"}{" "}
                                ({key.current_cpu_health_value}%)
                              </td>
                            )}
                          </tr>
                          {this.state.isAsp ? (
                            <tr>
                              <td id="InnerRow">
                                HTTP Queue Length (
                                {parseFloat(
                                  key.current_mem_health_value
                                ).toFixed(4)}
                                )
                              </td>
                            </tr>
                          ) : null}

                          {this.state.entityType === "db" ? (
                            <tr>
                              <td id="InnerRow">
                                {" "}
                                Workers Percentage (
                                {parseFloat(
                                  key.workersPercentage_health_val
                                ).toFixed(4)}
                                %)
                              </td>
                            </tr>
                          ) : null}
                          {this.state.entityType === "db" ? (
                            <tr>
                              <td id="InnerRow">
                                {" "}
                                Session Percentage (
                                {parseFloat(
                                  key.sessionsPercentage_health_val
                                ).toFixed(4)}
                                %)
                              </td>
                            </tr>
                          ) : null}
                        </Table>
                      </td>
                    </tr>
                  );
                })}

                {this.state.entityType === "db"
                  ? data.osdisk.map((key, i) => {
                      return i === 0 ? (
                        <tr>
                          <td id="InnerRow" rowSpan={data.osdisk.length}>
                            {" "}
                            <Table>
                              <tr>
                                <td id="InnerRow">vCore Memory Usage</td>
                              </tr>
                            </Table>
                          </td>
                          <td>{key.current_os_disk_memory} GB </td>
                          <td>
                            <Table>
                              <tr>
                                <td>{key.os_readiops} GB</td>
                                <td>
                                  {" "}
                                  {this.getTrendImage(
                                    key.current_os_disk_read_iops_trends
                                  )}
                                </td>
                              </tr>
                            </Table>
                          </td>
                          <td>
                            <Table>
                              <tr>
                                <td>{key.adjusted_os_readiops} GB</td>
                              </tr>
                            </Table>
                          </td>

                          <td id="veticalAlignment">
                            <Table>
                              <tr>
                                <td id="InnerRow">
                                  {key.current_os_disk_utilisation}
                                </td>
                              </tr>
                              <tr>
                                <td id="InnerRow">
                                  Memory Used{" "}
                                  {parseFloat(
                                    key.diskReadIOPS_OS_PERCENT_UTIL
                                  ).toFixed(2)}
                                  %
                                </td>
                              </tr>
                            </Table>
                          </td>
                          <td id="verticalAlignment">
                            {data.vm.map((vmkey, i) => {
                              return (
                                <Table>
                                  <tr>
                                    <td id="InnerRow">
                                      {key.current_os_disk_health}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td id="InnerRow">
                                      {" "}
                                      Server Process Memory Percentage (
                                      {parseFloat(
                                        vmkey.serverProcessMemPercentage_health_val
                                      ).toFixed(4)}
                                      %)
                                    </td>
                                  </tr>
                                  <tr>
                                    <td id="InnerRow">
                                      {" "}
                                      Workers Percentage (
                                      {parseFloat(
                                        vmkey.workersPercentage_health_val
                                      ).toFixed(4)}
                                      %)
                                    </td>
                                  </tr>
                                  <tr>
                                    <td id="InnerRow">
                                      {" "}
                                      Session Percentage (
                                      {parseFloat(
                                        vmkey.sessionsPercentage_health_val
                                      ).toFixed(4)}
                                      %)
                                    </td>
                                  </tr>
                                </Table>
                              );
                            })}
                          </td>
                        </tr>
                      ) : (
                        ""
                      );
                    })
                  : null}

                {data.vm.map((key, i) => {
                  return (
                    <tr id="Row">
                      {i === 0 ? (
                        <td id="InnerRow" rowspan={data.vm.length}>
                          <Table>
                            <tr>
                              <td id="InnerRow">
                                {this.state.entityType === "db"
                                  ? "Data Disk Usage"
                                  : "Memory Usage"}
                              </td>
                            </tr>
                          </Table>
                        </td>
                      ) : (
                        ""
                      )}
                      <td>
                        <Table>
                          {this.state.entityType === "db" ? (
                            <tr>
                              <td id="InnerRow">
                                {key.current_data_space}
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td id="InnerRow">
                              {parseFloat(key.current_memory).toFixed(2)}{" "}
                              {this.state.entityType === "db"
                                ? "IOPS"
                                : this.state.providerName === "AWS"
                                ? "GiB"
                                : "GB"}
                            </td>
                          </tr>
                        </Table>
                      </td>
                      <td>
                        <Table>
                          <tr>
                            <td id="DoubleInnerRow">
                              {this.state.hostOnly
                                ? "N/A"
                                : this.state.entityType === "db"
                                ? key.data_storage_used
                                : parseFloat(key.memused).toFixed(2)}{" "}
                              {this.state.hostOnly
                                ? ""
                                : this.state.entityType === "db"
                                ? "GB"
                                : this.state.providerName === "AWS"
                                ? "GiB"
                                : "GB"}
                            </td>
                            <td id="InnerRow">
                              {this.state.hostOnly
                                ? ""
                                : this.getTrendImage(key.current_memory_trends)}
                            </td>
                          </tr>
                          {this.state.entityType === "db" ? (
                            <tr>
                              <td id="DoubleInnerRow">
                                {parseFloat(key.memused).toFixed(2)} IOPS
                              </td>
                              <td id="InnerRow">
                                {this.state.hostOnly
                                  ? ""
                                  : this.getTrendImage(
                                      key.current_data_storage_trends
                                    )}
                              </td>
                            </tr>
                          ) : null}
                        </Table>
                      </td>
                      <td>
                        <Table>
                          <tr>
                            <td id="DoubleInnerRow">
                              {this.state.hostOnly
                                ? "N/A"
                                : this.state.entityType === "db"
                                ? key.adjusted_data_storage_used
                                : parseFloat(key.adjusted_memused).toFixed(
                                    2
                                  )}{" "}
                              {this.state.hostOnly
                                ? ""
                                : this.state.entityType === "db"
                                ? "GB"
                                : this.state.providerName === "AWS"
                                ? "GiB"
                                : "GB"}
                            </td>
                          </tr>
                          {this.state.entityType === "db" ? (
                            <tr>
                              <td id="DoubleInnerRow">
                                {parseFloat(key.adjusted_memused).toFixed(2)}{" "}
                                IOPS
                              </td>
                            </tr>
                          ) : null}
                        </Table>
                      </td>
                      <td id="veticalAlignment">
                        <Table>
                          <tr rowSpan={this.state.hostOnly ? "2" : "1"}>
                            <td id="InnerRow">
                              {this.state.hostOnly
                                ? "N/A"
                                : key.current_memory_utilisation}
                            </td>
                          </tr>
                          {this.state.hostOnly ? (
                            ""
                          ) : this.state.entityType === "db" ? (
                            <tr>
                              <td id="InnerRow">
                                Data Storage Percentage{" "}
                                {parseFloat(
                                  key.current_data_storage_percent_utilization
                                ).toFixed(2)}
                                %
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td id="InnerRow">
                                Memory Usage{" "}
                                {parseFloat(
                                  key.current_memory_percent_utilisation
                                ).toFixed(2)}
                                %
                              </td>
                            </tr>
                          )}
                          {this.state.entityType === "db" ? (
                            <tr>
                              <td id="InnerRow">
                                Data IO Percentage (
                                {parseFloat(
                                  key.current_memory_percent_utilisation
                                ).toFixed(2)}
                                %)
                              </td>
                            </tr>
                          ) : null}
                        </Table>
                      </td>
                      <td id="veticalAlignment">
                        {this.state.isAsp ? (
                          <Table>
                            <tr>
                              <td id="InnerRow">
                                {" "}
                                {key.current_memory_health}
                              </td>
                            </tr>
                            <tr>
                              <td id="InnerRow">
                                Response Time (
                                {parseFloat(
                                  key.current_cpu_health_value
                                ).toFixed(4)}
                                )
                              </td>
                            </tr>
                            <tr>
                              <td id="InnerRow">
                                HTTP Queue Length (
                                {parseFloat(
                                  key.current_mem_health_value
                                ).toFixed(4)}
                                )
                              </td>
                            </tr>
                          </Table>
                        ) : (
                          <Table>
                            <tr rowSpan={this.state.hostOnly ? "2" : "1"}>
                              <td id="InnerRow">
                                {" "}
                                {this.state.hostOnly
                                  ? "N/A"
                                  : key.current_memory_health}
                              </td>
                            </tr>
                            {this.state.hostOnly ? (
                              ""
                            ) : (
                              <tr>
                                {this.state.isAsp ? (
                                  <td id="InnerRow">
                                    HTTP Queue Length (
                                    {parseFloat(
                                      key.current_mem_health_value
                                    ).toFixed(4)}
                                    )
                                  </td>
                                ) : this.state.entityType === "db" ? (
                                  <td id="InnerRow">
                                    Data Storage Percentage (
                                    {key.current_data_storage_health_value}%)
                                  </td>
                                ) : (
                                  <td id="InnerRow">
                                    Memory Saturation Percent (
                                    {key.current_mem_health_value}%)
                                  </td>
                                )}
                              </tr>
                            )}
                            {this.state.entityType === "db" ? (
                              <tr>
                                <td id="InnerRow">
                                  Data IO Percentage (
                                  {parseFloat(
                                    key.current_mem_health_value
                                  ).toFixed(2)}
                                  %)
                                </td>
                              </tr>
                            ) : null}
                          </Table>
                        )}
                      </td>
                    </tr>
                  );
                })}
                {data.vm.map((key, i) => {
                  return (
                    <tr>
                      {i === 0 ? (
                        <td id="InnerRow" rowspan={data.vm.length}>
                          <Table>
                            <tr>
                              <td id="InnerRow">
                                {this.state.entityType === "db"
                                  ? "Log Disk Usage"
                                  : "Network Bandwidth"}
                              </td>
                            </tr>
                          </Table>
                        </td>
                      ) : (
                        ""
                      )}
                      <td>
                        <Table>
                          {this.state.entityType === "db" ? (
                            <tr>
                              <td id="InnerRow">
                                {key.current_log_space}
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td id="InnerRow">
                              {key.current_network_bandwidth}
                            </td>
                          </tr>
                        </Table>
                      </td>
                      <td>
                        <Table>
                          {this.state.entityType !== "db" ? (
                            <tr>
                              <td id="HalfRowNtwBorder">
                                TX{" "}
                                {parseFloat(key.nettxbandwidth_aggr).toFixed(4)}{" "}
                                Mbps
                              </td>
                              <td id="HalfRowNtwBorder">
                                {this.getTrendImage(
                                  key.current_network_tx_trends
                                )}{" "}
                              </td>
                            </tr>
                          ) : null}
                          {this.state.entityType === "db" ? (
                            <tr>
                              <td id="DoubleInnerRow">
                                {parseFloat(key.nettxbandwidth_aggr).toFixed(2)}{" "}
                                GB
                              </td>
                              <td id="InnerRow">
                                {" "}
                                {this.getTrendImage(
                                  key.current_network_tx_trends
                                )}{" "}
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td id="HalfRowNtw">
                                RX{" "}
                                {parseFloat(key.netrxbandwidth_aggr).toFixed(4)}{" "}
                                Mbps
                              </td>
                              <td id="HalfRowNtw">
                                {" "}
                                {this.getTrendImage(
                                  key.current_network_rx_trends
                                )}{" "}
                              </td>
                            </tr>
                          )}
                          {this.state.entityType === "db" ? (
                            <tr>
                              <td if="DoubleInnerRow">
                                {parseFloat(key.netrxbandwidth_aggr).toFixed(4)}{" "}
                                Mbps
                              </td>
                              <td id="InnerRow">
                                {" "}
                                {this.getTrendImage(
                                  key.current_network_rx_trends
                                )}{" "}
                              </td>
                            </tr>
                          ) : null}
                        </Table>
                      </td>
                      <td>
                        <Table>
                          {this.state.entityType !== "db" ? (
                            <tr>
                              <td id="HalfRowNtwBorder">
                                TX{" "}
                                {parseFloat(
                                  key.adjusted_nettxbandwidth_aggr
                                ).toFixed(4)}{" "}
                                Mbps
                              </td>
                            </tr>
                          ) : null}
                          {this.state.entityType === "db" ? (
                            <tr>
                              <td id="DoubleInnerRow">
                                {parseFloat(
                                  key.adjusted_nettxbandwidth_aggr
                                ).toFixed(2)}{" "}
                                GB
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td id="HalfRowNtw">
                                RX{" "}
                                {parseFloat(
                                  key.adjusted_netrxbandwidth_aggr
                                ).toFixed(4)}{" "}
                                Mbps
                              </td>
                            </tr>
                          )}
                          {this.state.entityType === "db" ? (
                            <tr>
                              <td id="DoubleInnerRow">
                                {parseFloat(
                                  key.adjusted_netrxbandwidth_aggr
                                ).toFixed(4)}{" "}
                                Mbps
                              </td>
                            </tr>
                          ) : null}
                        </Table>
                      </td>
                      <td id="veticalAlignment">
                        <Table>
                          <tr>
                            <td id="InnerRow">
                              {key.current_network_utilisation}
                            </td>
                          </tr>
                          {this.state.entityType !== "db" ? (
                            <tr>
                              <td id="InnerRow">
                                TX Bandwidth Usage{" "}
                                {parseFloat(
                                  key.current_network_tx_bw_percent_utilisation
                                ).toFixed(4)}
                                %
                              </td>
                            </tr>
                          ) : null}
                          {this.state.entityType === "db" ? (
                            <tr>
                              {" "}
                              <td id="InnerRow">
                                Log Storage Used (
                                {parseFloat(
                                  key.current_network_rx_bw_percent_utilisation
                                ).toFixed(4)}
                                %)
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td id="InnerRow">
                                RX Bandwidth Usage{" "}
                                {parseFloat(
                                  key.current_network_rx_bw_percent_utilisation
                                ).toFixed(4)}
                                %
                              </td>
                            </tr>
                          )}
                          {this.state.entityType === "db" ? (
                            <tr>
                              {" "}
                              <td id="InnerRow">
                                Log IO Used (
                                {parseFloat(
                                  key.current_network_tx_bw_percent_utilisation
                                ).toFixed(4)}
                                %)
                              </td>
                            </tr>
                          ) : null}
                        </Table>
                      </td>
                      <td id="veticalAlignment">
                        <Table>
                          <tr>
                            <td id="InnerRow"> {key.current_network_health}</td>
                          </tr>
                          {this.state.entityType !== "db" ? (
                            <tr>
                              {this.state.isAsp ? (
                                <td id="InnerRow">
                                  HTTP Queue Length (
                                  {parseFloat(
                                    key.netRXPacketSize_health_value
                                  ).toFixed(4)}
                                  )
                                </td>
                              ) : (
                                <td id="InnerRow">
                                  Network TX PacketSize (
                                  {key.netRXPacketSize_health_value} bytes)
                                </td>
                              )}
                            </tr>
                          ) : null}
                          {this.state.isAsp ? (
                            ""
                          ) : this.state.entityType === "db" ? (
                            <tr>
                              <td id="InnerRow">
                                Log Storage Percentage(
                                {key.netTXPacketSize_health_value}%)
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td id="InnerRow">
                                Network RX PacketSize (
                                {key.netTXPacketSize_health_value})
                              </td>
                            </tr>
                          )}
                          {this.state.entityType === "db" ? (
                            <tr>
                              <td id="InnerRow">
                                Log IO Percentage(
                                {key.netRXPacketSize_health_value}%)
                              </td>
                            </tr>
                          ) : null}
                        </Table>
                      </td>
                    </tr>
                  );
                })}
                {this.state.entityType !== "db"
                  ? data.osdisk.map((key, i) => {
                      return i === 0 ? (
                        <tr>
                          <td
                            id="InnerRow"
                            rowSpan={
                              data.osdisk.length +
                              Object.keys(data.dataDisk).length
                            }
                          >
                            {" "}
                            <Table>
                              <tr>
                                <td id="InnerRow">
                                  {this.state.isAsp
                                    ? "Storage Used, Disk IOPS & Disk Throughput"
                                    : "Disk(s) IOPS & Throughput"}
                                </td>
                              </tr>
                            </Table>
                          </td>
                          {this.state.isAsp ? (
                            ""
                          ) : (
                            <td id="InnerRow">
                              OS Disk-
                              {key.current_os_disk_name
                                ? key.current_os_disk_name.toUpperCase()
                                : key.current_os_disk_name}
                            </td>
                          )}
                          <td>
                            <Table>
                              <tr>
                                <td id="HalfRowBorderLimit">
                                  {this.state.isAsp
                                    ? "--"
                                    : key.current_os_disk_iops}{" "}
                                  {this.state.isAsp ? "" : "IOPS"}{" "}
                                </td>
                              </tr>
                              <tr>
                                <td id="HalfRowLimit">
                                  {this.state.isAsp
                                    ? "--"
                                    : key.current_os_disk_bandwidth}
                                </td>{" "}
                              </tr>
                            </Table>
                          </td>
                          <td>
                            <Table>
                              <tr>
                                <td id="HalfRowBorder">
                                  Read {key.os_readiops} IOPS
                                </td>
                                <td id="HalfRowBorder">
                                  {" "}
                                  {this.getTrendImage(
                                    key.current_os_disk_read_iops_trends
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td id="HalfRowBorder">
                                  Write {key.os_writeiops} IOPS
                                </td>
                                <td id="HalfRowBorder">
                                  {this.getTrendImage(
                                    key.current_os_disk_write_iops_trends
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td id="HalfRowBorder">
                                  Read Throughput {key.os_readthroughput}{" "}
                                  {this.state.providerName === "AWS"
                                    ? "MiB/s"
                                    : "MBps"}
                                </td>
                                <td id="HalfRowBorder">
                                  {" "}
                                  {this.getTrendImage(
                                    key.current_os_disk_read_throughput_trends
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td id="HalfRow">
                                  Write Throughput {key.os_writethroughput}{" "}
                                  {this.state.providerName === "AWS"
                                    ? "MiB/s"
                                    : "MBps"}
                                </td>
                                <td id="HalfRow">
                                  {this.getTrendImage(
                                    key.current_os_disk_write_throughput_trends
                                  )}
                                </td>
                              </tr>
                            </Table>
                          </td>
                          <td>
                            <Table>
                              <tr>
                                <td id="HalfRowBorder">
                                  Read {key.adjusted_os_readiops} IOPS
                                </td>
                              </tr>
                              <tr>
                                <td id="HalfRowBorder">
                                  Write {key.adjusted_os_writeiops} IOPS
                                </td>
                              </tr>
                              <tr>
                                <td id="HalfRowBorder">
                                  Read Throughput{" "}
                                  {key.adjusted_os_readthroughput}{" "}
                                  {this.state.providerName === "AWS"
                                    ? "MiB/s"
                                    : "MBps"}
                                </td>
                              </tr>
                              <tr>
                                <td id="HalfRow">
                                  Write Throughput{" "}
                                  {key.adjusted_os_writethroughput}{" "}
                                  {this.state.providerName === "AWS"
                                    ? "MiB/s"
                                    : "MBps"}
                                </td>
                              </tr>
                            </Table>
                          </td>
                          {this.state.isAsp ? (
                            ""
                          ) : (
                            <td id="InnerRow">
                              <Table>
                                <tr>
                                  <td id="InnerRow">
                                    {" "}
                                    {priceDisplay(
                                      key.current_os_disk_price,
                                      this.props.userCurrency
                                    )}
                                    /mo
                                  </td>
                                </tr>
                              </Table>
                            </td>
                          )}
                          <td id="veticalAlignment">
                            <Table>
                              <tr>
                                {this.state.isAsp ? (
                                  <td id="InnerRow">
                                    Storage Used :{" "}
                                    {key.current_os_disk_utilisation}
                                  </td>
                                ) : (
                                  <td id="InnerRow">
                                    {key.current_os_disk_utilisation}
                                  </td>
                                )}
                              </tr>
                              <tr>
                                <td id="InnerRow">
                                  Read+Write IOPS Usage{" "}
                                  {key.diskReadWriteIOPS_OS_PERCENT_UTIL}%
                                </td>
                              </tr>
                              <tr>
                                <td id="InnerRow">
                                  Read+Write Throughput Usage{" "}
                                  {key.diskReadWriteThroughput_OS_PERCENT_UTIL}%
                                </td>
                              </tr>
                            </Table>
                          </td>
                          <td id="veticalAlignment">
                            <Table>
                              <tr>
                                <td id="InnerRow">
                                  {key.current_os_disk_health}
                                </td>
                              </tr>
                              {this.state.isAsp ? (
                                ""
                              ) : (
                                <tr>
                                  <td id="InnerRow">
                                    {this.state.hostOnly
                                      ? "IOPS Saturation"
                                      : "Read Latency"}{" "}
                                    ({key.diskReadLatency_OS_health_value}{" "}
                                    {this.state.hostOnly ? "%" : "milliseconds"}
                                    )
                                  </td>
                                </tr>
                              )}
                              {this.state.isAsp ? (
                                ""
                              ) : (
                                <tr>
                                  <td id="InnerRow">
                                    {this.state.hostOnly
                                      ? "Throughput Saturation"
                                      : "Write Latency"}{" "}
                                    ({key.diskWriteLatency_OS_health_value}{" "}
                                    {this.state.hostOnly ? "%" : "milliseconds"}
                                    )
                                  </td>
                                </tr>
                              )}
                              <tr>
                                {this.state.isAsp ? (
                                  <td id="InnerRow">
                                    Disk Queue Length (
                                    {parseFloat(
                                      key.diskAvgQueueLength_OS_health_value
                                    ).toFixed(4)}
                                    )
                                  </td>
                                ) : (
                                  <td id="InnerRow">
                                    Disk Queue (
                                    {key.diskAvgQueueLength_OS_health_value}{" "}
                                    count)
                                  </td>
                                )}
                              </tr>
                              <tr>
                                <td id="InnerRow">&nbsp;</td>
                                <td id="InnerRow">&nbsp;</td>
                              </tr>
                            </Table>
                          </td>
                        </tr>
                      ) : (
                        ""
                      );
                    })
                  : null}
                {this.state.entityType !== "db"
                  ? Object.keys(data.dataDisk).map((key) => {
                      return data.dataDisk[key].map((value, i) => {
                        return i === 0 ? (
                          <tr>
                            <td id="InnerRow">
                              <Table>
                                <tr>
                                  <td id="InnerRow">
                                    Data Disk {value.data_disk_numm}-{" "}
                                    {value.current_data_disk_name
                                      ? value.current_data_disk_name.toUpperCase()
                                      : value.current_data_disk_name}
                                  </td>
                                </tr>
                              </Table>
                            </td>
                            <td>
                              <Table>
                                <tr>
                                  <td id="HalfRowBorderLimit">
                                    {value.current_data_disk_iops} IOPS{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td id="HalfRowLimit">
                                    {value.current_data_disk_bandwidth}
                                  </td>{" "}
                                </tr>
                              </Table>
                            </td>
                            <td id="veticalAlignment">
                              <Table id="tableBorder">
                                <tr>
                                  <td id="HalfRowBorder">
                                    Read {value.dt_readiops} IOPS
                                  </td>
                                  <td id="HalfRowBorder">
                                    {this.getTrendImage(
                                      value.current_data_disk_read_iops_trends
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td id="HalfRowBorder">
                                    Write {value.dt_writeiops} IOPS
                                  </td>
                                  <td id="HalfRowBorder">
                                    {this.getTrendImage(
                                      value.current_data_disk_write_iops_trends
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td id="HalfRowBorder">
                                    Read Throughput {value.dt_readthroughput}{" "}
                                    MBps
                                  </td>
                                  <td id="HalfRowBorder">
                                    {this.getTrendImage(
                                      value.current_data_disk_read_throughput_trends
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td id="HalfRow">
                                    Write Throughput {value.dt_writethroughput}{" "}
                                    MBps
                                  </td>
                                  <td id="HalfRow">
                                    {this.getTrendImage(
                                      value.current_data_disk_write_throughput_trends
                                    )}
                                  </td>
                                </tr>
                              </Table>
                            </td>
                            <td id="veticalAlignment">
                              <Table id="tableBorder">
                                <tr>
                                  <td id="HalfRowBorder">
                                    Read {value.adjusted_dt_readiops} IOPS
                                  </td>
                                </tr>
                                <tr>
                                  <td id="HalfRowBorder">
                                    Write {value.adjusted_dt_writeiops} IOPS
                                  </td>
                                </tr>
                                <tr>
                                  <td id="HalfRowBorder">
                                    Read Throughput{" "}
                                    {value.adjusted_dt_readthroughput} MBps
                                  </td>
                                </tr>
                                <tr>
                                  <td id="HalfRow">
                                    Write Throughput{" "}
                                    {value.adjusted_dt_writethroughput} MBps
                                  </td>
                                </tr>
                              </Table>
                            </td>
                            <td id="InnerRow">
                              <Table>
                                <tr>
                                  <td id="InnerRow">
                                    {" "}
                                    {priceDisplay(
                                      value.current_data_disk_price,
                                      this.props.userCurrency
                                    )}
                                    /mo
                                  </td>
                                </tr>
                              </Table>
                            </td>
                            <td id="veticalAlignment">
                              <Table>
                                <tr>
                                  <td id="InnerRow">
                                    {value.current_data_disk_utilisation}
                                  </td>
                                </tr>
                                <tr>
                                  <td id="InnerRow">
                                    Read+Write IOPS Usage{" "}
                                    {value.diskReadWriteIOPS_PERCENT_UTIL}%
                                  </td>
                                </tr>
                                <tr>
                                  <td id="InnerRow">
                                    Read+Write Throughput Usage{" "}
                                    {value.diskReadWriteThroughput_PERCENT_UTIL}
                                    %
                                  </td>
                                </tr>
                              </Table>
                            </td>
                            <td id="veticalAlignment">
                              <Table>
                                <tr>
                                  <td id="InnerRow">
                                    {value.current_data_disk_health}
                                  </td>
                                </tr>
                                <tr>
                                  <td id="InnerRow">
                                    {this.state.hostOnly
                                      ? "IOPS Saturation"
                                      : "Read Latency"}{" "}
                                    ({value.diskReadLatency_DT_health_value}{" "}
                                    {this.state.hostOnly ? "%" : "milliseconds"}
                                    )
                                  </td>
                                </tr>
                                <tr>
                                  <td id="InnerRow">
                                    {this.state.hostOnly
                                      ? "Throughput Saturation"
                                      : "Write Latency"}{" "}
                                    ({value.diskWriteLatency_DT_health_value}{" "}
                                    {this.state.hostOnly ? "%" : "milliseconds"}
                                    )
                                  </td>
                                </tr>
                                <tr>
                                  <td id="InnerRow">
                                    Disk Queue (
                                    {value.diskAvgQueueLength_DT_health_value}{" "}
                                    count)
                                  </td>
                                </tr>
                                <tr>
                                  <td id="InnerRow">&nbsp; </td>
                                  <td id="InnerRow">&nbsp; </td>
                                </tr>
                              </Table>
                            </td>
                          </tr>
                        ) : (
                          ""
                        );
                      });
                    })
                  : null}
              </tbody>
            </Table>
          </Col>
        </Row>
        <div
          style={{ paddingLeft: "13px", fontSize: "16px", textAlign: "left" }}
        >
          <div
            dangerouslySetInnerHTML={{ __html: message.CostHealthFootNote }}
          />
        </div>
      </div>
    );
  }
}
export default CostHealthDetails;
