import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import Header from "./Header";
import { useHistory } from "react-router-dom";
import jstz from "jstz";
import * as Constdata from "../../Constants/ApiConstants";
import SerraLoader from "../CommonComponent/Loader";
import SerraError from "../CommonComponent/Error";

const StartAnalysis = ({ registerListners, processLogut }) => {
  const history = useHistory();
  const { basicVMId, basicVMParkingId, basicASPIDList } =
    history.location.state || {};
  const [modalLoader, setModalLoader] = useState(true);
  const [error, setError] = useState(false);
  const [orgInfo, setorgInfo] = useState(null);

  let timeoutId;

  useEffect(() => {
    registerListners(true);
    checkSessionStatus();
  }, []);

  // useEffect(() => {
  //   if (basicVMId) {
  //     startAnalysis({ basicVMIDList: basicVMId }, Constdata.STARTANALYSIS_URL);
  //   }
  // }, [basicVMId]);

  // useEffect(() => {
  //   if (basicVMParkingId && basicVMParkingId.length > 0) {
  //     startAnalysis(
  //       { basicASPIDList: basicVMParkingId },
  //       Constdata.PARKING_VM_START_ANALYSIS_URL
  //     );
  //   }
  // }, [basicVMParkingId]);

  // useEffect(() => {
  //   if (basicASPIDList && basicASPIDList.length > 0) {
  //     startAnalysis(
  //       { basicASPIDList: basicASPIDList },
  //       Constdata.CLEANUP_START_ANALYSIS_URL
  //     );
  //   }
  // }, [basicASPIDList]);

  const checkSessionStatus = () => {
    fetch(Constdata.SESSIONACTIVE_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => {
        if (response.redirected) {
          window.location.href = "/";
        } else {
          return response.json();
        }
      })
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          setorgInfo(responseJson.orgInfo);
          setModalLoader(false);
          if (responseJson.orgInfo && responseJson.orgInfo.isUserVerified) {
            window.localStorage.removeItem("resendEmail");
          }
        }
      })
      .catch((error) => {
        console.log("Session check failed:", error);
      });
  };

  useEffect(() => {
    analysis();
  }, [basicVMId, basicVMParkingId, basicASPIDList]);

  const analysis = async () => {
    try {
      setModalLoader(true);

      // Run each check independently to avoid unnecessary API calls
      if (basicVMId) {
        await startAnalysis(
          { basicVMIDList: basicVMId },
          Constdata.STARTANALYSIS_URL
        );
      }

      if (basicVMParkingId && basicVMParkingId.length > 0) {
        await startAnalysis(
          { basicASPIDList: basicVMParkingId },
          Constdata.PARKING_VM_START_ANALYSIS_URL
        );
      }

      if (basicASPIDList && basicASPIDList.length > 0) {
        await startAnalysis(
          { basicASPIDList: basicASPIDList },
          Constdata.CLEANUP_START_ANALYSIS_URL
        );
      }
    } catch (error) {
      console.error("Error during analysis: ", error);
    } finally {
      setModalLoader(false); // Ensure loader is turned off after all operations
    }
  };

  const startAnalysis = async (vmIDList, endpoint) => {
    try {
      const timezone = jstz.determine();
      const timezoneid = timezone.name();

      const payload = {
        userGtmId: timezoneid,
        ...vmIDList,
      };

      const response = await fetch(endpoint, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(payload),
      });

      const responseJson = await response.json();

      if (responseJson.success === true) {
        clearTimeout(timeoutId);
        return true;
      } else {
        setError({
          modalLoader: false,
          errorPopup: true,
          errormessage: responseJson.message,
          isErrorHeader: false,
        });
      }
    } catch (error) {
      console.error("Error during startAnalysis: ", error);
      setError({
        modalLoader: false,
        errorPopup: true,
        errormessage: "Failed to start analysis",
        isErrorHeader: false,
      });
    }
  };

  const startAnalysis_old = async (vmIDList, endpoint) => {
    const timezone = jstz.determine();
    const timezoneid = timezone.name();

    const payload = {
      userGtmId: timezoneid,
      ...vmIDList,
    };

    fetch(endpoint, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success === true) {
          clearTimeout(timeoutId);
          return true;
        } else {
          startAnalysis(vmIDList, endpoint);
          setError({
            modalLoader: false,
            errorPopup: true,
            errormessage: responseJson.message,
            isErrorHeader: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const setErrorPopupState = (event) => {
    setError({
      modalLoader: false,
      errorPopup: event,
      errormessage: "",
      isErrorHeader: false,
    });
  };

  const resendVerification = () => {
    fetch(Constdata.RESEND_ACTIVATION_LINK + "?t_id=" + orgInfo?.loggedInUser, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.success === true) {
          window.localStorage.setItem("resendEmail", true);
          setError({
            modalLoader: false,
            errorPopup: true,
            errormessage: responseJson.message,
            isErrorHeader: true,
          });
        } else {
          setError({
            isRedirect: true,
            modalLoader: false,
            errorPopup: true,
            errormessage: responseJson.message,
            isErrorHeader: true,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Header getStarted={true} orgInfo={orgInfo} processLogut={processLogut} />
      <div className="container-verf container-verf-padding">
        <div className="boxShadow" style={{ marginTop: "6rem" }}>
          {orgInfo &&
          !orgInfo.isUserVerified &&
          window.localStorage.getItem("resendEmail") !== "true" ? (
            <Row className="pageBgHeader">
              <Col sm="12" style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                    color: "#ffffff",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                >
                  An email has been sent to {orgInfo?.loggedInUser}. Please take
                  a moment to confirm your email.
                </div>
                <div
                  style={{
                    width: "20%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    className=""
                    id="PPU-btn"
                    onClick={() => resendVerification()}
                  >
                    Resend Email
                  </Button>
                </div>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col className="col-sm-8 p-5">
              <h2 style={{ fontWeight: "600" }}>Analysis in Progress</h2>

              <p className="mt-5" style={{ fontSize: "18px" }}>
                Congrats! CPO has started ML and AI based in-depth analysis and
                this may take some time depending on how many VMs there are.
                We'll email you when it's done. In the meantime, if you'd like,
                you can continue to the CPO dashboard and check progress as
                needed.
              </p>

              <Button
                className="mt-4"
                id="PPU-btn"
                onClick={() => history.push("/dashboard")}
              >
                Go to Dashboard
              </Button>
            </Col>
          </Row>
        </div>
        {modalLoader === true ? <SerraLoader></SerraLoader> : ""}
        {error.errorPopup === true ? (
          <SerraError
            Errordata={error.errormessage}
            ErrorPopup={error.errorPopup}
            setErrorMethod={setErrorPopupState}
            isError={error.isErrorHeader}
          ></SerraError>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default StartAnalysis;
