import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import {
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import * as message from "../../Constants/MessageConstant";
import ReactGA from "react-ga4";
import AccountPPU from "./AccountCreationPPU";
import AWSAccountPPU from "./AWSAccountCreationPPU";
import * as Constdata from "../../Constants/ApiConstants";
import SerraLoader from "../CommonComponent/Loader";
import SerraError from "../CommonComponent/Error";
import Hashmap from "hashmap";
import { connect } from "react-redux";
import {
  reset,
  setDataSourcePayload,
} from "../../reducers/dataSourcePayloadSlice";

class MetricsDataSource extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.toggleAccountPPU = this.toggleAccountPPU.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.fetchAllConfig = this.fetchAllConfig.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
    this.showError = this.showError.bind(this);
    this.enableDisableConfig = this.enableDisableConfig.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.manageCommonAction = this.manageCommonAction.bind(this);
    this.checkThis = this.checkThis.bind(this);
    this.freeTrialAnalysisList = this.freeTrialAnalysisList.bind(this);
    this.state = {
      disableCommon: false,
      modalupload: false,
      upload: false,
      modal: false,
      isEdit: false,
      modalAccountPPU: false,
      providerName: "",
      errormessage: "",
      errorPopup: false,
      modalLoader: false,
      modalDelete: false,
      isGoTo: false,
      isFetching: false,
      value: [],
      fields: {},
      result: [],
      disableVmManagement: true,
      isFreeTrial: false,
      isFreeTrialCompleted: false,
      isRedirect: true,
      selectedMap: new Hashmap(),
      enableRefresh: false,
      enableDelete: false,
      enableSwitch: true,
      swchecked: true,
      isFetchingStatus: false,
      currentVm: null,
      dataSourceName: null,
      isFreeTrialAnalysisStarted: true,
    };
  }
  timeoutId = null;
  toggleAccountPPU = () => {
    this.setState((prevState) => ({
      modalAccountPPU: !prevState.modalAccountPPU,
      isEdit: false,
    }));
  };
  toggleDelete = () => {
    this.setState((prevState) => ({
      modalDelete: !prevState.modalDelete,
    }));
  };
  checkThis = async (id) => {
    var ck = document.getElementById(id + "select");
    var { selectedMap } = this.state;
    var checkedCount = 0;
    this.state.value.map(async (key) => {
      if (document.getElementById(key.id + "select").checked === true) {
        checkedCount++;
      }
    });
    if (ck.checked === true) {
      selectedMap.set(id, id + "select");
    } else {
      selectedMap.delete(id);
    }
    if (checkedCount === this.state.value.length) {
      document.getElementById("ckAll").checked = true;
      this.checkAll();
    } else {
      document.getElementById("ckAll").checked = false;
    }
    await this.setState({ selectedMap: selectedMap });
    await this.manageCommonAction();
    console.log(this.state.selectedMap);
  };
  checkAll = async () => {
    var { selectedMap } = this.state;
    var allck = document.getElementById("ckAll");
    this.state.value.map(async (key) => {
      if (key.status !== "FETCHING") {
        document.getElementById(key.id + "select").checked = allck.checked;
      }
      if (allck.checked === true) {
        if (key.status !== "FETCHING") {
          selectedMap.set(key.id, key.id + "select");
        }
      } else {
        selectedMap.delete(key.id);
      }
    });
    await this.setState({ selectedMap: selectedMap });
    await this.manageCommonAction();
    // console.log('selectedMap', this.state.selectedMap);
  };

  async manageCommonAction() {
    var enbRfsh = false;
    var enbDel = false;
    var enableSw = true;
    var enabledMap = new Hashmap();
    var disabledMap = new Hashmap();

    if (this.state.selectedMap.count() > 0) {
      this.state.value.map(async (key) => {
        let isAnyPricFetching = false;
        if (this.state.providerName === "AWS") {
          isAnyPricFetching = false;
        } else if (this.state.providerName === "AZURE") {
          if (key.azureSubscriptions.length > 0) {
            key.azureSubscriptions.map((Sb, i) => {
              if (
                Sb.priceThreadStatus !== null &&
                Sb.priceThreadStatus.toLowerCase().includes("fetching")
              ) {
                isAnyPricFetching = true;
              }
            });
          }
        }
        if (
          this.state.selectedMap.has(key.id) &&
          !key.status.toLowerCase().includes("fetching") &&
          !isAnyPricFetching
        ) {
          enbRfsh = true;
          enbDel = true;
          if (key.isCPOEnabled) {
            enabledMap.set(key.id, key.id);
          } else {
            disabledMap.set(key.id, key.id);
          }
        }
      });
      if (this.state.selectedMap.count() === enabledMap.count()) {
        await this.setState({ swchecked: true });
        enableSw = false;
      } else if (this.state.selectedMap.count() === disabledMap.count()) {
        await this.setState({ swchecked: false });
        enableSw = false;
      }
    }
    await this.setState({
      enableRefresh: enbRfsh,
      enableDelete: enbDel,
      enableSwitch: enableSw,
    });
  }

  enableDisableConfig(id, isCPOStatus) {
    this.setState({ modalLoader: true });
    // var ck = document.getElementById("cpoSwitch");
    // var isChecked = ck.checked;
    var callurl = !isCPOStatus
      ? Constdata.ENABLE_CONFIG
      : Constdata.DISABLE_CONFIG;
    const payload = {
      configIds: [id],
    };

    fetch(`${callurl}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        console.log(responseJson);
        // var { selectedMap } = this.state;
        // selectedMap.clear();
        // this.setState({ selectedMap: selectedMap });
        // var allck = document.getElementById("ckAll");
        // allck.checked = false;
        if (responseJson.success === true) {
          if (responseJson.orgInfo.providerName === "AWS") {
            await this.setState({ value: responseJson.data.awsConfigs });
          } else if (responseJson.orgInfo.providerName === "AZURE") {
            await this.setState({ value: responseJson.data.configs });
          }

          if (this.state.value.length === 0) {
            this.setState({ modalAccountPPU: true });
          } else {
            var status = !isCPOStatus ? "EnableDS" : "DisableDS";
            await this.fetchAllConfig(status);
            await this.props.checkRefresh(status);
          }
          // this.state.value.map(async (key) => {
          //     document.getElementById(key.id + "select").checked = false;
          // });
          await this.setState({ modalLoader: false });
        } else if (
          responseJson.success === false &&
          responseJson.message === "No organization found"
        ) {
          this.setState({ modalLoader: false });
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.setState({
            isRedirect: true,
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        } else {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.ServerError,
            isErrorHeader: false,
            isRedirect: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  }
  async componentDidMount() {
    ReactGA.initialize(message.MeasurementId);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Data Sources",
    });
    await this.props.registerListners();
    await this.fetchAllConfig(1);
  }
  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId); // Clear timeout on component unmount
    }
  }

  async freeTrialAnalysisList(orgData) {
    if (orgData && orgData.freeTrialAnalysisList !== null) {
      const allEmpty = orgData.freeTrialAnalysisList.every(
        (item) => item === "" || item === null
      );
      return await !allEmpty;
    }
  }

  fetchAllConfig = async (type) => {
    this.props.registerListners(true);
    fetch(Constdata.GETALLACCOUNT_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        let isFreeTrialCompleted = false;
        if (responseJson.success === true) {
          if (responseJson.orgInfo.plan === "FREE_TRIAL") {
            isFreeTrialCompleted =
              responseJson.orgInfo.isVmFreeTrialCompleted ||
              responseJson.orgInfo.isAspFreeTrialCompleted ||
              responseJson.orgInfo.isDbFreeTrialCompleted ||
              responseJson.orgInfo.isCleanupFreeTrailCompleted ||
              responseJson.orgInfo.isParkingFreeTrialCompleted;
          }

          this.setState({
            isFreeTrialAnalysisStarted:
              responseJson.orgInfo.freeTrialAnalysisList !== null &&
              responseJson.orgInfo.plan === "FREE_TRIAL" &&
              responseJson.orgInfo.userName !== "support@serralabs.com"
                ? await this.freeTrialAnalysisList(responseJson.orgInfo)
                : false,
          });

          if (this.props.dataSourcePayload) {
            this.props.dataSourcePayload.map((ds) => {
              if (ds.isEdit) {
                if (responseJson.orgInfo.providerName === "AWS") {
                  var findIndexAWS =
                    responseJson.data.awsConfigs &&
                    responseJson.data.awsConfigs.findIndex(
                      (val) => val.awsKey === ds.awsKey
                    );
                  if (findIndexAWS !== -1) {
                    responseJson.data.awsConfigs[findIndexAWS].status =
                      "FETCHING";
                    responseJson.data.awsConfigs[findIndexAWS].statusReason =
                      "Fetching";
                  }
                } else {
                  var findIndex = responseJson.data.configs.findIndex(
                    (val) => val.tenantId === ds.tenantId
                  );
                  if (findIndex !== -1) {
                    responseJson.data.configs[findIndex].status = "FETCHING";
                    responseJson.data.configs[findIndex].statusReason =
                      "Fetching";
                  }
                }
              }
            });
          }

          if (responseJson.orgInfo.providerName === "AWS") {
            await this.setState({
              value: responseJson.data.awsConfigs,
              providerName: "AWS",
              modalLoader: false,
              isFreeTrial: responseJson.orgInfo.plan === "FREE_TRIAL",
              isFreeTrialCompleted: isFreeTrialCompleted,
            });
          } else {
            await this.setState({
              value: responseJson.data.configs,
              providerName: "AZURE",
              modalLoader: false,
              isFreeTrial: responseJson.orgInfo.plan === "FREE_TRIAL",
              isFreeTrialCompleted: isFreeTrialCompleted,
            });
          }

          let isFetching = false;
          this.setState({ isFetchingStatus: false });
          let failedCount = 0;
          this.state.value.map(async (key) => {
            let isAnyPricFetching = false;
            if (responseJson.orgInfo.providerName === "AWS") {
              isAnyPricFetching = false;
            } else {
              if (key.azureSubscriptions.length > 0) {
                key.azureSubscriptions.map((Sb, i) => {
                  if (
                    Sb.priceThreadStatus !== null &&
                    Sb.priceThreadStatus.toLowerCase().includes("fetching")
                  ) {
                    isAnyPricFetching = true;
                  }
                });
              }
            }
            if (
              key.status.toLowerCase().includes("fetching") ||
              isAnyPricFetching
            ) {
              isFetching = true;
              this.setState({ isFetchingStatus: true });
            }
            if (
              key.status.toLowerCase().includes("fail") &&
              key.statusReason !== "Fetching interrupted by user"
            ) {
              failedCount++;
            }
          });
          var disableNext =
            failedCount === this.state.value.length ||
            !responseJson.data.enableNext;
          if (responseJson.data.enableNext) {
            disableNext = !responseJson.data.enableNext;
          }
          await this.setState({ disableVmManagement: disableNext });

          if (!isFetching) {
            if (this.timeoutId) {
              clearTimeout(this.timeoutId); // Clear the previous timeout if it exists
            }
            this.setState({ fetchFlag: false });
            if (type !== 1) {
              await this.props.checkRefresh(type);
            }
          } else {
            if (this.timeoutId) {
              clearTimeout(this.timeoutId); // Clear the previous timeout if it exists
            }
            this.timeoutId = setTimeout(() => {
              this.fetchAllConfig();
            }, 5000);
          }
          await this.manageCommonAction();
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.setState({
            isRedirect: true,
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        } else {
          this.setState({
            isRedirect: false,
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          modalLoader: false,
          errorPopup: true,
          isRedirect: false,
          isErrorHeader: false,
        });
      });
  };
  handleEdit = (key) => {
    fetch(`${Constdata.GETAZURECONFIG_URL}${key}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.success === true) {
          var prefieldData = {};
          if (responseJson.orgInfo.providerName === "AWS") {
            prefieldData = {
              accountname: responseJson.data.accountname,
              accountKey: responseJson.data.awsKey,
              accountSecret: responseJson.data.awsSecret,
              storeSecret: responseJson.data.storeSecret,
              id: key,
              isCPOEnabled: responseJson.data.isCPOEnabled,
              region: responseJson.data.region,
            };
          } else {
            prefieldData = {
              accountname: responseJson.data.accountname,
              tenantId: responseJson.data.tenantId,
              clientSecret: responseJson.data.clientSecret,
              clientId: responseJson.data.clientId,
              storeSecret: responseJson.data.storeSecret,
              id: key,
              isCPOEnabled: responseJson.data.isCPOEnabled,
              region: responseJson.data.region,
            };
          }

          this.setState({
            fields: prefieldData,
            modalLoader: false,
            isEdit: true,
          });
          this.setState({ modalAccountPPU: true });
          this.fetchAllConfig();
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.setState({
            isRedirect: true,
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        } else {
          this.setState({
            isRedirect: false,
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  };
  handleDelete = () => {
    if (this.state.currentVm === "") {
      return;
    }
    this.setState({ modalLoader: true });

    const payload = {
      configIds: [this.state.currentVm],
    };
    fetch(Constdata.DELETESINGLEACCOUNT_URL, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        var { selectedMap } = this.state;
        selectedMap.clear();
        this.setState({ selectedMap: selectedMap });
        if (responseJson.success === true) {
          this.setState({ modalLoader: false, modalDelete: false });
          this.fetchAllConfig("DeleteDS");
          this.props.checkRefresh("DeleteDS");
          this.props.history.push("/dashboard");
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.setState({
            modalDelete: false,
            isRedirect: true,
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        } else {
          this.setState({
            modalDelete: false,
            isRedirect: false,
            modalLoader: false,
            errorPopup: true,
            errormessage: message.ServerError,
            isErrorHeader: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalDelete: false,
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  };
  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event });
    if (this.state.isRedirect) {
      this.props.history.push("/");
    }
  };
  showError(msg) {
    if (msg.includes("Application (ClientID) Role Issue")) {
      this.setState({
        isRedirect: false,
        modalLoader: false,
        errorPopup: true,
        errormessage:
          this.state.providerName === "AWS"
            ? message.AwsTestErrorMsg
            : message.AzureTestErrorMsg,
      });
    } else {
      this.setState({
        isRedirect: false,
        modalLoader: false,
        errorPopup: true,
        errormessage: msg,
      });
    }
  }

  enableRefresh(key) {
    let isAnyPricFetching = false;
    if (this.state.providerName === "AWS") {
      isAnyPricFetching = false;
    } else if (this.state.providerName === "AZURE") {
      if (key.azureSubscriptions.length > 0) {
        key.azureSubscriptions.map((Sb, i) => {
          if (
            Sb.priceThreadStatus !== null &&
            Sb.priceThreadStatus.toLowerCase().includes("fetching")
          ) {
            isAnyPricFetching = true;
          }
        });
      }
    }
    if (
      key.id &&
      !key.status.toLowerCase().includes("fetching") &&
      !isAnyPricFetching
    ) {
      return true;
    } else {
      return false;
    }
  }

  handleRefreshIndividual(id) {
    const payload = {
      configIds: [id],
    };
    this.setState({ isFetchingStatus: true });
    let newValues = [];
    this.state.value.map((Vm, i) => {
      if (Vm.id === id) {
        Vm.status = "FETCHING";
      }
      newValues.push(Vm);
    });
    this.setState({ value: newValues });
    this.manageCommonAction();
    fetch(`${Constdata.SELECT_REFRESH}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        console.log(responseJson);
        if (responseJson.success === true) {
          //await  this.setState({value: responseJson.data.configs, modalLoader: false});
          if (responseJson.orgInfo.providerName === "AWS") {
            await this.setState({
              value: responseJson.data.awsConfigs,
              providerName: "AWS",
              modalLoader: false,
            });
          } else {
            await this.setState({
              value: responseJson.data.configs,
              providerName: "AZURE",
              modalLoader: false,
            });
          }
          if (this.state.value.length === 0) {
            this.setState({ modalAccountPPU: true });
          }
          let isFetching = false;
          let failedCount = 0;
          this.state.value.map(async (key) => {
            if (key.status.toLowerCase().includes("fetching")) {
              isFetching = true;
            }
            if (key.status.toLowerCase().includes("fail")) {
              failedCount++;
            }
          });
          var disableNext =
            failedCount === this.state.value.length ||
            !responseJson.data.enableNext;
          if (responseJson.data.enableNext) {
            disableNext = !responseJson.data.enableNext;
          }
          await this.setState({ disableVmManagement: disableNext });
          if (!isFetching) {
            this.setState({ isFetchingStatus: false });
            if (this.timeoutId) {
              clearTimeout(this.timeoutId); // Clear the previous timeout if it exists
            }
            await this.props.checkRefresh();
          } else {
            this.timeoutId = setTimeout(() => {
              this.fetchAllConfig();
            }, 5000);
          }
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.setState({
            isRedirect: true,
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        } else {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.ServerError,
            isErrorHeader: false,
            isRedirect: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  }

  updateStatusOnSubmit = (id, type) => {
    if (type === "AWS") {
      console.log("AWS", id);
      var findIndex = this.state.value.findIndex((val) => val.awsKey === id);
      if (findIndex !== -1) {
        this.state.value[findIndex].status = "FETCHING";
        this.state.value[findIndex].statusReason = "Fetching";
        this.toggleAccountPPU();
      }
      this.props.checkRefresh("AddDS");
    } else {
      console.log("AZURE", id);
      var findIndex = this.state.value.findIndex((val) => val.tenantId === id);
      if (findIndex !== -1) {
        this.state.value[findIndex].status = "FETCHING";
        this.state.value[findIndex].statusReason = "Fetching";
        this.toggleAccountPPU();
      }
    }
  };

  render() {
    return (
      <div>
        <div
          className="container-verf container-verf-margin pageBgWhite"
          style={{ borderRadius: "10px" }}
          role="main"
        >
          <div className="frm-container">
            <div className="ManageAcntTable">
              <Table
                id="list_table"
                style={{ lineHeight: "3", textAlign: "center" }}
              >
                <thead>
                  <tr>
                    <th>Data Source Name</th>
                    <th>Data Source Access Status</th>
                    <th>Data Source Pricing Status</th>
                    <th>Enable</th>
                    <th>Edit</th>
                    <th>Delete</th>
                    <th>Refresh</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.value.map((Vm, i) => {
                    var isAnyPricFetching = false;
                    if (this.state.providerName === "AWS") {
                    } else {
                      if (Vm.azureSubscriptions.length > 0) {
                        Vm.azureSubscriptions.map((Sb, i) => {
                          if (
                            Sb.priceThreadStatus !== null &&
                            Sb.priceThreadStatus
                              .toLowerCase()
                              .includes("fetching")
                          ) {
                            isAnyPricFetching = true;
                          }
                        });
                      }
                    }

                    return (
                      <tr id="list_tablerow">
                        <td
                          style={{
                            opacity:
                              Vm.isCPOEnabled && Vm.status !== "FETCHING"
                                ? 1
                                : 0.5,
                            fontStyle: Vm.isCPOEnabled ? "" : "italic",
                          }}
                        >
                          {Vm.accountname}{" "}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            opacity:
                              Vm.isCPOEnabled && Vm.status !== "FETCHING"
                                ? 1
                                : 0.5,
                            fontStyle: Vm.isCPOEnabled ? "" : "italic",
                          }}
                        >
                          {Vm.status === "OK" ? (
                            "Yes"
                          ) : Vm.status === "FETCHING" ? (
                            <div>
                              <i
                                className="fa fa-circle-o-notch fa-spin"
                                aria-hidden="true"
                                data-toggle="tooltip"
                                data-placement="right"
                                title={"Checking"}
                              />
                              Checking
                            </div>
                          ) : Vm.status === "WARNING" ? (
                            Vm.statusReason
                          ) : (
                            <div>
                              No{" "}
                              <i
                                className="fa fa-warning"
                                style={{
                                  fontSize: "15px",
                                  color: "#e6da12",
                                  paddingLeft: "5px",
                                }}
                                data-placement="right"
                              >
                                {" "}
                              </i>
                            </div>
                          )}
                        </td>

                        <td
                          style={{
                            opacity:
                              Vm.isCPOEnabled && Vm.status !== "FETCHING"
                                ? 1
                                : 0.5,
                            fontStyle: Vm.isCPOEnabled ? "" : "italic",
                          }}
                        >
                          {this.state.providerName === "AWS" ? (
                            <>
                              {Vm.priceSource !== null ? (
                                <>
                                  {Vm.priceSource !== "fail" ? (
                                    <div>Available </div>
                                  ) : null}
                                  <div>{Vm.priceSource}</div>
                                </>
                              ) : (
                                <div>
                                  <i
                                    className="fa fa-circle-o-notch fa-spin"
                                    aria-hidden="true"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title={"Checking"}
                                  />
                                  Checking
                                </div>
                              )}
                            </>
                          ) : Vm.azureSubscriptions !== undefined &&
                            Vm.azureSubscriptions !== null &&
                            Vm.azureSubscriptions.length > 0 ? (
                            Vm.azureSubscriptions.map((Sb, i) => {
                              return (
                                <div>
                                  {Sb.priceThreadStatus !== null &&
                                  Sb.priceThreadStatus.toLowerCase() ===
                                    "ok" ? (
                                    Sb.priceSource !== null &&
                                    Sb.priceSource
                                      .toLowerCase()
                                      .includes("public") ? (
                                      <>
                                        <div>Available</div>
                                        <div>
                                          Using default Calculator pricing
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div>Available</div>
                                        <div
                                          style={{
                                            lineHeight: "1",
                                            paddingBottom: "10px",
                                          }}
                                        >
                                          {Sb.priceSource !== null ? (
                                            <>
                                              <div>Subscription Pricing</div>
                                              <div>
                                                (
                                                {Sb.priceSource !== null
                                                  ? Sb.priceSource.replace(
                                                      "Private-",
                                                      ""
                                                    )
                                                  : ""}
                                                )
                                              </div>
                                            </>
                                          ) : (
                                            <div>
                                              Using default Calculator pricing
                                            </div>
                                          )}
                                        </div>
                                      </>
                                    )
                                  ) : Sb.priceThreadStatus !== null &&
                                    Sb.priceThreadStatus.toLowerCase() ===
                                      "fetching" ? (
                                    <div>
                                      <i
                                        className="fa fa-circle-o-notch fa-spin"
                                        aria-hidden="true"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Checking"
                                      />
                                      Checking
                                    </div>
                                  ) : (
                                    <div>
                                      Unavailable{" "}
                                      <i
                                        className="fa fa-warning"
                                        style={{
                                          fontSize: "15px",
                                          color: "#e6da12",
                                          paddingLeft: "5px",
                                        }}
                                        data-placement="right"
                                        title="Subscription Pricing is Unavailable - Using General (Azure Calculator) Pricing"
                                      >
                                        {" "}
                                      </i>
                                    </div>
                                  )}
                                </div>
                              );
                            })
                          ) : (
                            <div>
                              Unavailable{" "}
                              <i
                                className="fa fa-warning"
                                style={{
                                  fontSize: "15px",
                                  color: "#e6da12",
                                  paddingLeft: "5px",
                                }}
                                data-placement="right"
                                title="Subscription Pricing is Unavailable - Using General (Azure Calculator) Pricing"
                              >
                                {" "}
                              </i>
                            </div>
                          )}
                        </td>
                        <td>
                          <Input
                            type="radio"
                            name={"isCPOEnabled" + Vm.id}
                            checked={Vm.isCPOEnabled ? true : false}
                            onClick={() => {
                              this.enableDisableConfig(Vm.id, Vm.isCPOEnabled);
                            }}
                            style={{
                              marginLeft: "0px",
                              height: "25px",
                              width: "25px",
                              verticalAlign: "middle",
                              accentColor: "#29a4a8",
                            }}
                          />
                        </td>

                        <td
                          style={{
                            opacity: Vm.status === "FETCHING" ? 0.5 : 1,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "10px 0px",
                            }}
                          >
                            {this.state.isFreeTrialAnalysisStarted ||
                            Vm.status.toLowerCase().includes("fetching") ||
                            !Vm.isCPOEnabled ? (
                              <div outline style={{ cursor: "pointer" }}>
                                <img
                                  src={require("../../images/EditIcon_Disabled_01.svg")}
                                  title="Edit"
                                />
                              </div>
                            ) : (
                              <div
                                outline
                                onClick={() => {
                                  this.handleEdit(Vm.id);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src={require("../../images/EditIcon_01.svg")}
                                  title="Edit"
                                />
                              </div>
                            )}
                          </div>
                        </td>
                        <td
                          style={{
                            opacity: Vm.status === "FETCHING" ? 0.5 : 1,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "10px 0px",
                            }}
                          >
                            {this.state.isFreeTrialAnalysisStarted ||
                            Vm.status.toLowerCase().includes("fetching") ? (
                              <div outline style={{ cursor: "pointer" }}>
                                <img
                                  src={require("../../images/DeleteIcon_Disabled_01.svg")}
                                  title="Delete"
                                />
                              </div>
                            ) : (
                              <div
                                outline
                                onClick={() => {
                                  this.toggleDelete();
                                  this.setState({
                                    currentVm: Vm.id,
                                    dataSourceName: Vm.accountname,
                                  });
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src={require("../../images/Delete_Icon.svg")}
                                  title="Delete"
                                />
                              </div>
                            )}
                          </div>
                        </td>
                        <td>
                          {this.enableRefresh(Vm) && Vm.isCPOEnabled ? (
                            <div
                              outline
                              onClick={() =>
                                this.handleRefreshIndividual(Vm.id)
                              }
                              style={{ marginRight: "10px", cursor: "pointer" }}
                            >
                              <img
                                src={require("../../images/Refresh_Icon.svg")}
                                title="Refresh Data Source"
                              />
                            </div>
                          ) : (
                            <div
                              outline
                              style={{ marginRight: "10px", cursor: "pointer" }}
                            >
                              <img
                                src={require("../../images/RefreshIcon_Disabled_01.svg")}
                                title="Refresh Data Source"
                              />
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}

                  {this.props.dataSourcePayload &&
                    this.props.dataSourcePayload.map((Vm, i) => {
                      return !Vm.isEdit &&
                        (!this.state.value.some(
                          (val) => val.tenantId == Vm.tenantId
                        ) ||
                          !this.state.value.some(
                            (val) => val.awsKey == Vm.awsKey
                          )) ? (
                        <tr id="list_tablerow">
                          <td
                            style={{
                              opacity:
                                Vm.isCPOEnabled && Vm.status !== "FETCHING"
                                  ? 1
                                  : 0.5,
                              fontStyle: Vm.isCPOEnabled ? "" : "italic",
                            }}
                          >
                            {Vm.accountname}{" "}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              opacity:
                                Vm.isCPOEnabled && Vm.status !== "FETCHING"
                                  ? 1
                                  : 0.5,
                              fontStyle: Vm.isCPOEnabled ? "" : "italic",
                            }}
                          >
                            {Vm.status === "OK" ? (
                              "Yes"
                            ) : Vm.status === "FETCHING" ? (
                              <div>
                                <i
                                  className="fa fa-circle-o-notch fa-spin"
                                  aria-hidden="true"
                                  data-toggle="tooltip"
                                  data-placement="right"
                                  title={"Checking"}
                                />
                                Checking
                              </div>
                            ) : Vm.status === "WARNING" ? (
                              Vm.statusReason
                            ) : (
                              <div>
                                No{" "}
                                <i
                                  className="fa fa-warning"
                                  style={{
                                    fontSize: "15px",
                                    color: "#e6da12",
                                    paddingLeft: "5px",
                                  }}
                                  data-placement="right"
                                >
                                  {" "}
                                </i>
                              </div>
                            )}
                          </td>

                          <td
                            style={{
                              opacity:
                                Vm.isCPOEnabled && Vm.status !== "FETCHING"
                                  ? 1
                                  : 0.5,
                              fontStyle: Vm.isCPOEnabled ? "" : "italic",
                            }}
                          >
                            <div>
                              <i
                                className="fa fa-circle-o-notch fa-spin"
                                aria-hidden="true"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Checking"
                              />
                              Checking
                            </div>
                          </td>
                          <td>
                            <Input
                              type="radio"
                              name={"isCPOEnabled" + i}
                              checked={false}
                              style={{
                                marginLeft: "0px",
                                height: "25px",
                                width: "25px",
                                verticalAlign: "middle",
                                accentColor: "#29a4a8",
                              }}
                            />
                          </td>

                          <td
                            style={{
                              opacity: Vm.status === "FETCHING" ? 0.5 : 1,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "10px 0px",
                              }}
                            >
                              <div outline style={{ cursor: "pointer" }}>
                                <img
                                  src={require("../../images/EditIcon_Disabled_01.svg")}
                                  title="Edit"
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "10px 0px",
                              }}
                            >
                              <div outline style={{ cursor: "pointer" }}>
                                <img
                                  src={require("../../images/DeleteIcon_Disabled_01.svg")}
                                  title="Delete"
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div
                              outline
                              style={{ marginRight: "10px", cursor: "pointer" }}
                            >
                              <img
                                src={require("../../images/RefreshIcon_Disabled_01.svg")}
                                title="Refresh Data Source"
                              />
                            </div>
                          </td>
                        </tr>
                      ) : null;
                    })}
                </tbody>
              </Table>
              <div
                className="btn-toolbar pageBgWhite"
                role="toolbar"
                aria-label="Toolbar with button groups"
                style={{
                  display: "flex",
                  padding: "20px",
                  justifyContent: "flex-end",
                  borderRadius: "0px 0px 10px 10px",
                }}
              >
                <div
                  className="btn-group mr-2"
                  role="group"
                  aria-label="Third group"
                >
                  <Button
                    id={
                      this.state.isFreeTrialAnalysisStarted
                        ? "listDisabledBtn"
                        : "listBtn"
                    }
                    onClick={this.toggleAccountPPU}
                    className="btn-detils"
                    disabled={this.state.isFreeTrialAnalysisStarted}
                  >
                    Add Data Source
                  </Button>
                  <Button className="btn listInfoBtn" onClick={this.toggle}>
                    <i className="fa fa-info" />
                  </Button>
                </div>
                <div role="group" aria-label="First group">
                  <Button
                    className="btn-cancel btn btn-light"
                    onClick={this.props.toggle}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
            <Modal
              size="lg"
              isOpen={this.state.modal}
              toggle={this.toggle}
              backdrop="static"
            >
              <ModalHeader
                id="modalheader_list"
                toggle={this.toggle}
                cssModule={{ "modal-title": "modalHeader" }}
              >
                <div
                  className="modalCompanyName"
                  dangerouslySetInnerHTML={{
                    __html: message.SerraLabsModalHeader,
                  }}
                />{" "}
                <div className="modalTitle">
                  {" "}
                  Add {this.state.providerName === "AWS" ? "AWS" : "Azure"}{" "}
                  Account
                </div>
              </ModalHeader>
              <ModalBody>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.providerName === "AWS"
                        ? message.AddAwsAccountText
                        : message.AddAzureAccountText,
                  }}
                />

                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.providerName === "AWS"
                        ? message.AwsClientInstructionText
                        : message.AzureClientInstructionText,
                  }}
                />
              </ModalBody>
              <ModalFooter id="knowMoreFooter">
                <Button id="confirmbtn_list" onClick={this.toggle}>
                  OK
                </Button>
              </ModalFooter>
            </Modal>
            <Modal
              size="lg"
              isOpen={this.state.modalAccountPPU}
              toggle={this.toggleAccountPPU}
              backdrop="static"
            >
              <ModalHeader
                id="modalheader_list"
                toggle={this.toggleAccountPPU}
                cssModule={{ "modal-title": "modalHeader" }}
              >
                <div
                  className="modalCompanyName"
                  dangerouslySetInnerHTML={{
                    __html: message.SerraLabsModalHeader,
                  }}
                />
              </ModalHeader>
              {
                //console.log("Inside metricdatasource providername = ", this.state.providerName);
                this.state.providerName === "AWS" ? (
                  <AWSAccountPPU
                    updateStatusOnSubmit={this.updateStatusOnSubmit}
                    addDataSourcesResponse={this.props.addDataSourcesResponse}
                    cancelAccount={this.toggleAccountPPU}
                    data={this.state.fields}
                    handleFetch={this.fetchAllConfig}
                    AccountEdit={this.state.isEdit}
                    dataRes={this.state.value}
                  ></AWSAccountPPU>
                ) : (
                  <AccountPPU
                    updateStatusOnSubmit={this.updateStatusOnSubmit}
                    addDataSourcesAzureResponse={
                      this.props.addDataSourcesAzureResponse
                    }
                    cancelAccount={this.toggleAccountPPU}
                    data={this.state.fields}
                    handleFetch={this.fetchAllConfig}
                    AccountEdit={this.state.isEdit}
                    dataRes={this.state.value}
                  ></AccountPPU>
                )
              }
            </Modal>
            <Modal
              size="lg"
              isOpen={this.state.modalDelete}
              toggle={this.toggleDelete}
              backdrop="static"
            >
              <ModalHeader
                id="modalheader_list"
                toggle={this.toggleDelete}
                cssModule={{ "modal-title": "modalHeader" }}
              >
                <div
                  className="modalCompanyName"
                  dangerouslySetInnerHTML={{
                    __html: message.SerraLabsModalHeader,
                  }}
                />{" "}
                <div className="modalTitle"> Delete Azure Account</div>
              </ModalHeader>
              <ModalBody>
                Are you sure you want to delete data source{" "}
                {this.state.dataSourceName}?
              </ModalBody>
              <ModalFooter id="knowMoreFooter">
                <Button
                  id="confirmbtn_list"
                  onClick={() => {
                    this.handleDelete();
                  }}
                >
                  Go Ahead
                </Button>
                <Button id="confirmbtn_list" onClick={this.toggleDelete}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
        {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}
        {this.state.errorPopup === true ? (
          <SerraError
            Errordata={this.state.errormessage}
            ErrorPopup={this.state.errorPopup}
            setErrorMethod={this.setErrorPopupState}
            isError={this.state.isErrorHeader}
          ></SerraError>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataSourcePayload: state.dataSourcePayload.payload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetDataSourcePayload: () => dispatch(reset()),
    setDataSourcePayload: (data) => dispatch(setDataSourcePayload(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MetricsDataSource);
