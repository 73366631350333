import React, { Component } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
} from "reactstrap";
import ReactGA from "react-ga4";
import Header from "../HeaderComponent/Header";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import SerraLoader from "../CommonComponent/Loader";
import SerraError from "../CommonComponent/Error";
import domtoimage from "dom-to-image";
import jstz from "jstz";
import {
  ColumnSeries,
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  Tooltip,
  DataLabel,
  StackingBarSeries,
  Chart,
} from "@syncfusion/ej2-react-charts";
import { getStringDate, priceDisplay } from "../../Constants/CommonConstant";

class CleanupReport extends Component {
  constructor(props) {
    super(props);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.handleBacktoCleanuplist = this.handleBacktoCleanuplist.bind(this);
    this.showDetails = this.showDetails.bind(this);
    this.handlePDF = this.handlePDF.bind(this);
    this.state = {
      providerName: "",
      loggedInUser: "",
      currentDateTime: 0,
      value: undefined,
      reportDataPayload:
        this.props.location.state.data !== undefined
          ? this.props.location.state.data
          : [],
      modalLoader: false,
      errorPopup: false,
      errormessage: "",
      isErrorHeader: false,
      isFreeTrial: false,
      chartLeftImage: "",
      chartRightImage: "",
      isShowDetails: false,
      currentDs: undefined,
      resourceTypePalette: [
        "#C8FFFF",
        "#007FFF",
        "#C8C8C8",
        "#646464",
        "#3200FF",
        "#00007F",
      ],
      costPalett: ["#FF9B29", "#84CD59"],
      legendSettings: {
        visible: true,
        position: "Bottom",
        margin: { top: 20 },
        textStyle: {
          size: "14px",
          fontWeight: "400",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
        alignment: "Center",
        toggleVisibility: false,
      },
      titleStyle: {
        color: "#393939",
        size: "16px",
        fontWeight: "bold",
        location: { x: "277", y: "26.5" },
      },
      marker: {
        dataLabel: {
          position: "Auto",
          visible: true,
          font: {
            color: "white",
            size: "16px",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "500",
          },
        },
      },
      costPerfMarker: {
        dataLabel: {
          position: "Outer",
          visible: true,
          font: {
            color: "#393939",
            size: "16px",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "500",
          },
        },
      },
      costxAxis: {
        valueType: "Category",
        labelStyle: {
          size: "16px",
          fontWeight: "500",
          color: "#808080",
          fontFamily: "Poppins, sans-serif",
        },
      },
      costyAxis: {
        labelStyle: {
          size: "16px",
          fontWeight: "500",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
      },
      chartTitleStyle: {
        color: "#393939",
        fontWeight: "500",
        size: "17px",
        fontFamily: "Poppins, sans-serif",
      },
      progressxAxis: {
        valueType: "Category",
        labelStyle: {
          size: "16px",
          fontWeight: "500",
          color: "#808080",
          fontFamily: "Poppins, sans-serif",
        },
      },
      progressyAxis: {
        labelFormat: "{value}%",
        minimum: 0,
        maximum: 100,
        interval: 25,
        labelStyle: {
          size: "12px",
          fontWeight: "400",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
      },
    };
  }

  async componentDidMount() {
    ReactGA.initialize(message.MeasurementId);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Specific Report",
    });
    this.props.registerListners(true);

    fetch(Constdata.CLEANUP_REPORT_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(this.state.reportDataPayload),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true && responseJson.data !== undefined) {
          if (
            responseJson.data.costGraph !== undefined &&
            responseJson.data.costGraph.costChart !== undefined
          ) {
            let mxCost = Math.max.apply(
              Math,
              responseJson.data.costGraph.costChart.map(function (o) {
                return o.current_cost;
              })
            );
            let interval = Math.round(mxCost / 4);

            await this.setState({
              costyAxis: {
                interval: interval,
                maximum: mxCost + interval,
                minimum: 0,
              },
            });
          }

          //      Progress Chart
          let order = {
            percent_unanalyzed: "#C8FFFF",
            percent_analyzed: "#007FFF",
            percent_analysis_ineligible: "#C8C8C8",
            percent_analysis_error: "#646464",
            percent_optimized: "#3200FF",
            percent_custom_sized: "#00007F",
          };

          let label = {
            percent_unanalyzed: "Unanalyzed",
            percent_analyzed: "Analyzed",
            percent_analysis_ineligible: "Analysis Ineligible",
            percent_analysis_error: "Analysis Issue",
            percent_optimized: "Optimally Cleaned",
            percent_custom_sized: "Custom Cleaned",
          };

          let keysSorted = Object.keys(
            responseJson.data.progressGraph.progressList[0]
          ).sort((a, b) => {
            return (
              Object.keys(order).indexOf(a) - Object.keys(order).indexOf(b)
            );
          });
          let fiterData = [];
          let data = responseJson.data.progressGraph.progressList[0];

          keysSorted.map((value) => {
            if (
              parseInt(data[value]) !== 0 &&
              data[value] !== null &&
              value.startsWith("percent_")
            ) {
              let obj1 = {
                type: "StackingBar",
                dataSource: [
                  {
                    resourceName: data.resourceName,
                    [value]: data[value],
                    [`actual_${value}`]: data[`actual_${value}`],
                  },
                ],
                xName: "resourceName",
                yName: value,
                name: label[value],
                legendShape: "Rectangle",
                fill: order[value],
                marker: {
                  dataLabel: {
                    visible: true,
                    font: { size: "16px", fontFamily: " Poppins, sans-serif" },
                  },
                },
              };
              fiterData.push(obj1);
            }
          });

          Chart.Inject(
            Chart,
            StackingBarSeries,
            Category,
            Tooltip,
            Legend,
            DataLabel
          );

          let chart = new Chart({
            //Initializing Primary X Axis
            primaryXAxis: {
              valueType: "Category",
              labelStyle: {
                size: "16px",
                fontWeight: "500",
                color: "#808080",
                fontFamily: "Poppins, sans-serif",
              },
            },
            //Initializing Primary Y Axis
            primaryYAxis: {
              minimum: 0,
              maximum: 100,
              interval: 25,
              labelStyle: {
                size: "12px",
                fontWeight: "400",
                color: "#393939",
                fontFamily: "Poppins, sans-serif",
              },
              labelFormat: "{value}%",
            },
            chartArea: {
              border: {
                width: 1,
              },
            },
            series: fiterData,
            legendSettings: this.state.legendSettings,
            height: "300",
            title: "Overall Metric Data Source Progress",
            titleStyle: this.state.chartTitleStyle,
            palettes: this.state.resourceTypePalette,
            pointRender: this.progressBarPointRenderer,
            axisLabelRender: this.axisLabelRender,
            marker: this.state.marker,
          });
          chart.appendTo("#Chart");

          //     End Progress Chart

          await this.setState({
            value: responseJson.data,
            modalLoader: false,
            isFreeTrial: responseJson.orgInfo.plan === "FREE_TRIAL",
            providerName: responseJson.orgInfo.providerName,
            currentDateTime: responseJson.orgInfo.currentDateTime,
            loggedInUser: responseJson.orgInfo.loggedInUser,
          });
          console.log(
            "Providername in cleanup report: " + this.state.providerName
          );
        } else {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.ServerError,
            isErrorHeader: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
        });
      });
  }

  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event });
    if (this.state.isRedirect) {
      this.props.processLogut();
      this.props.history.push("/");
    }
  };

  handleBacktoCleanuplist = () => {
    this.props.history.push({ pathname: "/manageCleanup" });
  };

  handlePDF = async () => {
    this.setState({ modalLoader: true });
    var timer = setInterval(() => {
      document.getElementById("LeftChart").click();
    }, 5000);
    let filename = "";
    const timezone = jstz.determine();
    const timezoneid = timezone.name();
    let chartLeftImage = "";
    await domtoimage
      .toPng(document.getElementById("costChart"), { quality: 1 })
      .then(function (dataUrl) {
        chartLeftImage = dataUrl;
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });

    this.chartLeftImage = chartLeftImage;
    let chartRightImage = "";
    await domtoimage
      .toPng(document.getElementById("progressChart"))
      .then(function (dataUrl) {
        chartRightImage = dataUrl;
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
    this.chartRightImage = chartRightImage;
    let rptDtd = [];
    this.state.reportDataPayload.map(async (key) => {
      rptDtd.push({ basicVMId: key });
    });
    const payload = {
      recList: rptDtd,
      duration: "current",
      userGtmId: timezoneid,
      leftChart: this.chartLeftImage,
      rightChart: this.chartRightImage,
    };
    let response = await fetch(Constdata.CLEANUP_PDF_REPORT_URL, {
      method: "POST",
      credentials: "include",
      responseType: "blob",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).catch((error) => {
      clearInterval(timer);
      this.setState({
        modalLoader: false,
        errorPopup: true,
        errormessage: message.CacheErrorText,
        isErrorHeader: false,
        isRedirect: false,
      });
    });
    if (response.status !== 500) {
      if (response.headers.get("Content-Disposition") === null) {
        clearInterval(timer);
        let js = await response.json();
        if (js.success === false) {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: js.message,
            isErrorHeader: false,
            isRedirect: false,
          });
        }
      } else {
        filename = response.headers
          .get("Content-Disposition")
          .split("filename=")[1];
        let blob = await response.blob();
        if (blob !== undefined && blob !== null) {
          let url = window.URL.createObjectURL(blob);
          let a = document.getElementById("pdfid");
          a.href = url;
          a.download = filename;
          a.click();
          clearInterval(timer);
          this.setState({ modalLoader: false, modalPDF: false });
        } else {
          clearInterval(timer);
          this.setState({ modalLoader: false, modalPDF: false });
        }
      }
    } else {
      clearInterval(timer);
      this.setState({
        modalLoader: false,
        errorPopup: true,
        errormessage: message.CacheErrorText,
        isErrorHeader: false,
        isRedirect: false,
      });
    }
  };

  showDetails = async (ds) => {
    console.log(ds);
    await this.setState({
      currentDs: ds,
    });
    await this.setState((prevState) => ({
      isShowDetails: !prevState.isShowDetails,
    }));
  };

  axisLabelRender = (args) => {
    if (!isNaN(args.value) && !isNaN(args.axis.endLabel)) {
      args.text = "";
    }
  };

  axisPointRender = (args) => {
    //        if (args.point.y == 0) {
    //            args.cancel = true;
    //        } else {
    args.point.y = parseFloat(args.point.y).toFixed(2);
    args.point.text = priceDisplay(
      args.point.y,
      this.state.value?.userCurrency
    );
    //}
  };

  progressBarPointRenderer = (args) => {
    console.log(args);
    if (args.point.y == 0) {
      args.cancel = true;
    } else {
      var text = "actual_" + args.series.properties.yName;
      if (
        args.series.properties.dataSource[0][text] !== undefined &&
        args.series.properties.dataSource[0][text] !== null
      ) {
        args.point.yValue = args.series.properties.dataSource[0][text];
      }
    }
  };

  render() {
    let pgTtl = "Cost-Progress Report for Cleanup";
    return (
      <div>
        <Header
          processLogout={this.props.processLogut}
          registerListners={this.props.registerListners}
          providerName={this.state.providerName}
          resourceType="Data Sources"
          datetime={this.state.currentDateTime}
          loggedInUser={this.state.loggedInUser}
        ></Header>

        <div
          className="container-verf container-verf-padding"
          role="main"
          id="fillme"
          style={{ marginTop: "50px" }}
        >
          <div className="container1 boxShadow">
            <Row className="pageBgHeader">
              <Col sm="12" style={{ display: "flex", alignItems: "center" }}>
                <div id="spaceFiller" style={{ width: "10%" }}></div>
                <div
                  style={{
                    width: "80%",
                    textAlign: "center",
                    color: "#ffffff",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                >
                  {pgTtl}
                </div>
                <div
                  style={{
                    width: "10%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div style={{ display: "inline-flex" }}>
                    <div
                      outline
                      onClick={this.handlePDF}
                      style={{ margin: "auto", cursor: "pointer" }}
                    >
                      <img
                        src={require("../../images/PDF_Icon1.svg")}
                        title="Save as PDF Report"
                      />
                    </div>
                    <div
                      outline
                      onClick={this.handleBacktoCleanuplist}
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                    >
                      <img
                        style={{ margin: "5px" }}
                        src={require("../../images/Close_Icon1.svg")}
                        title="Close and go back to Manage Cleanup"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div
              id="list_account"
              style={{ width: "100%", justifyContent: "space-between" }}
            >
              <div
                className="reportChart"
                style={{ padding: "0px", width: "100%", paddingBottom: "40px" }}
              >
                <div id="LeftChart">
                  <Row
                    id="costChart"
                    style={{ paddingTop: "20px", justifyContent: "center" }}
                  >
                    <Col sm="10">
                      <ChartComponent
                        id="cchart"
                        axisLabelRender={this.axisLabelRender}
                        pointRender={this.axisPointRender}
                        primaryXAxis={this.state.costxAxis}
                        primaryYAxis={this.state.costyAxis}
                        title={"Cleanup Monthly Cost Saving"}
                        titleStyle={this.state.chartTitleStyle}
                        palettes={this.state.costPalett}
                        legendSettings={this.state.legendSettings}
                      >
                        <Inject
                          services={[ColumnSeries, Legend, DataLabel, Category]}
                        />
                        <SeriesCollectionDirective>
                          <SeriesDirective
                            dataSource={
                              this.state.value !== undefined
                                ? this.state.value.costGraph.costChart
                                : []
                            }
                            xName="type"
                            yName="current_cost"
                            name="Current Cost"
                            type="Column"
                            columnWidth="0.9"
                            marker={this.state.costPerfMarker}
                          ></SeriesDirective>
                          <SeriesDirective
                            dataSource={
                              this.state.value !== undefined
                                ? this.state.value.costGraph.costChart
                                : []
                            }
                            xName="type"
                            yName="reduced_cost"
                            name="Post-Cleanup Cost"
                            type="Column"
                            columnWidth="0.9"
                            marker={this.state.costPerfMarker}
                          ></SeriesDirective>
                        </SeriesCollectionDirective>
                      </ChartComponent>
                    </Col>
                  </Row>
                  <Row
                    id="progressChart"
                    style={{ paddingTop: "20px", justifyContent: "center" }}
                  >
                    <Col sm="9">
                      <div id="Chart"></div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div style={{ padding: "20px 5px 20px 5px" }}>
              <div
                className="tableBgHeader"
                style={{
                  textAlign: "center",
                  color: "#ffffff",
                  fontSize: "18px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <b>Cleanup Details</b>
              </div>
              <Table id="tableSizing" style={{ fontSize: "14px" }}>
                <thead>
                  <tr>
                    <th style={{ width: "110px" }}>Metrics Data Source</th>
                    <th style={{ padding: "0px" }}>Benefits</th>
                    <th style={{ padding: "0px" }}>Current Cost</th>
                    <th style={{ padding: "0px" }}>Post-Cleanup Cost</th>
                    <th>Status</th>
                    <th style={{ width: "80px" }}>When</th>
                    <th style={{ width: "90px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.value !== undefined
                    ? this.state.value.configResultList.map((ds, i) => {
                        return (
                          <tr
                            style={{
                              textAlign: "center",
                              backgroundColor: "white",
                            }}
                          >
                            <td className="textfieldCentered">
                              <b>{ds.metricDataSourceName}</b>
                            </td>
                            <td className="textfieldCentered">
                              {ds.orphanList.length > 0 ? (
                                <span
                                  className="benefitsTblCol"
                                  onClick={() => this.showDetails(ds)}
                                >
                                  {ds.cleanupSummary}
                                </span>
                              ) : (
                                ds.cleanupSummary
                              )}{" "}
                            </td>
                            <td className="textfieldCentered">
                              {priceDisplay(
                                ds.current_cost,
                                this.state.value?.userCurrency
                              )}
                            </td>
                            <td className="textfieldCentered">
                              {priceDisplay(
                                ds.reduced_cost,
                                this.state.value?.userCurrency
                              )}
                            </td>
                            <td className="textfieldCentered">
                              {ds.cleanup_status}
                            </td>
                            <td className="textfieldCentered">
                              {getStringDate(ds.cleanupDateTime)}
                            </td>
                            <td className="textfieldCentered">
                              {ds.orphanList.length > 0 ? (
                                <span
                                  className="benefitsTblCol"
                                  onClick={() => this.showDetails(ds)}
                                >
                                  View Details
                                </span>
                              ) : (
                                "--"
                              )}
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </Table>
            </div>
            <Modal
              isOpen={this.state.isShowDetails}
              toggle={this.showDetails}
              size="lg"
              backdrop={"static"}
            >
              <ModalHeader
                id="modalheader_list"
                cssModule={{ "modal-title": "modalHeader" }}
                toggle={this.showDetails}
              >
                <div
                  className="modalCompanyName"
                  dangerouslySetInnerHTML={{
                    __html: message.SerraLabsModalHeader,
                  }}
                />{" "}
                <div className="modalTitle">List of Cleanable Resources</div>
              </ModalHeader>
              <ModalBody>
                <div style={{ height: "400px", overflow: "scroll" }}>
                  <Table
                    id="tableSizing"
                    bordered={false}
                    style={{ fontSize: "14px" }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{ backgroundColor: "#F6F6F6", width: "110px" }}
                        >
                          Resource Name
                        </th>
                        <th
                          style={{
                            border: "unset",
                            backgroundColor: "#F6F6F6",
                            padding: "0px",
                          }}
                        >
                          Resource Type
                        </th>
                        <th
                          style={{
                            border: "unset",
                            backgroundColor: "#F6F6F6",
                            padding: "0px",
                          }}
                        >
                          Region
                        </th>
                        <th
                          style={{
                            border: "unset",
                            backgroundColor: "#F6F6F6",
                            padding: "0px",
                          }}
                        >
                          Current Cost
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.currentDs !== undefined &&
                      this.state.currentDs.orphanList !== undefined
                        ? this.state.currentDs.orphanList
                            .sort(
                              (a, b) =>
                                parseFloat(b.price) - parseFloat(a.price)
                            )
                            .map((rs, i) => {
                              return (
                                <tr>
                                  <td className="textfieldCentered">
                                    {rs.resourceName}
                                  </td>
                                  <td className="textfieldCentered">
                                    {rs.resourceType}
                                  </td>
                                  <td className="textfieldCentered">
                                    {rs.region}
                                  </td>
                                  <td className="textfieldCentered">
                                    {priceDisplay(
                                      rs.price,
                                      this.state.value?.userCurrency
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                        : null}
                    </tbody>
                  </Table>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button className="btn btnBg" onClick={this.showDetails}>
                  Close
                </Button>
              </ModalFooter>
            </Modal>
            {this.state.errorPopup === true ? (
              <SerraError
                Errordata={this.state.errormessage}
                ErrorPopup={this.state.errorPopup}
                setErrorMethod={this.setErrorPopupState}
                isError={this.state.isErrorHeader}
              ></SerraError>
            ) : (
              ""
            )}
            {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}
          </div>
        </div>
        <a id="pdfid" />
      </div>
    );
  }
}
export default CleanupReport;
