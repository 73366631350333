import React, { Component } from "react";
import {
  Input,
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from "reactstrap";
import * as Constdata from "../../Constants/ApiConstants";
import SerraLoader from "../CommonComponent/Loader";
import SerraError from "../CommonComponent/Error";
import * as message from "../../Constants/MessageConstant";
import { connect } from "react-redux";
import {
  reset,
  setDataSourcePayload,
} from "../../reducers/dataSourcePayloadSlice";

class AwsDS extends Component {
  constructor(props) {
    super(props);
    this.handleSumbit = this.handleSumbit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.IsDisabled = this.IsDisabled.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.testConfig = this.testConfig.bind(this);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      orgId: "",
      fields: this.props.AccountEdit === true ? this.props.data : {},
      errors: {},
      modal: false,
      provider: "",
      modalLoader: false,
      errormessage: "",
      errorPopup: false,
      hidden: true,
      isRedirect: false,
      modalAccntAddedPopup: false,
      loader: false,
      isTestConfig: false,
    };
  }

  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  };
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //Name
    if (!fields["accountKey"]) {
      formIsValid = false;
      errors["accountKey"] = "Account Key cannot be empty";
    }
    //accountSecret
    if (!fields["accountSecret"]) {
      formIsValid = false;
      errors["accountSecret"] = "Account Secret cannot be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  handleChange(field, e) {
    this.setState({ isTestConfig: false });
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  handleStoreSecret(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.checked;
    this.setState({ fields });
  }
  handleSumbit = (e) => {
    e.preventDefault();
    const { accountname, accountKey, accountSecret, id, isCPOEnabled } =
      this.state.fields;
    const payload = {
      accountname: accountname,
      awsKey: accountKey,
      awsSecret: accountSecret,
      storeSecret: true,
      isEdit: this.props.AccountEdit,
      id: id,
      isCPOEnabled: isCPOEnabled,
    };
    this.setState({ loader: true });

    if (!this.handleValidation()) {
      return;
    }

    this.props.handlePage(3, true);
    this.props.dsAdded(false);

    fetch(Constdata.ADDUPDATE_AWS_CONFIG_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ loader: false });
        if (responseJson.success === true) {
          this.props.dsAdded(true);
        } else {
          this.props.dsError({
            modalLoader: false,
            errorPopup: true,
            errormessage: responseJson.message,
            isErrorHeader: true,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });

    this.props.handlePage(3, true);
  };

  testConfig = () => {
    const { accountname, accountKey, accountSecret, id } = this.state.fields;
    const payload = {
      accountname: accountname,
      awsKey: accountKey,
      awsSecret: accountSecret,
      storeSecret: true,
      isEdit: this.props.AccountEdit,
      id: id,
    };

    this.setState({ loader: true });

    if (!this.handleValidation()) {
      return;
    }
    fetch(Constdata.TEST_AWS_CONFIG_URL, {
      method: "PUT",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ loader: false });
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: responseJson.message,
          isErrorHeader: true,
        });
        if (responseJson.success === true) {
          this.setState({ loader: false, isTestConfig: true });
        } else {
          this.setState({ loader: false, isTestConfig: false });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          loader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
        });
      });
  };

  IsDisabled = () => {
    const { accountKey, accountSecret, accountname } = this.state.fields;
    return (
      !accountKey ||
      !accountSecret ||
      !accountname ||
      this.state.modalLoader ||
      this.state.loader
    );
  };
  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event });
    if (this.state.isRedirect) {
      this.props.history.push("/");
    }
  };

  render() {
    return (
      <div>
        <div className="container-verf" style={{ width: "100%" }} role="main">
          <div className="account_container ml-0 pl-0">
            <form>
              <div className="form-group">
                <label htmlFor="accountname">
                  AWS Metrics Data Source Name:
                </label>
                <Input
                  placeholder="Name for this AWS Data Source"
                  type="text"
                  id="accountname"
                  name="accountname"
                  value={this.state.fields["accountname"]}
                  onChange={this.handleChange.bind(this, "accountname")}
                  disabled={this.props.AccountEdit}
                />
                <span style={{ color: "red" }}>
                  {this.state.errors["accountname"]}
                </span>
              </div>
              <div className="form-group">
                <label htmlFor="accountKey">AWS Access Key:</label>
                <Input
                  placeholder="Access Key"
                  type="text"
                  id="accountKey"
                  name="accountKey"
                  value={this.state.fields["accountKey"]}
                  onChange={this.handleChange.bind(this, "accountKey")}
                  disabled={this.props.AccountEdit}
                />
                <span style={{ color: "red" }}>
                  {this.state.errors["accountKey"]}
                </span>
              </div>

              <div className="form-group">
                <label htmlFor="accountSecret">AWS Secret Access Key:</label>
                <InputGroup>
                  <Input
                    placeholder="Secret Access Key"
                    type={this.state.hidden ? "password" : "text"}
                    id="accountSecret"
                    name="accountSecret"
                    value={this.state.fields["accountSecret"]}
                    onChange={this.handleChange.bind(this, "accountSecret")}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i
                        className="fa fa-eye-slash"
                        onClick={this.toggleShow}
                      ></i>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <span style={{ color: "red" }}>
                  {this.state.errors["accountSecret"]}
                </span>
              </div>

              <div className="prim_But1">
                <Button
                  id={this.IsDisabled() ? "btn_disabled" : "btnaccount_test"}
                  disabled={this.IsDisabled()}
                  onClick={() => this.testConfig()}
                >
                  Test
                </Button>
              </div>

              <div className="d-flex mt-5">
                <Button
                  className="btn-cancel"
                  onClick={() => this.props.handlePage(1)}
                >
                  Back
                </Button>
                {this.props.forward ? (
                  <Button
                    id="btnaccount_cancel"
                    style={{ width: "initial" }}
                    onClick={() => this.props.handlePage(3)}
                  >
                    Forward
                  </Button>
                ) : null}
                <Button
                  id={
                    this.IsDisabled() || !this.state.isTestConfig
                      ? "PPU_listdisabled"
                      : "PPU-btn"
                  }
                  type="submit"
                  onClick={this.handleSumbit}
                  disabled={this.IsDisabled() || !this.state.isTestConfig}
                >
                  Start Collecting Data
                </Button>
              </div>

              {this.state.loader === true ? (
                <SerraLoader loader="true"></SerraLoader>
              ) : (
                ""
              )}
            </form>
          </div>
          {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}
          {this.state.errorPopup === true ? (
            <SerraError
              Errordata={this.state.errormessage}
              ErrorPopup={this.state.errorPopup}
              setErrorMethod={this.setErrorPopupState}
              isError={this.state.isErrorHeader}
              subTitle={this.state.subTitle}
            ></SerraError>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataSourcePayload: state.dataSourcePayload.payload,
    companyDetails: state.companyDetails.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetDataSourcePayload: () => dispatch(reset()),
    setDataSourcePayload: (data) => dispatch(setDataSourcePayload(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AwsDS);
