/* global fetch */
import React, { Component } from "react";
import { Table, Modal, ModalHeader, ModalBody } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import * as message from "../../Constants/MessageConstant";
import PdfGuestMetricsAzure from "../../docs/Enabling_Guest_Metrics_for_Azure_VMs_07-15-2024.pdf";
import PdfGuestMetricsAWS from "../../docs/Enabling_Guest_Metrics_for_AWS_VMs_07-15-2024.pdf";

class IneligibleVMList extends Component {
  constructor(props) {
    super(props);
    this.renderVmReason = this.renderVmReason.bind(this);
    this.renderVmMem = this.renderVmMem.bind(this);
    this.renderVmCpu = this.renderVmCpu.bind(this);
    this.renderVmDisk = this.renderVmDisk.bind(this);
    this.renderVmNet = this.renderVmNet.bind(this);
    this.toggleReason = this.toggleReason.bind(this);
    this.sortVmReason = this.sortVmReason.bind(this);
    this.state = {
      value: this.props.vmlist,
      reasons: [],
      modalReason: false,
      resTitle: "Missing Metrics",
      columns: [
        {
          dataField: "basicvm_id",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "name",
          text: "VM Name",
          sort: true,
          headerStyle: {
            minWidth: "60px",
          },
          style: {
            pointerEvents: "none",
            wordBreak: "break-word",
            fontWeight: "600",
          },
        },
        {
          dataField: "accountName",
          text: "Metric Data Source",
          sort: true,
          headerStyle: {
            minWidth: "60px",
          },
          style: {
            pointerEvents: "none",
            wordBreak: "break-word",
          },
        },
        {
          dataField: "ostype",
          text: "OS Type",
          style: {
            wordBreak: "break-word",
          },
        },
        {
          dataField: "vmsize_Name",
          text: "VM Size",
          style: {
            wordBreak: "break-word",
          },
        },
        {
          dataField: "region",
          text: "Region",
          style: {
            wordBreak: "break-word",
          },
        },
        {
          dataField: "num_CPU",
          text: "vCPU",
          headerStyle: {
            width: "90px",
          },
          style: {
            wordBreak: "break-word",
          },
        },
        {
          dataField: "cpuspeed",
          formatter: this.renderVmCpu,
          text: "vCPU Speed (GHz)",
          style: {
            wordBreak: "break-word",
          },
        },
        {
          dataField: "max_Memory",
          formatter: this.renderVmMem,
          text: "Memory (GB)",
          headerStyle: {
            width: "90px",
          },
          style: {
            wordBreak: "break-word",
          },
        },
        {
          dataField: "num_Disks",
          text: "#Disks",
          headerStyle: {
            width: "90px",
          },
          style: {
            wordBreak: "break-word",
          },
        },
        {
          dataField: "aggregateIops",
          formatter: this.renderVmDisk,
          text: "Aggregate Disk IOPS/Throughput Limits",
          headerStyle: {
            width: "150px",
          },
          style: {
            wordBreak: "break-word",
          },
        },
        {
          dataField: "networkbandwidth",
          formatter: this.renderVmNet,
          text: "Aggregate Network Bandwidth Limit",
          headerStyle: {
            width: "150px",
            fontSize: "16px",
          },
          style: {
            wordBreak: "break-word",
          },
        },
        {
          dataField: "status",
          text: "Reason",
          sort: true,
          formatter: this.renderVmReason,
          sortValue: this.sortVmReason,
          style: {
            padding: "0px !important",
            fontWeight: "600",
            wordBreak: "break-word",
            textAlign: "center",
          },
          headerStyle: {
            padding: "0px !important",
            width: "230px",
          },
        },
      ],
    };
  }

  async toggleReason(Vm) {
    if (Vm.basicvm_id === undefined) {
      await this.setState({ reasons: [] });
    } else {
      let res = [];
      let ttl = "";
      if (Vm.ineligibility.otherReason !== null) {
        res.push(Vm.ineligibility.otherReason);
        ttl = "Reasons";
      }
      if (Vm.ineligibility.missingMetricNames !== null) {
        res.push(Vm.ineligibility.missingMetricNames);
        ttl = "Missing Metrics";
      }
      await this.setState({ reasons: res, resTitle: ttl });
    }

    await this.setState((prevState) => ({
      modalReason: !prevState.modalReason,
    }));
  }

  renderVmReason(cell, Vm) {
    if (Vm.isstaleds === true) {
      return <div className="textfieldCentered">{message.IsStaledsMsg}</div>;
    }
    let reason = "";
    let missingmet = "";
    if (Vm.ineligibility.otherReason !== null) {
      reason = Vm.ineligibility.otherReason;
    } else if (Vm.ineligibility.missingMetricNames !== null) {
      missingmet = Vm.ineligibility.missingMetricNames;
    }

    let notAvailable = false;
    if (
      Vm.ineligibility.otherReason === null &&
      Vm.ineligibility.missingMetricNames === null &&
      Vm.ineligibility.otherReason === null &&
      this.props.cpoAnalysisType === "Parking"
    ) {
      notAvailable = true;
    }

    let pdfDoc = PdfGuestMetricsAWS;
    if (this.props.providerName === "AZURE") {
      pdfDoc = PdfGuestMetricsAzure;
    }

    if (reason !== "") {
      switch (reason) {
        case "Failed Diagnostics Enablement":
          return (
            <div className="textfieldCentered">
              <a style={{ fontSize: "14px" }} href={pdfDoc} target="_blank">
                <div>Failed Diagnostics Enablement</div>
              </a>
            </div>
          );
        case "Diagnostics Enablement status unknown":
        case "Pending Diagnostics Enablement":
          return (
            <div className="textfieldCentered">
              <a style={{ fontSize: "14px" }} href={pdfDoc} target="_blank">
                <div>Pending Diagnostics Enablement</div>
              </a>
            </div>
          );
        case "Diagnostics Disabled":
          return (
            <div className="textfieldCentered">
              <a style={{ fontSize: "14px" }} href={pdfDoc} target="_blank">
                <div>
                  Guest-level metrics not available as guest-level monitoring is
                  disabled
                </div>
              </a>
            </div>
          );
        case "VM Diagnostics Extension is in bad state":
          return (
            <div className="textfieldCentered">
              <a style={{ fontSize: "14px" }} href={pdfDoc} target="_blank">
                <div>VM Diagnostics Extension is in bad state</div>
              </a>
            </div>
          );
        case "CloudWatch agent missing or misconfigured":
          return (
            <div className="textfieldCentered">
              <a style={{ fontSize: "14px" }} href={pdfDoc} target="_blank">
                <div>
                  Guest-level metrics not available as CloudWatch monitoring is
                  disabled
                </div>
              </a>
            </div>
          );
      }
    } else if (missingmet !== "") {
      return (
        <div className="textfieldCentered">
          <a
            style={{ fontSize: "14px" }}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              this.toggleReason(Vm);
            }}
          >
            <div>Missing Metrics</div>
          </a>
        </div>
      );
    } else if (notAvailable) {
      return (
        <div className="textfieldCentered">Guest metrics not available</div>
      );
    }

    return reason;
  }

  sortVmReason(cell, Vm) {
    let reason = "";
    let missingmet = "";
    if (Vm.ineligibility.otherReason !== null) {
      reason = Vm.ineligibility.otherReason;
    } else if (Vm.ineligibility.missingMetricNames !== null) {
      missingmet = Vm.ineligibility.missingMetricNames;
    }
    if (reason !== "") {
      return reason;
    } else if (missingmet !== "") {
      return "Missing Metrics";
    }

    return reason;
  }
  renderVmCpu(cell, Vm) {
    let val = Vm.cpuspeed / 1000 / 1000 / 1000;
    return val;
  }
  renderVmMem(cell, Vm) {
    const num = this.state.providerName === "AZURE" ? 1000 : 1024;
    return "" + Vm.max_Memory / num / num / num;
  }
  renderVmDisk(cell, Vm) {
    return Vm.aggregateIops + "/" + Vm.aggregateThroughput + " Mbps";
  }
  renderVmNet(cell, Vm) {
    return Vm.networkbandwidth + " Mbps";
  }

  render() {
    return (
      <div>
        <div style={{ textAlign: "center", borderTop: "1px solid gray" }}>
          <BootstrapTable
            id="list_table"
            bordered={false}
            bodyClasses="tbodyColor"
            classes=" table-responsive"
            bootstrap4={true}
            keyField="basicvm_id"
            data={this.state.value}
            columns={this.state.columns}
            wrapperClasses="scrollTable"
          />
        </div>
        <Modal
          isOpen={this.state.modalReason}
          toggle={this.toggleReason}
          size="lg"
          backdrop={"static"}
        >
          <ModalHeader
            id="modalheader_list"
            cssModule={{ "modal-title": "modalHeader" }}
            toggle={this.toggleReason}
          >
            <div
              className="modalCompanyName"
              dangerouslySetInnerHTML={{ __html: message.SerraLabsModalHeader }}
            />
            <div className="modalTitle">{this.state.resTitle}</div>
          </ModalHeader>
          <ModalBody>
            <Table id="list_table" bordered>
              <tbody>
                {this.state.reasons.map((res, i) => {
                  if (!res.includes("Diagnostics")) {
                    return (
                      <tr>
                        <td style={{ padding: "15px" }}>
                          <b>
                            Following metrics are not configured in VM
                            diagnostics settings :{" "}
                          </b>
                          <div>
                            <div>{res}</div>
                            <div
                              style={{
                                border: "1px solid gray",
                                marginTop: "10px",
                              }}
                            >
                              <p style={{ fontSize: "16px", padding: "5px" }}>
                                see{" "}
                                <a
                                  style={{ fontSize: "14px" }}
                                  href={
                                    this.props.providerName === "AZURE"
                                      ? PdfGuestMetricsAzure
                                      : PdfGuestMetricsAWS
                                  }
                                  target="_blank"
                                >
                                  this document
                                </a>{" "}
                                on how to add missing metrics automatically or
                                manually{" "}
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <td style={{ padding: "15px" }}>
                          <div>
                            <div>
                              <b>{res}</b>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </Table>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default IneligibleVMList;
