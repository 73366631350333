import React, { useState } from "react";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import { useHistory } from "react-router-dom";
import SerraLoader from "../CommonComponent/Loader";
import SerraError from "../CommonComponent/Error";

const Header = ({ orgInfo }) => {
  const history = useHistory();

  const [modalLoader, setModalLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState(false);

  const Logo = (
    <img src={require("../../images/SerraLabs_Logo_NewColors.png")} alt="" />
  );

  const handleLogout = async () => {
    setModalLoader(true);
    await fetch(Constdata.LOGOUT_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        window.localStorage.removeItem("resendEmail");
        processResponse(responseJson);
      })
      .catch((error) => {
        console.error(error);
        logoutError({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.ServerError,
          isErrorHeader: false,
        });
      });
  };

  const processResponse = (response) => {
    if (response.success === true) {
      history.push("/");
      setModalLoader(false);
    } else if (response.success === false) {
      logoutError({
        isRedirect: true,
        modalLoader: false,
        errorPopup: true,
        errormessage: response.message,
      });
    } else if (response.status === 403 || response.status === 401) {
      history.push("/");
    } else {
      logoutError({
        modalLoader: false,
        errorPopup: true,
        errormessage: message.ServerError,
        isErrorHeader: false,
      });
    }
  };

  const logoutError = (error) => {
    setError(true);
    setErrorData(error);
  };

  const setErrorPopupState = (event) => {
    setError(event);
  };

  return (
    <div className="head-section">
      <div className="navbarHeader d-flex justify-content-between mx-3">
        <div id="logoWidth" className="logo-container">
          {Logo}
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "22px",
            color: "#610058",
            fontWeight: 600,
          }}
        >
          Getting Started
        </div>
        <div className="me-3 d-flex align-items-center">
          <div>{orgInfo?.loggedInUser}</div>
          <div className="manage-option-btn" id="selectDisplay">
            <div className="divcenter">
              <div className="option-dropdown">
                <div className="icon-border">
                  <i
                    style={{ fontSize: "25px", color: "#2500A1" }}
                    className="fa fa-bars"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="dropdown-content">
                  <ul className="dropdown-menus">
                    <li className="dropdown-menu2">
                      <div
                        className="dropdown-list"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleLogout()}
                      >
                        Logout
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalLoader === true ? <SerraLoader></SerraLoader> : ""}
      {error === true ? (
        <SerraError
          Errordata={errorData.errormessage}
          ErrorPopup={error}
          setErrorMethod={setErrorPopupState}
          isError={errorData.isErrorHeader}
        ></SerraError>
      ) : (
        ""
      )}
    </div>
  );
};

export default Header;
