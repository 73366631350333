/* eslint-disable default-case */
import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  Row,
  Col,
  Collapse,
  CardBody,
  ButtonGroup,
} from "reactstrap";
// import './test.css';
import classnames from "classnames";
import Header from "../HeaderComponent/Header";
import Resize from "./ResizeBenefitTable";
import Recommend from "./ResizeRecommendationTable";
import Health from "./SizingHealthAnalysisTable";
import CostHealthModal from "./CostHealthDetails";
import ColorComponent from "./SvgColorOverview";
import ResingBenefitTextComponent from "./ResingBenefitTextComponent";
import * as message from "../../Constants/MessageConstant";
import * as Constdata from "../../Constants/ApiConstants";
import SerraLoader from "../CommonComponent/Loader";
import SerraError from "../CommonComponent/Error";
import ReactGA from "react-ga4";
import Recommendstatus from "../AnalysisComponent/RecommendetionStatus";
import ValidationTable from "../AnalysisComponent/ValidationTable";
import ValidationDetailsTable from "../AnalysisComponent/ValidationDetailsTable";
import { getStringDate } from "../../Constants/CommonConstant";
import GaiRecommend from "./ResizeGaiRecommendationTable";
import GaiResize from "./ResizeGaiBenefitTable";

let tabType = ["", "Economical", "Balanced", "Enhanced"];
class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.toggleTab = this.toggleTab.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.showCostHealthModal = this.showCostHealthModal.bind(this);
    this.showValidationDetails = this.showValidationDetails.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.closeCostHealthModal = this.closeCostHealthModal.bind(this);
    this.closeValidationDetails = this.closeValidationDetails.bind(this);
    this.getRecommendedData = this.getRecommendedData.bind(this);
    this.state = {
      providerName:
        this.props.location.state !== undefined
          ? this.props.location.state.providerName
          : "",
      loggedInUser: this.props.location.state.loggedInUser,
      summaryData:
        this.props.location.state !== undefined
          ? this.props.location.state.analysisStatus
          : "",
      viewResultData:
        this.props.location.state !== undefined
          ? this.props.location.state.viewResultData
          : "",
      data:
        this.props.location.state !== undefined
          ? this.props.location.state.data
          : "",
      aggrIcondata:
        this.props.location.state !== undefined
          ? this.props.location.state.aggrIconData
          : [],
      balIcondata:
        this.props.location.state !== undefined
          ? this.props.location.state.balIconData
          : [],
      healthyIcondata:
        this.props.location.state !== undefined
          ? this.props.location.state.healthyIconData
          : [],
      gaiIcondata:
        this.props.location.state !== undefined
          ? this.props.location.state.gaiIconData
          : [],
      modalData: [],
      modal: false,
      isValidationGraphTable: false,
      activeTab:
        this.props.location.state !== undefined
          ? this.props.location.state.actTb
          : "1",
      collapse:
        this.props.location.state !== undefined
          ? [this.props.location.state.actCl]
          : [],
      aggBenefitData:
        this.props.location.state !== undefined
          ? this.props.location.state.aggBenefitData
          : [],
      balBenefitData:
        this.props.location.state !== undefined
          ? this.props.location.state.balBenefitData
          : [],
      healthyBenefitData:
        this.props.location.state !== undefined
          ? this.props.location.state.healthyBenefitData
          : [],
      analysisStatus:
        this.props.location.state === undefined
          ? ""
          : this.props.location.state.analysisStatus,
      errorPopup: false,
      errormessage: "",
      isErrorHeader: true,
      modalLoader: false,
      recommendationData:
        this.props.location.state !== undefined
          ? this.props.location.state.data
          : [],
      aggrIcondataStatus:
        this.props.location.state !== undefined
          ? this.props.location.state.aggrIconData
          : [],
      balIcondataStatus:
        this.props.location.state !== undefined
          ? this.props.location.state.balIconData
          : [],
      healthyIcondataStatus:
        this.props.location.state !== undefined
          ? this.props.location.state.healthyIconData
          : [],
      gaiIcondataStatus:
        this.props.location.state !== undefined
          ? this.props.location.state.gaiIconData
          : [],
      isRedirect: true,
      previousView:
        this.props.location.state === undefined
          ? ""
          : this.props.location.state.lastView,
      isAsp:
        this.props.location.state.isAsp !== undefined
          ? this.props.location.state.isAsp
          : false,
      entityType:
        this.props.location.state.entityType !== undefined
          ? this.props.location.state.entityType
          : "vm",
      listTb:
        this.props.location.state.listTb !== undefined
          ? this.props.location.state.listTb
          : "1",
      currentDateTime: this.props.location.state.currentDateTime,
    };
  }

  componentDidMount() {
    ReactGA.initialize(message.MeasurementId);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Analysis Details",
    });
    this.props.registerListners(true);
    let scrollId = "tab";
    if (this.state.collapse[0] !== undefined) {
      scrollId = scrollId + this.props.location.state.actCl;
    }
    var ele = document.getElementById(scrollId);
    if (ele !== null) {
      var offset = ele.offsetTop;
      window.scrollTo(0, offset);
    } else {
      window.scrollTo(0, 0);
    }
    let platform =
      this.state.data !== undefined &&
      this.state.data.metricsPlatform === "Host"
        ? " (Only Host Metrics Used)"
        : "";
    var pgAc = document.getElementById("pageRef");
    if (pgAc !== undefined && pgAc !== null) {
      // pgAc.text = (this.state.modal ? "Analysis Details of " : this.state.isValidationGraphTable ? "Simulation Details of " : "Optimization Details of ") + this.state.summaryData.vmname;
      // pgAc.text = (this.state.modal ? "Analysis Details of " + this.state.summaryData.vmname : this.state.isValidationGraphTable ? "Simulation Details of " + this.state.summaryData.vmname : "Rightsizing Analysis of " + this.state.summaryData.vmname +' for '+tabType[this.state.activeTab]+' Goal');
      var header =
        `<div id="dashHeight">
            <div style="display: flex; text-align: center; font-size: 22px; color:#610058;">` +
        (this.state.modal
          ? "Rightsizing Analysis Details of " +
            this.state.summaryData.vmname +
            platform +
            " for " +
            tabType[this.state.activeTab] +
            " Goal"
          : this.state.isValidationGraphTable
          ? "Simulation Details of " + this.state.summaryData.vmname + platform
          : "Rightsizing Analysis Details of " +
            this.state.summaryData.vmname +
            platform +
            " for " +
            tabType[this.state.activeTab] +
            " Goal") +
        `</div>
            <div style="text-align: center; font-size: 15px; color: #610058;">${getStringDate(
              this.state.currentDateTime
            )} </div>
        </div>`;
      const pageRef = document.getElementById("pageRef");
      pageRef.innerHTML = header;

      pgAc.disabled = true;
      const dashHeight = document.getElementById("dashHeight");
      if (dashHeight) {
        pageRef.style.height = `${dashHeight.offsetHeight}px`;
      }
    }
  }
  getRecommendedData = async (tab) => {
    this.setState({
      modalLoader: true,
      aggrIcondataStatus: [],
      balIcondataStatus: [],
      healthyIcondataStatus: [],
      gaiIcondataStatus: [],
    });
    await fetch(
      `${Constdata.GETRECOMMENDATION_URL}/${tab}/${this.state.data.basicVMID}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          await this.setState({
            modalLoader: false,
            recommendationData: responseJson.data,
            providerName: responseJson.orgInfo.providerName,
            loggedInUser: responseJson.orgInfo.loggedInUser,
          });
          await this.setState((prevState) => ({
            analysisStatus: {
              ...prevState.analysisStatus,
              status_change_datetime: responseJson.data.status_change_datetime,
            },
          }));
          var aggrIcondata = [];
          var aggrcpu = [];
          var aggrnetwork = [];
          var aggrdisks = [];
          var aggrmemory = [];
          var balIcondata = [];
          var balcpu = [];
          var balnetwork = [];
          var balmemory = [];
          var baldisks = [];
          var healthyIcondata = [];
          var healthycpu = [];
          var healthynetwork = [];
          var healthymemory = [];
          var healthydisks = [];
          var gaiIcondata = [];
          var gaicpu = [];
          var gainetwork = [];
          var gaimemory = [];
          var gaidisks = [];
          responseJson.data.aggressive.vm.map(async (key) => {
            aggrcpu.push({
              costColor: key.current_vcpu_cost,
              healthColor: key.current_vcpu_health,
              icon: "cpu",
              resize: key.vcpuResizeImage,
              status: key.recommendedVMStatus,
            });
            aggrmemory.push({
              costColor: key.current_memory_cost,
              healthColor: key.current_memory_health,
              icon: "memory",
              resize: key.memoryResizeImage,
              status: key.recommendedVMStatus,
            });
            aggrnetwork.push({
              costColor: key.current_network_cost,
              healthColor: key.current_network_health,
              icon: "network",
              resize: key.networkResizeImage,
              status: key.recommendedVMStatus,
            });
          });

          responseJson.data.aggressive.osdisk.map(async (key) => {
            aggrdisks.push({
              costColor: key.current_os_disk_cost,
              healthColor: key.current_os_disk_health,
              icon: "osdisk",
              resize: key.osDiskResizeImage,
              status: key.recommendedOSDiskStatus,
            });
          });
          if (this.state.entityType !== "db") {
            Object.keys(responseJson.data.aggressive.dataDisk).map(
              async (key) => {
                return responseJson.data.aggressive.dataDisk[key].map(
                  async (value) => {
                    aggrdisks.push({
                      costColor: value.current_data_disk_cost,
                      healthColor: value.current_data_disk_health,
                      icon: "datadisk",
                      resize: value.diskResizeImage,
                      status: value.recommendedDataDiskStatus,
                    });
                  }
                );
              }
            );
          }
          aggrIcondata.push({
            cpu: aggrcpu,
            memory: aggrmemory,
            network: aggrnetwork,
            disks: aggrdisks,
          });

          await this.setState(() => ({
            aggrIcondataStatus: aggrIcondata,
          }));

          responseJson.data.balanced.vm.map(async (key) => {
            balcpu.push({
              costColor: key.current_vcpu_cost,
              healthColor: key.current_vcpu_health,
              icon: "cpu",
              resize: key.vcpuResizeImage,
              status: key.recommendedVMStatus,
            });
            balmemory.push({
              costColor: key.current_memory_cost,
              healthColor: key.current_memory_health,
              icon: "memory",
              resize: key.memoryResizeImage,
              status: key.recommendedVMStatus,
            });
            balnetwork.push({
              costColor: key.current_network_cost,
              healthColor: key.current_network_health,
              icon: "network",
              resize: key.networkResizeImage,
              status: key.recommendedVMStatus,
            });
          });

          responseJson.data.balanced.osdisk.map(async (key) => {
            baldisks.push({
              costColor: key.current_os_disk_cost,
              healthColor: key.current_os_disk_health,
              icon: "osdisk",
              resize: key.osDiskResizeImage,
              status: key.recommendedOSDiskStatus,
            });
          });
          if (this.state.entityType !== "db") {
            Object.keys(responseJson.data.balanced.dataDisk).map(
              async (key) => {
                return responseJson.data.balanced.dataDisk[key].map(
                  async (value) => {
                    baldisks.push({
                      costColor: value.current_data_disk_cost,
                      healthColor: value.current_data_disk_health,
                      icon: "datadisk",
                      resize: value.diskResizeImage,
                      status: value.recommendedDataDiskStatus,
                    });
                  }
                );
              }
            );
          }
          balIcondata.push({
            cpu: balcpu,
            memory: balmemory,
            network: balnetwork,
            disks: baldisks,
          });

          await this.setState(() => ({
            balIcondataStatus: balIcondata,
          }));
          responseJson.data.conservative.vm.map(async (key) => {
            healthycpu.push({
              costColor: key.current_vcpu_cost,
              healthColor: key.current_vcpu_health,
              icon: "cpu",
              resize: key.vcpuResizeImage,
              status: key.recommendedVMStatus,
            });
            healthymemory.push({
              costColor: key.current_memory_cost,
              healthColor: key.current_memory_health,
              icon: "memory",
              resize: key.memoryResizeImage,
              status: key.recommendedVMStatus,
            });
            healthynetwork.push({
              costColor: key.current_network_cost,
              healthColor: key.current_network_health,
              icon: "network",
              resize: key.networkResizeImage,
              status: key.recommendedVMStatus,
            });
          });

          responseJson.data.conservative.osdisk.map(async (key) => {
            healthydisks.push({
              costColor: key.current_os_disk_cost,
              healthColor: key.current_os_disk_health,
              icon: "osdisk",
              resize: key.osDiskResizeImage,
              status: key.recommendedOSDiskStatus,
            });
          });
          if (this.state.entityType !== "db") {
            Object.keys(responseJson.data.conservative.dataDisk).map(
              async (key) => {
                return responseJson.data.conservative.dataDisk[key].map(
                  async (value) => {
                    healthydisks.push({
                      costColor: value.current_data_disk_cost,
                      healthColor: value.current_data_disk_health,
                      icon: "datadisk",
                      resize: value.diskResizeImage,
                      status: value.recommendedDataDiskStatus,
                    });
                  }
                );
              }
            );
          }
          healthyIcondata.push({
            cpu: healthycpu,
            memory: healthymemory,
            network: healthynetwork,
            disks: healthydisks,
          });

          await this.setState(() => ({
            healthyIcondataStatus: healthyIcondata,
          }));

          console.log(this.state.healthyIcondataStatus);

          responseJson.data.gaiValidationData.vm.map(async (key) => {
            gaicpu.push({
              costColor: key.current_vcpu_cost,
              healthColor: key.current_vcpu_health,
              icon: "cpu",
              resize: key.vcpuResizeImage,
              status: key.recommendedVMStatus,
            });
            gaimemory.push({
              costColor: key.current_memory_cost,
              healthColor: key.current_memory_health,
              icon: "memory",
              resize: key.memoryResizeImage,
              status: key.recommendedVMStatus,
            });
            gainetwork.push({
              costColor: key.current_network_cost,
              healthColor: key.current_network_health,
              icon: "network",
              resize: key.networkResizeImage,
              status: key.recommendedVMStatus,
            });
          });

          responseJson.data.gaiValidationData.osdisk.map(async (key) => {
            gaidisks.push({
              costColor: key.current_os_disk_cost,
              healthColor: key.current_os_disk_health,
              icon: "osdisk",
              resize: key.osDiskResizeImage,
              status: key.recommendedOSDiskStatus,
            });
          });
          if (this.state.entityType !== "db") {
            Object.keys(responseJson.data.gaiValidationData.dataDisk).map(
              async (key) => {
                return responseJson.data.gaiValidationData.dataDisk[key].map(
                  async (value) => {
                    gaidisks.push({
                      costColor: value.current_data_disk_cost,
                      healthColor: value.current_data_disk_health,
                      icon: "datadisk",
                      resize: value.diskResizeImage,
                      status: value.recommendedDataDiskStatus,
                    });
                  }
                );
              }
            );
          }
          gaiIcondata.push({
            cpu: gaicpu,
            memory: gaimemory,
            network: gainetwork,
            disks: gaidisks,
          });

          await this.setState(() => ({
            gaiIcondataStatus: gaiIcondata,
          }));
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        } else {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.ServerError,
            isErrorHeader: false,
            isRedirect: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  };

  handleClose = () => {
    var lastvw =
      this.state.isAsp || this.state.entityType === "db"
        ? "/AspSummary"
        : "/summary";
    this.props.history.push({
      pathname: lastvw,
      state: {
        data: this.state.summaryData,
        oldData: this.state.viewResultData,
        lastView: this.props.location.state.lastView,
        key: this.props.location.state.Listkey,
        isAsp: this.state.isAsp,
        listTb: this.state.listTb,
        entityType: this.state.entityType,
      },
    });
  };
  closeCostHealthModal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      modalLoader: false,
    }));
  };
  closeValidationDetails = () => {
    this.setState((prevState) => ({
      isValidationGraphTable: !prevState.isValidationGraphTable,
      modalLoader: false,
    }));
  };
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  toggleCollapse(tab) {
    if (!this.state.collapse.includes(tab)) {
      this.setState((previousState) => ({
        collapse: [...previousState.collapse, tab],
      }));
    } else {
      var selectedCollapse = this.state.collapse.slice();
      let idx1 = selectedCollapse.indexOf(tab);
      selectedCollapse.splice(idx1, 1);
      this.setState({ collapse: selectedCollapse });
    }
  }
  showCostHealthModal(type) {
    this.setState({ modalLoader: true });
    if (type === "eco") {
      this.setState({
        modalData: this.state.data.aggressive,
        type: "Aggressive",
      });
    } else if (type === "bal") {
      this.setState({
        modalData: this.state.data.balanced,
        type: "Balanced",
      });
    } else if (type === "health") {
      this.setState({
        modalData: this.state.data.conservative,
        type: "Conservative",
      });
    }
    this.setState((prevState) => ({
      modal: !prevState.modal,
      modalLoader: false,
    }));
  }
  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event });
    if (this.state.isRedirect) {
      this.props.processLogut();
      this.props.history.push("/");
    }
  };
  showValidationDetails() {
    this.setState((prevState) => ({
      isValidationGraphTable: !prevState.isValidationGraphTable,
      modalLoader: false,
    }));
  }

  componentWillUnmount() {
    setTimeout(() => {
      document
        .getElementById("optionDisplay")
        .setAttribute("style", "height: auto;");
      document.getElementsByClassName("container-verf")[0].style.marginTop =
        "50px";
    }, 500);
  }

  render() {
    let hostOnly =
      this.state.data !== undefined &&
      this.state.data.aggressive !== undefined &&
      this.state.data.aggressive.metricsPlatform === "Host";
    const OPEN = (
      <img
        src={require("../../images/OpenArrow_01.png")}
        alt=""
        style={{ maxWidth: "22px", maxHeight: "18px" }}
      />
    );
    const CLOSE = (
      <img
        src={require("../../images/CloseArrow_01.png")}
        alt=""
        style={{ maxWidth: "22px", maxHeight: "18px" }}
      />
    );
    var show = { display: "block", cursor: "pointer" };
    var hide = { display: "none" };
    let forValidation =
      this.state.data.validationData !== null &&
      this.state.data.validationData !== undefined;
    let bvmStatus = this.state.data.bvmState;
    if (
      forValidation === true &&
      (bvmStatus === "VALIDATED" ||
        bvmStatus === "OPTIMALLY SIZED" ||
        bvmStatus === "CUSTOM-SIZED" ||
        bvmStatus === "OPTIMIZING")
    ) {
      bvmStatus = "VALIDATED";
    }

    let platform =
      this.state.data !== undefined &&
      this.state.data.metricsPlatform === "Host"
        ? " (Only Host Metrics Used)"
        : "";
    var pgAc = document.getElementById("pageRef");
    if (pgAc !== undefined && pgAc !== null) {
      var header =
        `<div id="dashHeight">
            <div style="display: flex; text-align: center; font-size: 22px; color:#610058;">` +
        (this.state.modal
          ? "Rightsizing Analysis Details of " +
            this.state.summaryData.vmname +
            platform +
            " for " +
            tabType[this.state.activeTab] +
            " Goal"
          : this.state.isValidationGraphTable
          ? "Simulation Details of " + this.state.summaryData.vmname + platform
          : "Rightsizing Analysis Details of " +
            this.state.summaryData.vmname +
            platform +
            " for " +
            tabType[this.state.activeTab] +
            " Goal") +
        `</div>
            <div style="text-align: center; font-size: 15px; color: #610058;">${getStringDate(
              this.state.currentDateTime
            )} </div>
        </div>`;
      document.getElementById("pageRef").innerHTML = header;
      pgAc.disabled = true;
    }

    let br = <br />;
    return (
      <div>
        <Header
          processLogout={this.props.processLogut}
          registerListners={this.props.registerListners}
          providerName={this.state.providerName}
          loggedInUser={this.state.loggedInUser}
        />

        <div
          className="container-verf container-verf-padding"
          style={{ marginTop: "50px" }}
          role="main"
        >
          <div className="container1 boxShadow">
            {this.state.data.isResourceChanged !== undefined &&
            this.state.data.isResourceChanged === true ? (
              <Row>
                <div
                  className="InfoDiv"
                  style={{
                    backgroundColor: "#ffffff",
                    marginBottom: "5px",
                    borderRadius: "7px",
                  }}
                >
                  <div style={{ color: "#e32227", width: "80%" }}>
                    {" "}
                    {bvmStatus === "VALIDATED" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            message.VMResourceChngedFlagInfoTextSummaryValidated +
                            "<br/>" +
                            this.state.data.resourceChangeDetails,
                        }}
                      />
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.isAsp
                            ? message.ASPResourceChngedFlagInfoTextSummaryAnalyzed
                            : this.state.entityType === "db"
                            ? message.DBResourceChngedFlagInfoTextSummaryAnalyzed
                            : message.VMResourceChngedFlagInfoTextSummaryAnalyzed +
                              "<br/>" +
                              this.state.data.resourceChangeDetails,
                        }}
                      />
                    )}
                  </div>
                </div>
              </Row>
            ) : null}

            {this.state.data.isSameMetricPlatform === false &&
            this.state.entityType === "vm" ? (
              <Row>
                <div
                  className="InfoDiv"
                  style={{
                    backgroundColor: "#ffffff",
                    marginBottom: "5px",
                    borderRadius: "7px",
                  }}
                >
                  <div style={{ color: "#e32227", width: "80%" }}>
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.data.metricPlatformChangeDetails,
                      }}
                    />
                  </div>
                </div>
              </Row>
            ) : null}

            {this.state.data.priceChangeMsg !== null ? (
              <Row>
                <div
                  className="InfoDiv"
                  style={{
                    backgroundColor: "#ffffff",
                    marginBottom: "5px",
                    borderRadius: "7px",
                  }}
                >
                  <div style={{ color: "#e32227", width: "80%" }}>
                    <div>{this.state.data.priceChangeMsg}</div>
                  </div>
                </div>
              </Row>
            ) : null}

            {/*<Row>
                                {(this.state.data.metricDataAvalability !== undefined && this.state.data.metricDataAvalability !== null && this.state.data.metricDataAvalability.trim() !== "") ?
                                    <div className="InfoDiv" style={{backgroundColor: "#ffffff", marginBottom: "5px", borderRadius: "7px"}}>
                                        <div style={{color: "#393939", width: "80%"}}><div>Note : {this.state.data.metricDataAvalability}</div></div>
                                    </div> : null }
                            </Row>*/}
            <Row
              className="pageBgHeader"
              style={{
                height: "unset",
                position: "relative",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#E9E9E9",
                borderRadius: "8px 8px 0px 0px",
                flexWrap: "nowrap",
                padding: "2px 0px",
              }}
            >
              <div id="spaceFiller" style={{ width: "8%" }}></div>
              <div
                style={{
                  width: "84%",
                  textAlign: "center",
                  color: "#ffffff",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                {this.state.data.vminfo}
                <div style={{ fontSize: "18px" }}>
                  {this.state.data && this.state.data.analysisDatetimestamp
                    ? `Analyzed: ${getStringDate(
                        this.state.data.analysisDatetimestamp
                      )}`
                    : null}

                  {this.state.data &&
                  (this.state.data.bvmState === "ANALYZED" ||
                    this.state.data.bvmState === "VERIFIED" ||
                    this.state.data.bvmState === "OPTIMIZED")
                    ? this.state.data.metricDataDurationUsed !== null
                      ? `, ${this.state.data.metricDataDurationUsed}`
                      : null
                    : null}
                </div>
              </div>
              <div
                style={{
                  width: "8%",
                  display: "flex",
                  justifyContent: "flex-end",
                  right: "10px",
                  position: "absolute",
                }}
              >
                <ButtonGroup>
                  {this.state.isValidationGraphTable ? (
                    <div outline onClick={this.closeValidationDetails}>
                      <img
                        src={require("../../images/Close_Icon1.svg")}
                        title="Close"
                      />
                    </div>
                  ) : this.state.modal ? (
                    <div outline onClick={this.closeCostHealthModal}>
                      <img
                        src={require("../../images/Close_Icon1.svg")}
                        title="Close"
                      />
                    </div>
                  ) : (
                    <div
                      outline
                      onClick={this.handleClose}
                      style={this.state.modal ? hide : show}
                    >
                      <img
                        src={require("../../images/Close_Icon1.svg")}
                        title="Close"
                      />
                    </div>
                  )}
                </ButtonGroup>
              </div>
            </Row>

            <div
              className="frm-container pageBgWhite"
              style={{ borderRadius: "0px 0px 10px 10px" }}
            >
              <div>
                {this.state.isValidationGraphTable === true ||
                this.state.modal === true ? null : (
                  <div
                    className="rowContainer"
                    style={{ margin: "0px 0px 0px 0px" }}
                  >
                    <Nav tabs className="navClass">
                      <NavItem style={{ width: "33.33%" }}>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggleTab("1");
                          }}
                        >
                          <b>
                            <h4>
                              Economical
                              <label
                                style={{ fontWeight: "300", fontSize: "16px" }}
                              >
                                (&eta;90)
                              </label>
                            </h4>
                          </b>
                          <p className="objectiveTagLine">
                            <i>Cost Reducing</i>{" "}
                          </p>
                        </NavLink>
                      </NavItem>
                      <NavItem style={{ width: "33.33%" }}>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggleTab("2");
                          }}
                        >
                          <b>
                            <h4>
                              Balanced
                              <label
                                style={{ fontWeight: "300", fontSize: "16px" }}
                              >
                                (&eta;95)
                              </label>
                            </h4>
                          </b>
                          <p className="objectiveTagLine">
                            {" "}
                            <i>Cost Reducing & Performance Improving</i>
                          </p>
                        </NavLink>
                      </NavItem>
                      <NavItem style={{ width: "33.33%" }}>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === "3",
                          })}
                          onClick={() => {
                            this.toggleTab("3");
                          }}
                        >
                          <b>
                            {" "}
                            <h4>
                              Enhanced
                              <label
                                style={{ fontWeight: "300", fontSize: "16px" }}
                              >
                                (&eta;100)
                              </label>
                            </h4>
                          </b>
                          <p className="objectiveTagLine">
                            <i>Performance Improving</i>
                          </p>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <Row>
                      <Col id="healthcolor">
                        <lable>
                          <b>Legend</b>
                        </lable>
                        <div className="legendLable">
                          <div className="legendclass Good">Good</div>
                          <div className="legendclass Fair">Fair</div>
                          <div className="legendclass Poor">Poor</div>
                        </div>
                      </Col>
                    </Row>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        <div id="tab1">
                          <div
                            color="primary"
                            onClick={() => {
                              this.toggleCollapse("1");
                            }}
                            className="collapsediv"
                          >
                            <div id="collapse_head">
                              <div className="arrow">
                                {!this.state.collapse.includes("1")
                                  ? CLOSE
                                  : OPEN}
                              </div>
                              <div id="overViewCollapseTitle">Analysis</div>
                            </div>
                            {!this.state.collapse.includes("1") ? (
                              <div className="maindiv">
                                <ColorComponent
                                  data={this.state.aggrIcondata[0].cpu}
                                  isResize={false}
                                  showInfo={false}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  showCostPerfMarker={true}
                                  fromOverview={true}
                                ></ColorComponent>
                                {this.state.entityType === "db" ? (
                                  <ColorComponent
                                    data={this.state.aggrIcondata[0].disks}
                                    isResize={false}
                                    showInfo={false}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    fromOverview={true}
                                  ></ColorComponent>
                                ) : null}
                                <ColorComponent
                                  data={this.state.aggrIcondata[0].memory}
                                  isResize={false}
                                  showInfo={false}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  hostOnly={hostOnly}
                                  fromOverview={true}
                                ></ColorComponent>
                                <ColorComponent
                                  data={this.state.aggrIcondata[0].network}
                                  isResize={false}
                                  showInfo={false}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  fromOverview={true}
                                ></ColorComponent>
                                {this.state.entityType !== "db" ? (
                                  <ColorComponent
                                    data={this.state.aggrIcondata[0].disks}
                                    isResize={false}
                                    showInfo={false}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    fromOverview={true}
                                  ></ColorComponent>
                                ) : null}
                              </div>
                            ) : null}{" "}
                          </div>
                          <Collapse
                            isOpen={this.state.collapse.includes("1")}
                            className="collapseTransition"
                          >
                            <Card style={{ border: "none" }}>
                              <CardBody style={{ border: "none" }}>
                                <Row>
                                  <Col sm="9">
                                    <Health
                                      data={this.state.data.aggressive}
                                      iconData={this.state.aggrIcondata}
                                      isResize={false}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                    ></Health>
                                  </Col>
                                  <Col
                                    sm="3"
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      marginTop: "220px",
                                      marginBottom: "auto",
                                    }}
                                  >
                                    <Button
                                      className="btn-common"
                                      onClick={() => {
                                        this.showCostHealthModal("eco");
                                      }}
                                    >
                                      Show Details
                                    </Button>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Collapse>
                        </div>
                        <div id="tab2">
                          <div
                            onClick={() => {
                              this.toggleCollapse("2");
                            }}
                            className="collapsediv"
                          >
                            <div id="collapse_head">
                              <div className="arrow">
                                {!this.state.collapse.includes("2")
                                  ? CLOSE
                                  : OPEN}
                              </div>
                              <div id="overViewCollapseTitle">
                                Recommendation
                              </div>
                            </div>
                            {!this.state.collapse.includes("2") ? (
                              <div className="maindiv">
                                <ColorComponent
                                  data={this.state.aggrIcondata[0].cpu}
                                  isResize={true}
                                  isLine={true}
                                  showInfo={false}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  showCostPerfMarker={true}
                                  fromOverview={true}
                                ></ColorComponent>
                                {this.state.entityType === "db" ? (
                                  <ColorComponent
                                    data={this.state.aggrIcondata[0].disks}
                                    isResize={true}
                                    isLine={true}
                                    showInfo={false}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    fromOverview={true}
                                  ></ColorComponent>
                                ) : null}
                                <ColorComponent
                                  data={this.state.aggrIcondata[0].memory}
                                  isResize={true}
                                  isLine={true}
                                  showInfo={false}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  hostOnly={hostOnly}
                                  fromOverview={true}
                                ></ColorComponent>
                                <ColorComponent
                                  data={this.state.aggrIcondata[0].network}
                                  isResize={true}
                                  isLine={
                                    this.state.entityType === "db"
                                      ? false
                                      : true
                                  }
                                  showInfo={false}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  fromOverview={true}
                                ></ColorComponent>
                                {this.state.entityType !== "db" ? (
                                  <ColorComponent
                                    data={this.state.aggrIcondata[0].disks}
                                    isResize={true}
                                    isLine={false}
                                    showInfo={false}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    fromOverview={true}
                                  ></ColorComponent>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                          <Collapse
                            isOpen={this.state.collapse.includes("2")}
                            className="collapseTransition"
                          >
                            <Card style={{ border: "none" }}>
                              <CardBody style={{ border: "none" }}>
                                <Recommend
                                  data={this.state.data.aggressive}
                                  iconData={this.state.aggrIcondata}
                                  isResize={true}
                                  isLine={true}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  userCurrency={this.state.data?.userCurrency}
                                  providerName={this.state.providerName}
                                ></Recommend>
                              </CardBody>
                            </Card>
                          </Collapse>
                        </div>
                        <div id="tab3">
                          <div
                            color="primary"
                            onClick={() => {
                              this.toggleCollapse("3");
                            }}
                            className="collapsediv resize-collapsediv"
                          >
                            <div
                              id="collapse_head"
                              style={{ minHeight: "102px", maxWidth: "28%" }}
                            >
                              <div className="arrow">
                                {!this.state.collapse.includes("3")
                                  ? CLOSE
                                  : OPEN}
                              </div>
                              <div id="overViewCollapseTitle">
                                Expected Benefits & Drawbacks of Recommendation
                              </div>{" "}
                            </div>{" "}
                            {!this.state.collapse.includes("3") ? (
                              <div className="overviewTextCompParent">
                                {this.state.data.aggressive !== undefined &&
                                this.state.data.aggressive.summary
                                  .costSummaryText !== undefined &&
                                this.state.data.aggressive.summary
                                  .costSummaryText !== null ? (
                                  <div className="maindiv-text-overview maindiv-text-overview-grad">
                                    <ResingBenefitTextComponent
                                      title={
                                        this.state.data.aggressive.summary
                                          .costSummaryText
                                      }
                                      data={
                                        this.state.data.aggressive.summary
                                          .costSummaryDescription
                                      }
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                    />
                                  </div>
                                ) : null}
                                {this.state.data.aggressive !== undefined &&
                                this.state.data.aggressive.summary
                                  .perfSummaryText !== undefined &&
                                this.state.data.aggressive.summary
                                  .perfSummaryText !== null ? (
                                  <div className="maindiv-text-overview maindiv-text-overview-grad">
                                    <ResingBenefitTextComponent
                                      data={
                                        this.state.data.aggressive.summary
                                          .perfSummaryDescription
                                      }
                                      title={
                                        this.state.data.aggressive.summary
                                          .perfSummaryText
                                      }
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                          <Collapse
                            isOpen={this.state.collapse.includes("3")}
                            className="collapseTransition"
                          >
                            <Card style={{ border: "none" }}>
                              <CardBody style={{ border: "none" }}>
                                <Resize
                                  data={this.state.data.aggressive}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  userCurrency={this.state.data?.userCurrency}
                                  providerName={this.state.providerName}
                                ></Resize>
                              </CardBody>
                            </Card>
                          </Collapse>
                        </div>

                        {this.state.data &&
                        this.state.data.gaiValidationData &&
                        this.state.data.gaiValidationData.validationPerfType ===
                          "Aggressive" ? (
                          <>
                            <div id="tab19">
                              <div
                                onClick={() => {
                                  this.toggleCollapse("19");
                                }}
                                className="collapsediv"
                              >
                                <div id="collapse_head">
                                  <div className="arrow">
                                    {!this.state.collapse.includes("19")
                                      ? CLOSE
                                      : OPEN}
                                  </div>
                                  <div id="overViewCollapseTitle">
                                    GAI Recommendation
                                  </div>
                                </div>
                                {!this.state.collapse.includes("19") ? (
                                  <div className="maindiv">
                                    <ColorComponent
                                      data={this.state.aggrIcondata[0].cpu}
                                      isResize={true}
                                      isLine={true}
                                      showInfo={false}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      showCostPerfMarker={true}
                                      fromOverview={true}
                                    ></ColorComponent>
                                    {this.state.entityType === "db" ? (
                                      <ColorComponent
                                        data={this.state.aggrIcondata[0].disks}
                                        isResize={true}
                                        isLine={true}
                                        showInfo={false}
                                        isAsp={this.state.isAsp}
                                        entityType={this.state.entityType}
                                        fromOverview={true}
                                      ></ColorComponent>
                                    ) : null}
                                    <ColorComponent
                                      data={this.state.aggrIcondata[0].memory}
                                      isResize={true}
                                      isLine={true}
                                      showInfo={false}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      hostOnly={hostOnly}
                                      fromOverview={true}
                                    ></ColorComponent>
                                    <ColorComponent
                                      data={this.state.aggrIcondata[0].network}
                                      isResize={true}
                                      isLine={
                                        this.state.entityType === "db"
                                          ? false
                                          : true
                                      }
                                      showInfo={false}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      fromOverview={true}
                                    ></ColorComponent>
                                    {this.state.entityType !== "db" ? (
                                      <ColorComponent
                                        data={this.state.aggrIcondata[0].disks}
                                        isResize={true}
                                        isLine={false}
                                        showInfo={false}
                                        isAsp={this.state.isAsp}
                                        entityType={this.state.entityType}
                                        fromOverview={true}
                                      ></ColorComponent>
                                    ) : null}
                                  </div>
                                ) : null}
                              </div>
                              <Collapse
                                isOpen={this.state.collapse.includes("19")}
                                className="collapseTransition"
                              >
                                <Card style={{ border: "none" }}>
                                  <CardBody style={{ border: "none" }}>
                                    <GaiRecommend
                                      data={this.state.data.gaiValidationData}
                                      iconData={this.state.aggrIcondata}
                                      isResize={true}
                                      isLine={true}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      userCurrency={
                                        this.state.data?.userCurrency
                                      }
                                      providerName={this.state.providerName}
                                    ></GaiRecommend>
                                  </CardBody>
                                </Card>
                              </Collapse>
                            </div>

                            <div id="tab20">
                              <div
                                color="primary"
                                onClick={() => {
                                  this.toggleCollapse("20");
                                }}
                                className="collapsediv resize-collapsediv"
                              >
                                <div
                                  id="collapse_head"
                                  style={{
                                    minHeight: "102px",
                                    maxWidth: "28%",
                                  }}
                                >
                                  <div className="arrow">
                                    {!this.state.collapse.includes("20")
                                      ? CLOSE
                                      : OPEN}
                                  </div>
                                  <div id="overViewCollapseTitle">
                                    Expected Benefits & Drawbacks of GAI
                                    Recommendation
                                  </div>{" "}
                                </div>{" "}
                                {!this.state.collapse.includes("20") ? (
                                  <div className="overviewTextCompParent">
                                    {this.state.data.gaiValidationData !==
                                      undefined &&
                                    this.state.data.gaiValidationData.summary
                                      .costSummaryText !== undefined &&
                                    this.state.data.gaiValidationData.summary
                                      .costSummaryText !== null ? (
                                      <div className="maindiv-text-overview maindiv-text-overview-grad">
                                        <ResingBenefitTextComponent
                                          title={
                                            this.state.data.gaiValidationData
                                              .summary.costSummaryText
                                          }
                                          data={
                                            this.state.data.gaiValidationData
                                              .summary.costSummaryDescription
                                          }
                                          isAsp={this.state.isAsp}
                                          entityType={this.state.entityType}
                                        />
                                      </div>
                                    ) : null}
                                    {this.state.data.gaiValidationData !==
                                      undefined &&
                                    this.state.data.gaiValidationData.summary
                                      .perfSummaryText !== undefined &&
                                    this.state.data.gaiValidationData.summary
                                      .perfSummaryText !== null ? (
                                      <div className="maindiv-text-overview maindiv-text-overview-grad">
                                        <ResingBenefitTextComponent
                                          data={
                                            this.state.data.gaiValidationData
                                              .summary.perfSummaryDescription
                                          }
                                          title={
                                            this.state.data.gaiValidationData
                                              .summary.perfSummaryText
                                          }
                                          isAsp={this.state.isAsp}
                                          entityType={this.state.entityType}
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                ) : null}
                              </div>
                              <Collapse
                                isOpen={this.state.collapse.includes("20")}
                                className="collapseTransition"
                              >
                                <Card style={{ border: "none" }}>
                                  <CardBody style={{ border: "none" }}>
                                    <GaiResize
                                      data={this.state.data.gaiValidationData}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      userCurrency={
                                        this.state.data?.userCurrency
                                      }
                                      providerName={this.state.providerName}
                                    ></GaiResize>
                                  </CardBody>
                                </Card>
                              </Collapse>
                            </div>
                          </>
                        ) : null}

                        {this.state.isValidationGraphTable === false &&
                        this.state.data.validationData !== null &&
                        this.state.data.validationData !== undefined &&
                        this.state.data.validationData.perfType ===
                          "Aggressive" ? (
                          <div id="tab4">
                            <div
                              onClick={() => {
                                this.toggleCollapse("4");
                              }}
                              className="collapsediv"
                            >
                              <div id="collapse_head">
                                <div className="arrow">
                                  {!this.state.collapse.includes("4")
                                    ? CLOSE
                                    : OPEN}
                                </div>
                                <div>
                                  <div id="overViewCollapseTitle">
                                    Validation :
                                  </div>
                                  <div>
                                    <b>Economical</b>
                                  </div>
                                  <div>{bvmStatus}</div>
                                </div>
                              </div>
                              {!this.state.collapse.includes("4") ? (
                                this.state.aggrIcondata.length > 0 ? (
                                  <div className="maindiv">
                                    <ColorComponent
                                      data={this.state.aggrIcondata[0].cpu}
                                      isResize={true}
                                      isLine={true}
                                      showInfo={false}
                                      isIcon={true}
                                      valStatus={bvmStatus}
                                      forValidation={true}
                                      valObjective="Economical"
                                      valData={this.state.data.validationData}
                                      showCostPerfMarker={true}
                                      fromOverview={true}
                                    ></ColorComponent>
                                    {this.state.entityType === "db" ? (
                                      <ColorComponent
                                        data={this.state.aggrIcondata[0].disks}
                                        isResize={true}
                                        isLine={true}
                                        showInfo={false}
                                        isIcon={true}
                                        valStatus={bvmStatus}
                                        forValidation={true}
                                        valObjective="Economical"
                                        valData={this.state.data.validationData}
                                        fromOverview={true}
                                      ></ColorComponent>
                                    ) : null}
                                    <ColorComponent
                                      data={this.state.aggrIcondata[0].memory}
                                      isResize={true}
                                      isLine={true}
                                      showInfo={false}
                                      isIcon={true}
                                      valStatus={bvmStatus}
                                      forValidation={true}
                                      valObjective="Economical"
                                      valData={this.state.data.validationData}
                                      hostOnly={hostOnly}
                                      fromOverview={true}
                                    ></ColorComponent>
                                    <ColorComponent
                                      data={this.state.aggrIcondata[0].network}
                                      isResize={true}
                                      isLine={
                                        this.state.entityType === "db"
                                          ? false
                                          : true
                                      }
                                      showInfo={false}
                                      isIcon={true}
                                      valStatus={bvmStatus}
                                      forValidation={true}
                                      valObjective="Economical"
                                      valData={this.state.data.validationData}
                                      fromOverview={true}
                                    ></ColorComponent>
                                    {this.state.entityType !== "db" ? (
                                      <ColorComponent
                                        data={this.state.aggrIcondata[0].disks}
                                        isResize={true}
                                        isLine={false}
                                        showInfo={false}
                                        isIcon={true}
                                        valStatus={bvmStatus}
                                        forValidation={true}
                                        valObjective="Economical"
                                        valData={this.state.data.validationData}
                                        fromOverview={true}
                                      ></ColorComponent>
                                    ) : null}
                                  </div>
                                ) : null
                              ) : null}
                            </div>
                            <Collapse
                              isOpen={this.state.collapse.includes("4")}
                              className="collapseTransition"
                            >
                              <Card style={{ border: "none" }}>
                                <CardBody style={{ border: "none" }}>
                                  <div>
                                    <ValidationTable
                                      data={
                                        this.state.recommendationData.aggressive
                                      }
                                      valTableData={
                                        this.state.data.validationData
                                      }
                                      iconData={this.state.aggrIcondata}
                                      isResize={true}
                                      isLine={true}
                                      isIcon={true}
                                      valStatus={bvmStatus}
                                      valObjective="Economical"
                                      showValidationDetails={
                                        this.showValidationDetails
                                      }
                                    ></ValidationTable>
                                  </div>
                                </CardBody>
                              </Card>
                            </Collapse>
                          </div>
                        ) : null}
                        {this.state.isValidationGraphTable === false &&
                        this.state.data.validationData !== null &&
                        this.state.data.validationData !== undefined &&
                        this.state.data.validationData.perfType ===
                          "Aggressive" &&
                        bvmStatus === "VALIDATED" ? (
                          <div id="5">
                            <div
                              onClick={() => {
                                this.toggleCollapse("5");
                              }}
                              className="collapsediv resize-collapsediv"
                            >
                              <div
                                id="collapse_head"
                                style={{ maxWidth: "29%" }}
                              >
                                <div className="arrow">
                                  {!this.state.collapse.includes("5")
                                    ? CLOSE
                                    : OPEN}
                                </div>
                                <div>
                                  <div
                                    id="overViewCollapseTitle"
                                    style={{ fontSize: "20px" }}
                                  >
                                    Validated Benefits & Drawbacks of
                                    Recommendation :{" "}
                                  </div>
                                  <div>
                                    <b>Economical</b>
                                  </div>
                                </div>
                              </div>
                              {!this.state.collapse.includes("5") ? (
                                <div className="overviewTextCompParent">
                                  {this.state.data.aggressive !== undefined &&
                                  this.state.data.aggressive.validation_summary
                                    .costSummaryText !== undefined &&
                                  this.state.data.aggressive.validation_summary
                                    .costSummaryText !== null ? (
                                    <div className="maindiv-text-overview maindiv-text-overview-grad">
                                      <ResingBenefitTextComponent
                                        title={
                                          this.state.data.aggressive
                                            .validation_summary.costSummaryText
                                        }
                                        data={
                                          this.state.data.aggressive
                                            .validation_summary
                                            .costSummaryDescription
                                        }
                                        affectString={
                                          this.state.data.aggressive
                                            .validation_summary.perfAffected
                                        }
                                        valResult={
                                          this.state.data.aggressive
                                            .validation_summary
                                            .validation_cost_result
                                        }
                                        renderIcon={
                                          this.state.data.validationPerfType !==
                                            null &&
                                          this.state.data.validationPerfType ===
                                            "Aggressive" &&
                                          this.state.data.bvmState !==
                                            "VALIDATING"
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                  ) : null}
                                  {this.state.data.aggressive !== undefined &&
                                  this.state.data.aggressive.validation_summary
                                    .perfSummaryText !== undefined &&
                                  this.state.data.aggressive.validation_summary
                                    .perfSummaryText !== null ? (
                                    <div className="maindiv-text-overview maindiv-text-overview-grad">
                                      <ResingBenefitTextComponent
                                        data={
                                          this.state.data.aggressive
                                            .validation_summary
                                            .perfSummaryDescription
                                        }
                                        title={
                                          this.state.data.aggressive
                                            .validation_summary.perfSummaryText
                                        }
                                        affectString={
                                          this.state.data.aggressive.summary
                                            .increasedCost
                                        }
                                        valResult={
                                          this.state.data.aggressive
                                            .validation_summary
                                            .validation_perf_result
                                        }
                                        renderIcon={
                                          this.state.data.validationPerfType !==
                                            null &&
                                          this.state.data.validationPerfType ===
                                            "Aggressive" &&
                                          this.state.data.bvmState !==
                                            "VALIDATING"
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                            <Collapse
                              isOpen={this.state.collapse.includes("5")}
                              className="collapseTransition"
                            >
                              <Card style={{ border: "none" }}>
                                <CardBody style={{ border: "none" }}>
                                  <div>
                                    <ValidationDetailsTable
                                      data={this.state.data.validationData}
                                      isValidationGraphTable={false}
                                      valSummary={
                                        this.state.data.aggressive
                                          .validation_summary
                                      }
                                    />
                                  </div>
                                </CardBody>
                              </Card>
                            </Collapse>
                          </div>
                        ) : null}
                        {this.state.recommendationData.aggressive !==
                          undefined &&
                        this.state.recommendationData.aggressive
                          .isRecommendationSelected === true ? (
                          <div id="tab6">
                            <div
                              onClick={() => {
                                this.toggleCollapse("6");
                              }}
                              className="collapsediv"
                            >
                              <div id="collapse_head">
                                <div className="arrow">
                                  {!this.state.collapse.includes("6")
                                    ? CLOSE
                                    : OPEN}
                                </div>
                                <div id="overViewCollapseTitle">
                                  Resizing Status :{" "}
                                  <div
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {this.state.recommendationData.bvmState}{" "}
                                    {this.state.recommendationData.bvmState ===
                                    "OPTIMIZING" ? (
                                      <i
                                        style={{ margin: "auto 14px" }}
                                        className="fa fa-info-circle"
                                        aria-hidden="true"
                                        data-toggle="tooltip"
                                        data-placement="left"
                                        title="When manual resizing has been completed, please mark it as complete using Manage VMs page"
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              {!this.state.collapse.includes("6") &&
                              this.state.aggrIcondataStatus.length > 0 ? (
                                <div className="maindiv">
                                  <ColorComponent
                                    data={this.state.aggrIcondataStatus[0].cpu}
                                    isResize={true}
                                    isLine={true}
                                    showInfo={false}
                                    isIcon={true}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    showCostPerfMarker={true}
                                    fromOverview={true}
                                  ></ColorComponent>
                                  {this.state.entityType === "db" ? (
                                    <ColorComponent
                                      data={
                                        this.state.aggrIcondataStatus[0].disks
                                      }
                                      isResize={true}
                                      isLine={true}
                                      showInfo={false}
                                      isIcon={true}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      fromOverview={true}
                                    ></ColorComponent>
                                  ) : null}
                                  <ColorComponent
                                    data={
                                      this.state.aggrIcondataStatus[0].memory
                                    }
                                    isResize={true}
                                    isLine={true}
                                    showInfo={false}
                                    isIcon={true}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    hostOnly={hostOnly}
                                    fromOverview={true}
                                  ></ColorComponent>
                                  <ColorComponent
                                    data={
                                      this.state.aggrIcondataStatus[0].network
                                    }
                                    isResize={true}
                                    isLine={
                                      this.state.entityType === "db"
                                        ? false
                                        : true
                                    }
                                    showInfo={false}
                                    isIcon={true}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    fromOverview={true}
                                  ></ColorComponent>
                                  {this.state.entityType !== "db" ? (
                                    <ColorComponent
                                      data={
                                        this.state.aggrIcondataStatus[0].disks
                                      }
                                      isResize={true}
                                      isLine={false}
                                      showInfo={false}
                                      isIcon={true}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      fromOverview={true}
                                    ></ColorComponent>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                            {this.state.aggrIcondataStatus.length > 0 ? (
                              <Collapse
                                isOpen={this.state.collapse.includes("6")}
                                className="collapseTransition"
                              >
                                <Card style={{ border: "none" }}>
                                  <CardBody style={{ border: "none" }}>
                                    <Recommendstatus
                                      data={
                                        this.state.recommendationData.aggressive
                                      }
                                      iconData={this.state.aggrIcondataStatus}
                                      isResize={true}
                                      isLine={true}
                                      isIcon={true}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      providerName={this.state.providerName}
                                    ></Recommendstatus>
                                  </CardBody>
                                </Card>
                              </Collapse>
                            ) : null}
                          </div>
                        ) : null}

                        {this.state.data.aggressive !== undefined &&
                        this.state.data.aggressive.gaiInsightSummary !==
                          null ? (
                          <div id="tab25">
                            <div
                              color="primary"
                              onClick={() => {
                                this.toggleCollapse("25");
                              }}
                              className="collapsediv resize-collapsediv"
                            >
                              <div
                                id="collapse_head"
                                style={{ minHeight: "102px", maxWidth: "28%" }}
                              >
                                <div className="arrow">
                                  {!this.state.collapse.includes("25")
                                    ? CLOSE
                                    : OPEN}
                                </div>
                                <div id="overViewCollapseTitle">
                                  GAI Insight
                                </div>
                              </div>
                              {!this.state.collapse.includes("25") ? (
                                this.state.data.aggressive !== undefined &&
                                this.state.data.aggressive.gaiInsightSummary !==
                                  null ? (
                                  <div className="overviewTextCompParent w-50">
                                    <div className="maindiv-text-overview maindiv-text-overview-grad p-3 px-4 text-center">
                                      {
                                        this.state.data.aggressive
                                          ?.gaiInsightSummary
                                      }
                                    </div>
                                  </div>
                                ) : null
                              ) : null}
                            </div>

                            <Collapse
                              isOpen={this.state.collapse.includes("25")}
                              className="collapseTransition"
                            >
                              <Card style={{ border: "none" }}>
                                <CardBody style={{ border: "none" }}>
                                  {this.state.data.aggressive !== undefined &&
                                  this.state.data.aggressive
                                    .gaiInsightDescription !== null ? (
                                    <>
                                      <div className="overviewTextCompParent w-75 mx-auto">
                                        <div className="maindiv-text-overview maindiv-text-overview-grad p-3 px-4">
                                          {
                                            this.state.data.aggressive
                                              ?.gaiInsightDescription
                                          }
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                </CardBody>
                              </Card>
                            </Collapse>
                          </div>
                        ) : null}
                      </TabPane>
                      <TabPane tabId="2">
                        <div id="tab7">
                          <div
                            color="primary"
                            onClick={() => {
                              this.toggleCollapse("7");
                            }}
                            className="collapsediv"
                          >
                            <div id="collapse_head">
                              <div className="arrow">
                                {!this.state.collapse.includes("7")
                                  ? CLOSE
                                  : OPEN}{" "}
                              </div>
                              <div id="overViewCollapseTitle">Analysis</div>
                            </div>{" "}
                            {!this.state.collapse.includes("7") ? (
                              <div className="maindiv">
                                <ColorComponent
                                  data={this.state.balIcondata[0].cpu}
                                  isResize={false}
                                  showInfo={false}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  showCostPerfMarker={true}
                                  fromOverview={true}
                                ></ColorComponent>
                                {this.state.entityType === "db" ? (
                                  <ColorComponent
                                    data={this.state.balIcondata[0].disks}
                                    isResize={false}
                                    showInfo={false}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    fromOverview={true}
                                  ></ColorComponent>
                                ) : null}
                                <ColorComponent
                                  data={this.state.balIcondata[0].memory}
                                  isResize={false}
                                  showInfo={false}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  hostOnly={hostOnly}
                                  fromOverview={true}
                                ></ColorComponent>
                                <ColorComponent
                                  data={this.state.balIcondata[0].network}
                                  isResize={false}
                                  showInfo={false}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  fromOverview={true}
                                ></ColorComponent>
                                {this.state.entityType !== "db" ? (
                                  <ColorComponent
                                    data={this.state.balIcondata[0].disks}
                                    isResize={false}
                                    showInfo={false}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    fromOverview={true}
                                  ></ColorComponent>
                                ) : null}
                              </div>
                            ) : null}{" "}
                          </div>
                          <Collapse
                            isOpen={this.state.collapse.includes("7")}
                            className="collapseTransition"
                          >
                            <Card style={{ border: "none" }}>
                              <CardBody style={{ border: "none" }}>
                                <Row>
                                  <Col sm="9">
                                    <Health
                                      data={this.state.data.balanced}
                                      iconData={this.state.balIcondata}
                                      isResize={false}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                    ></Health>
                                  </Col>
                                  <Col
                                    sm="3"
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      marginTop: "220px",
                                      marginBottom: "auto",
                                    }}
                                  >
                                    <Button
                                      className="btn-common"
                                      onClick={() => {
                                        this.showCostHealthModal("bal");
                                      }}
                                    >
                                      Show Details
                                    </Button>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Collapse>
                        </div>
                        <div id="tab8">
                          <div
                            color="primary"
                            onClick={() => {
                              this.toggleCollapse("8");
                            }}
                            className="collapsediv"
                          >
                            <div id="collapse_head">
                              <div className="arrow">
                                {!this.state.collapse.includes("8")
                                  ? CLOSE
                                  : OPEN}{" "}
                              </div>{" "}
                              <div id="overViewCollapseTitle">
                                Recommendation
                              </div>
                            </div>{" "}
                            {!this.state.collapse.includes("8") ? (
                              <div className="maindiv">
                                <ColorComponent
                                  data={this.state.balIcondata[0].cpu}
                                  isResize={true}
                                  showInfo={false}
                                  isLine={true}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  showCostPerfMarker={true}
                                  fromOverview={true}
                                ></ColorComponent>
                                {this.state.entityType === "db" ? (
                                  <ColorComponent
                                    data={this.state.balIcondata[0].disks}
                                    isResize={true}
                                    showInfo={false}
                                    isLine={true}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    fromOverview={true}
                                  ></ColorComponent>
                                ) : null}
                                <ColorComponent
                                  data={this.state.balIcondata[0].memory}
                                  isResize={true}
                                  showInfo={false}
                                  isLine={true}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  hostOnly={hostOnly}
                                  fromOverview={true}
                                ></ColorComponent>
                                <ColorComponent
                                  data={this.state.balIcondata[0].network}
                                  isResize={true}
                                  showInfo={false}
                                  isLine={
                                    this.state.entityType === "db"
                                      ? false
                                      : true
                                  }
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  fromOverview={true}
                                ></ColorComponent>
                                {this.state.entityType !== "db" ? (
                                  <ColorComponent
                                    data={this.state.balIcondata[0].disks}
                                    isResize={true}
                                    showInfo={false}
                                    isLine={false}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    fromOverview={true}
                                  ></ColorComponent>
                                ) : null}
                              </div>
                            ) : null}{" "}
                          </div>
                          <Collapse
                            isOpen={this.state.collapse.includes("8")}
                            className="collapseTransition"
                          >
                            <Card style={{ border: "none" }}>
                              <CardBody style={{ border: "none" }}>
                                <Recommend
                                  data={this.state.data.balanced}
                                  iconData={this.state.balIcondata}
                                  isResize={true}
                                  isLine={true}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  userCurrency={this.state.data?.userCurrency}
                                  providerName={this.state.providerName}
                                ></Recommend>
                              </CardBody>
                            </Card>
                          </Collapse>
                        </div>
                        <div id="tab9">
                          <div
                            color="primary"
                            onClick={() => {
                              this.toggleCollapse("9");
                            }}
                            className="collapsediv resize-collapsediv"
                          >
                            <div
                              id="collapse_head"
                              style={{ minHeight: "102px", maxWidth: "28%" }}
                            >
                              <div className="arrow">
                                {!this.state.collapse.includes("9")
                                  ? CLOSE
                                  : OPEN}
                              </div>
                              <div id="overViewCollapseTitle">
                                Expected Benefits & Drawbacks of Recommendation
                              </div>
                            </div>{" "}
                            {!this.state.collapse.includes("9") ? (
                              <div className="overviewTextCompParent">
                                {this.state.data.balanced !== undefined &&
                                this.state.data.balanced.summary
                                  .costSummaryText !== undefined &&
                                this.state.data.balanced.summary
                                  .costSummaryText !== null ? (
                                  <div className="maindiv-text-overview maindiv-text-overview-grad">
                                    <ResingBenefitTextComponent
                                      title={
                                        this.state.data.balanced.summary
                                          .costSummaryText
                                      }
                                      data={
                                        this.state.data.balanced.summary
                                          .costSummaryDescription
                                      }
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                                {this.state.data.balanced !== undefined &&
                                this.state.data.balanced.summary
                                  .perfSummaryText !== undefined &&
                                this.state.data.balanced.summary
                                  .perfSummaryText !== null ? (
                                  <div className="maindiv-text-overview maindiv-text-overview-grad">
                                    <ResingBenefitTextComponent
                                      data={
                                        this.state.data.balanced.summary
                                          .perfSummaryDescription
                                      }
                                      title={
                                        this.state.data.balanced.summary
                                          .perfSummaryText
                                      }
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : null}
                          </div>
                          <Collapse
                            isOpen={this.state.collapse.includes("9")}
                            className="collapseTransition"
                          >
                            <Card style={{ border: "none" }}>
                              <CardBody style={{ border: "none" }}>
                                <Resize
                                  data={this.state.data.balanced}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  userCurrency={this.state.data?.userCurrency}
                                  providerName={this.state.providerName}
                                ></Resize>
                              </CardBody>
                            </Card>
                          </Collapse>
                        </div>
                        {this.state.data &&
                        this.state.data.gaiValidationData &&
                        this.state.data.gaiValidationData.validationPerfType ===
                          "Balanced" ? (
                          <>
                            <div id="tab21">
                              <div
                                color="primary"
                                onClick={() => {
                                  this.toggleCollapse("21");
                                }}
                                className="collapsediv"
                              >
                                <div id="collapse_head">
                                  <div className="arrow">
                                    {!this.state.collapse.includes("21")
                                      ? CLOSE
                                      : OPEN}{" "}
                                  </div>{" "}
                                  <div id="overViewCollapseTitle">
                                    GAI Recommendation
                                  </div>
                                </div>{" "}
                                {!this.state.collapse.includes("21") ? (
                                  <div className="maindiv">
                                    <ColorComponent
                                      data={this.state.balIcondata[0].cpu}
                                      isResize={true}
                                      showInfo={false}
                                      isLine={true}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      showCostPerfMarker={true}
                                      fromOverview={true}
                                    ></ColorComponent>
                                    {this.state.entityType === "db" ? (
                                      <ColorComponent
                                        data={this.state.balIcondata[0].disks}
                                        isResize={true}
                                        showInfo={false}
                                        isLine={true}
                                        isAsp={this.state.isAsp}
                                        entityType={this.state.entityType}
                                        fromOverview={true}
                                      ></ColorComponent>
                                    ) : null}
                                    <ColorComponent
                                      data={this.state.balIcondata[0].memory}
                                      isResize={true}
                                      showInfo={false}
                                      isLine={true}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      hostOnly={hostOnly}
                                      fromOverview={true}
                                    ></ColorComponent>
                                    <ColorComponent
                                      data={this.state.balIcondata[0].network}
                                      isResize={true}
                                      showInfo={false}
                                      isLine={
                                        this.state.entityType === "db"
                                          ? false
                                          : true
                                      }
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      fromOverview={true}
                                    ></ColorComponent>
                                    {this.state.entityType !== "db" ? (
                                      <ColorComponent
                                        data={this.state.balIcondata[0].disks}
                                        isResize={true}
                                        showInfo={false}
                                        isLine={false}
                                        isAsp={this.state.isAsp}
                                        entityType={this.state.entityType}
                                        fromOverview={true}
                                      ></ColorComponent>
                                    ) : null}
                                  </div>
                                ) : null}{" "}
                              </div>
                              <Collapse
                                isOpen={this.state.collapse.includes("21")}
                                className="collapseTransition"
                              >
                                <Card style={{ border: "none" }}>
                                  <CardBody style={{ border: "none" }}>
                                    <GaiRecommend
                                      data={this.state.data.gaiValidationData}
                                      iconData={this.state.balIcondata}
                                      isResize={true}
                                      isLine={true}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      userCurrency={
                                        this.state.data?.userCurrency
                                      }
                                      providerName={this.state.providerName}
                                    ></GaiRecommend>
                                  </CardBody>
                                </Card>
                              </Collapse>
                            </div>
                            <div id="tab22">
                              <div
                                color="primary"
                                onClick={() => {
                                  this.toggleCollapse("22");
                                }}
                                className="collapsediv resize-collapsediv"
                              >
                                <div
                                  id="collapse_head"
                                  style={{
                                    minHeight: "102px",
                                    maxWidth: "28%",
                                  }}
                                >
                                  <div className="arrow">
                                    {!this.state.collapse.includes("22")
                                      ? CLOSE
                                      : OPEN}
                                  </div>
                                  <div id="overViewCollapseTitle">
                                    Expected Benefits & Drawbacks of GAI
                                    Recommendation
                                  </div>
                                </div>{" "}
                                {!this.state.collapse.includes("22") ? (
                                  <div className="overviewTextCompParent">
                                    {this.state.data.gaiValidationData !==
                                      undefined &&
                                    this.state.data.gaiValidationData.summary
                                      .costSummaryText !== undefined &&
                                    this.state.data.gaiValidationData.summary
                                      .costSummaryText !== null ? (
                                      <div className="maindiv-text-overview maindiv-text-overview-grad">
                                        <ResingBenefitTextComponent
                                          title={
                                            this.state.data.gaiValidationData
                                              .summary.costSummaryText
                                          }
                                          data={
                                            this.state.data.gaiValidationData
                                              .summary.costSummaryDescription
                                          }
                                          isAsp={this.state.isAsp}
                                          entityType={this.state.entityType}
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.data.gaiValidationData !==
                                      undefined &&
                                    this.state.data.gaiValidationData.summary
                                      .perfSummaryText !== undefined &&
                                    this.state.data.gaiValidationData.summary
                                      .perfSummaryText !== null ? (
                                      <div className="maindiv-text-overview maindiv-text-overview-grad">
                                        <ResingBenefitTextComponent
                                          data={
                                            this.state.data.gaiValidationData
                                              .summary.perfSummaryDescription
                                          }
                                          title={
                                            this.state.data.gaiValidationData
                                              .summary.perfSummaryText
                                          }
                                          isAsp={this.state.isAsp}
                                          entityType={this.state.entityType}
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : null}
                              </div>
                              <Collapse
                                isOpen={this.state.collapse.includes("22")}
                                className="collapseTransition"
                              >
                                <Card style={{ border: "none" }}>
                                  <CardBody style={{ border: "none" }}>
                                    <GaiResize
                                      data={this.state.data.gaiValidationData}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      userCurrency={
                                        this.state.data?.userCurrency
                                      }
                                      providerName={this.state.providerName}
                                    ></GaiResize>
                                  </CardBody>
                                </Card>
                              </Collapse>
                            </div>
                          </>
                        ) : null}
                        {this.state.isValidationGraphTable === false &&
                        this.state.data.validationData !== null &&
                        this.state.data.validationData !== undefined &&
                        this.state.data.validationData.perfType ===
                          "Balanced" ? (
                          <div id="tab10">
                            <div
                              onClick={() => {
                                this.toggleCollapse("10");
                              }}
                              className="collapsediv"
                            >
                              <div id="collapse_head">
                                <div className="arrow">
                                  {!this.state.collapse.includes("10")
                                    ? CLOSE
                                    : OPEN}
                                </div>
                                <div>
                                  <div id="overViewCollapseTitle">
                                    Validation :
                                  </div>
                                  <div>
                                    <b>Balanced</b>
                                  </div>
                                  <div>{bvmStatus}</div>
                                </div>
                              </div>
                              {!this.state.collapse.includes("10") ? (
                                this.state.balIcondata.length > 0 ? (
                                  <div className="maindiv">
                                    <ColorComponent
                                      data={this.state.balIcondata[0].cpu}
                                      isResize={true}
                                      isLine={true}
                                      showInfo={false}
                                      isIcon={true}
                                      valStatus={bvmStatus}
                                      forValidation={true}
                                      valObjective="Balanced"
                                      valData={this.state.data.validationData}
                                      showCostPerfMarker={true}
                                      fromOverview={true}
                                    ></ColorComponent>
                                    {this.state.entityType === "db" ? (
                                      <ColorComponent
                                        data={this.state.balIcondata[0].disks}
                                        isResize={true}
                                        isLine={true}
                                        showInfo={false}
                                        isIcon={true}
                                        valStatus={bvmStatus}
                                        forValidation={true}
                                        valObjective="Balanced"
                                        valData={this.state.data.validationData}
                                        fromOverview={true}
                                      ></ColorComponent>
                                    ) : null}
                                    <ColorComponent
                                      data={this.state.balIcondata[0].memory}
                                      isResize={true}
                                      isLine={true}
                                      showInfo={false}
                                      isIcon={true}
                                      valStatus={bvmStatus}
                                      forValidation={true}
                                      valObjective="Balanced"
                                      valData={this.state.data.validationData}
                                      hostOnly={hostOnly}
                                      fromOverview={true}
                                    ></ColorComponent>
                                    <ColorComponent
                                      data={this.state.balIcondata[0].network}
                                      isResize={true}
                                      isLine={
                                        this.state.entityType === "db"
                                          ? false
                                          : true
                                      }
                                      showInfo={false}
                                      isIcon={true}
                                      valStatus={bvmStatus}
                                      forValidation={true}
                                      valObjective="Balanced"
                                      valData={this.state.data.validationData}
                                      fromOverview={true}
                                    ></ColorComponent>
                                    {this.state.entityType !== "db" ? (
                                      <ColorComponent
                                        data={this.state.balIcondata[0].disks}
                                        isResize={true}
                                        isLine={false}
                                        showInfo={false}
                                        isIcon={true}
                                        valStatus={bvmStatus}
                                        forValidation={true}
                                        valObjective="Balanced"
                                        valData={this.state.data.validationData}
                                        fromOverview={true}
                                      ></ColorComponent>
                                    ) : null}
                                  </div>
                                ) : null
                              ) : null}
                            </div>
                            {this.state.data.validationData.perfType ===
                            "Balanced" ? (
                              <Collapse
                                isOpen={this.state.collapse.includes("10")}
                                className="collapseTransition"
                              >
                                <Card style={{ border: "none" }}>
                                  <CardBody style={{ border: "none" }}>
                                    <div>
                                      <ValidationTable
                                        data={
                                          this.state.recommendationData.balanced
                                        }
                                        valTableData={
                                          this.state.data.validationData
                                        }
                                        iconData={this.state.balIcondata}
                                        isResize={true}
                                        isLine={true}
                                        isIcon={true}
                                        valStatus={bvmStatus}
                                        valObjective="Balanced"
                                        showValidationDetails={
                                          this.showValidationDetails
                                        }
                                      ></ValidationTable>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Collapse>
                            ) : null}
                          </div>
                        ) : null}
                        {this.state.isValidationGraphTable === false &&
                        this.state.data.validationData !== null &&
                        this.state.data.validationData !== undefined &&
                        this.state.data.validationData.perfType ===
                          "Balanced" &&
                        bvmStatus === "VALIDATED" ? (
                          <div id="tab11">
                            <div
                              onClick={() => {
                                this.toggleCollapse("11");
                              }}
                              className="collapsediv"
                            >
                              <div
                                id="collapse_head"
                                style={{ maxWidth: "29%" }}
                              >
                                <div className="arrow">
                                  {!this.state.collapse.includes("11")
                                    ? CLOSE
                                    : OPEN}
                                </div>
                                <div>
                                  <div id="overViewCollapseTitle">
                                    Validated Benefits & Drawbacks of
                                    Recommendation :{" "}
                                  </div>
                                  <div>
                                    <b>Balanced</b>
                                  </div>
                                </div>
                              </div>
                              {!this.state.collapse.includes("11") ? (
                                <div className="overviewTextCompParent">
                                  {this.state.data.balanced !== undefined &&
                                  this.state.data.balanced.validation_summary
                                    .costSummaryText !== undefined &&
                                  this.state.data.balanced.validation_summary
                                    .costSummaryText !== null ? (
                                    <div className="maindiv-text-overview maindiv-text-overview-grad">
                                      <ResingBenefitTextComponent
                                        title={
                                          this.state.data.balanced
                                            .validation_summary.costSummaryText
                                        }
                                        data={
                                          this.state.data.balanced
                                            .validation_summary
                                            .costSummaryDescription
                                        }
                                        affectString={
                                          this.state.data.balanced
                                            .validation_summary.perfAffected
                                        }
                                        valResult={
                                          this.state.data.balanced
                                            .validation_summary
                                            .validation_cost_result
                                        }
                                        renderIcon={
                                          this.state.data.validationPerfType !==
                                            null &&
                                          this.state.data.validationPerfType ===
                                            "Balanced" &&
                                          this.state.data.bvmState !==
                                            "VALIDATING"
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                  ) : null}
                                  {this.state.data.balanced !== undefined &&
                                  this.state.data.balanced.validation_summary
                                    .perfSummaryText !== undefined &&
                                  this.state.data.balanced.validation_summary
                                    .perfSummaryText !== null ? (
                                    <div className="maindiv-text-overview maindiv-text-overview-grad">
                                      <ResingBenefitTextComponent
                                        data={
                                          this.state.data.balanced
                                            .validation_summary
                                            .perfSummaryDescription
                                        }
                                        title={
                                          this.state.data.balanced
                                            .validation_summary.perfSummaryText
                                        }
                                        affectString={
                                          this.state.data.balanced.summary
                                            .increasedCost
                                        }
                                        valResult={
                                          this.state.data.balanced
                                            .validation_summary
                                            .validation_perf_result
                                        }
                                        renderIcon={
                                          this.state.data.validationPerfType !==
                                            null &&
                                          this.state.data.validationPerfType ===
                                            "Balanced" &&
                                          this.state.data.bvmState !==
                                            "VALIDATING"
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                            <Collapse
                              isOpen={this.state.collapse.includes("11")}
                              className="collapseTransition"
                            >
                              <Card style={{ border: "none" }}>
                                <CardBody style={{ border: "none" }}>
                                  <div>
                                    <ValidationDetailsTable
                                      data={this.state.data.validationData}
                                      isValidationGraphTable={false}
                                      valSummary={
                                        this.state.data.balanced
                                          .validation_summary
                                      }
                                    />
                                  </div>
                                </CardBody>
                              </Card>
                            </Collapse>
                          </div>
                        ) : null}
                        {this.state.recommendationData.balanced !== undefined &&
                        this.state.recommendationData.balanced
                          .isRecommendationSelected === true ? (
                          <div id="tab12">
                            <div
                              onClick={() => {
                                this.toggleCollapse("12");
                              }}
                              className="collapsediv"
                            >
                              <div id="collapse_head">
                                <div className="arrow">
                                  {!this.state.collapse.includes("12")
                                    ? CLOSE
                                    : OPEN}
                                </div>
                                <div id="overViewCollapseTitle">
                                  Resizing Status :{" "}
                                  <div
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {this.state.recommendationData.bvmState}{" "}
                                    {this.state.recommendationData.bvmState ===
                                    "OPTIMIZING" ? (
                                      <i
                                        style={{ margin: "auto 14px" }}
                                        className="fa fa-info-circle"
                                        aria-hidden="true"
                                        data-toggle="tooltip"
                                        data-placement="left"
                                        title="When manual resizing has been completed, please mark it as complete using Manage VMs page"
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              {!this.state.collapse.includes("12") &&
                              this.state.balIcondataStatus.length > 0 ? (
                                <div className="maindiv">
                                  <ColorComponent
                                    data={this.state.balIcondataStatus[0].cpu}
                                    isResize={true}
                                    isLine={true}
                                    showInfo={false}
                                    isIcon={true}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    showCostPerfMarker={true}
                                    fromOverview={true}
                                  ></ColorComponent>
                                  {this.state.entityType === "db" ? (
                                    <ColorComponent
                                      data={
                                        this.state.balIcondataStatus[0].disks
                                      }
                                      isResize={true}
                                      isLine={true}
                                      showInfo={false}
                                      isIcon={true}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      fromOverview={true}
                                    ></ColorComponent>
                                  ) : null}
                                  <ColorComponent
                                    data={
                                      this.state.balIcondataStatus[0].memory
                                    }
                                    isResize={true}
                                    isLine={true}
                                    showInfo={false}
                                    isIcon={true}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    hostOnly={hostOnly}
                                    fromOverview={true}
                                  ></ColorComponent>
                                  <ColorComponent
                                    data={
                                      this.state.balIcondataStatus[0].network
                                    }
                                    isResize={true}
                                    isLine={
                                      this.state.entityType === "db"
                                        ? false
                                        : true
                                    }
                                    showInfo={false}
                                    isIcon={true}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    fromOverview={true}
                                  ></ColorComponent>
                                  {this.state.entityType !== "db" ? (
                                    <ColorComponent
                                      data={
                                        this.state.balIcondataStatus[0].disks
                                      }
                                      isResize={true}
                                      isLine={false}
                                      showInfo={false}
                                      isIcon={true}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      fromOverview={true}
                                    ></ColorComponent>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                            {this.state.balIcondataStatus.length > 0 ? (
                              <Collapse
                                isOpen={this.state.collapse.includes("12")}
                                className="collapseTransition"
                              >
                                <Card style={{ border: "none" }}>
                                  <CardBody style={{ border: "none" }}>
                                    <Recommendstatus
                                      data={
                                        this.state.recommendationData.balanced
                                      }
                                      iconData={this.state.balIcondataStatus}
                                      isResize={true}
                                      isLine={true}
                                      isIcon={true}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      providerName={this.state.providerName}
                                    ></Recommendstatus>
                                  </CardBody>
                                </Card>
                              </Collapse>
                            ) : null}
                          </div>
                        ) : null}

                        {this.state.data.balanced !== undefined &&
                        this.state.data.balanced.gaiInsightSummary !== null ? (
                          <div id="tab26">
                            <div
                              color="primary"
                              onClick={() => {
                                this.toggleCollapse("26");
                              }}
                              className="collapsediv resize-collapsediv"
                            >
                              <div
                                id="collapse_head"
                                style={{ minHeight: "102px", maxWidth: "28%" }}
                              >
                                <div className="arrow">
                                  {!this.state.collapse.includes("26")
                                    ? CLOSE
                                    : OPEN}
                                </div>
                                <div id="overViewCollapseTitle">
                                  GAI Insight
                                </div>
                              </div>
                              {!this.state.collapse.includes("26") ? (
                                this.state.data.balanced !== undefined &&
                                this.state.data.balanced.gaiInsightSummary !==
                                  null ? (
                                  <div className="overviewTextCompParent w-50">
                                    <div className="maindiv-text-overview maindiv-text-overview-grad p-3 px-4 text-center">
                                      {
                                        this.state.data.balanced
                                          ?.gaiInsightSummary
                                      }
                                    </div>
                                  </div>
                                ) : null
                              ) : null}
                            </div>

                            <Collapse
                              isOpen={this.state.collapse.includes("26")}
                              className="collapseTransition"
                            >
                              <Card style={{ border: "none" }}>
                                <CardBody style={{ border: "none" }}>
                                  {this.state.data.balanced !== undefined &&
                                  this.state.data.balanced
                                    .gaiInsightDescription !== null ? (
                                    <>
                                      <div className="overviewTextCompParent w-75 mx-auto">
                                        <div className="maindiv-text-overview maindiv-text-overview-grad p-3 px-4">
                                          {
                                            this.state.data.balanced
                                              ?.gaiInsightDescription
                                          }
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                </CardBody>
                              </Card>
                            </Collapse>
                          </div>
                        ) : null}
                      </TabPane>
                      <TabPane tabId="3">
                        <div id="tab13">
                          <div
                            color="primary"
                            onClick={() => {
                              this.toggleCollapse("13");
                            }}
                            className="collapsediv"
                          >
                            <div id="collapse_head">
                              <div className="arrow">
                                {!this.state.collapse.includes("13")
                                  ? CLOSE
                                  : OPEN}
                              </div>
                              <div id="overViewCollapseTitle">Analysis</div>
                            </div>{" "}
                            {!this.state.collapse.includes("13") ? (
                              <div className="maindiv">
                                <ColorComponent
                                  data={this.state.healthyIcondata[0].cpu}
                                  isResize={false}
                                  showInfo={false}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  showCostPerfMarker={true}
                                  fromOverview={true}
                                ></ColorComponent>
                                {this.state.entityType === "db" ? (
                                  <ColorComponent
                                    data={this.state.healthyIcondata[0].disks}
                                    isResize={false}
                                    showInfo={false}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    fromOverview={true}
                                  ></ColorComponent>
                                ) : null}
                                <ColorComponent
                                  data={this.state.healthyIcondata[0].memory}
                                  isResize={false}
                                  showInfo={false}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  hostOnly={hostOnly}
                                  fromOverview={true}
                                ></ColorComponent>
                                <ColorComponent
                                  data={this.state.healthyIcondata[0].network}
                                  isResize={false}
                                  showInfo={false}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  fromOverview={true}
                                ></ColorComponent>
                                {this.state.entityType !== "db" ? (
                                  <ColorComponent
                                    data={this.state.healthyIcondata[0].disks}
                                    isResize={false}
                                    showInfo={false}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    fromOverview={true}
                                  ></ColorComponent>
                                ) : null}
                              </div>
                            ) : null}{" "}
                          </div>
                          <Collapse
                            isOpen={this.state.collapse.includes("13")}
                            className="collapseTransition"
                          >
                            <Card style={{ border: "none" }}>
                              <CardBody style={{ border: "none" }}>
                                <Row>
                                  <Col sm="9">
                                    <Health
                                      data={this.state.data.conservative}
                                      iconData={this.state.healthyIcondata}
                                      isResize={false}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                    ></Health>
                                  </Col>
                                  <Col
                                    sm="3"
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      marginTop: "220px",
                                      marginBottom: "auto",
                                    }}
                                  >
                                    <Button
                                      className="btn-common"
                                      onClick={() => {
                                        this.showCostHealthModal("health");
                                      }}
                                    >
                                      Show Details
                                    </Button>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Collapse>
                        </div>
                        <div id="tab14">
                          <div
                            color="primary"
                            onClick={() => {
                              this.toggleCollapse("14");
                            }}
                            className="collapsediv"
                          >
                            <div id="collapse_head">
                              <div className="arrow">
                                {!this.state.collapse.includes("14")
                                  ? CLOSE
                                  : OPEN}
                              </div>
                              <div id="overViewCollapseTitle">
                                Recommendation
                              </div>
                            </div>{" "}
                            {!this.state.collapse.includes("14") ? (
                              <div className="maindiv">
                                <ColorComponent
                                  data={this.state.healthyIcondata[0].cpu}
                                  isResize={true}
                                  showInfo={false}
                                  isLine={true}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  showCostPerfMarker={true}
                                  fromOverview={true}
                                ></ColorComponent>
                                {this.state.entityType === "db" ? (
                                  <ColorComponent
                                    data={this.state.healthyIcondata[0].disks}
                                    isResize={true}
                                    showInfo={false}
                                    isLine={true}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    fromOverview={true}
                                  ></ColorComponent>
                                ) : null}
                                <ColorComponent
                                  data={this.state.healthyIcondata[0].memory}
                                  isResize={true}
                                  showInfo={false}
                                  isLine={true}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  hostOnly={hostOnly}
                                  fromOverview={true}
                                ></ColorComponent>
                                <ColorComponent
                                  data={this.state.healthyIcondata[0].network}
                                  isResize={true}
                                  showInfo={false}
                                  isLine={
                                    this.state.entityType === "db"
                                      ? false
                                      : true
                                  }
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  fromOverview={true}
                                ></ColorComponent>
                                {this.state.entityType !== "db" ? (
                                  <ColorComponent
                                    data={this.state.healthyIcondata[0].disks}
                                    isResize={true}
                                    showInfo={false}
                                    isLine={false}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    fromOverview={true}
                                  ></ColorComponent>
                                ) : null}
                              </div>
                            ) : null}{" "}
                          </div>
                          <Collapse
                            isOpen={this.state.collapse.includes("14")}
                            className="collapseTransition"
                          >
                            <Card style={{ border: "none" }}>
                              <CardBody style={{ border: "none" }}>
                                <Recommend
                                  data={this.state.data.conservative}
                                  iconData={this.state.healthyIcondata}
                                  isResize={true}
                                  isLine={true}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  userCurrency={this.state.data?.userCurrency}
                                  providerName={this.state.providerName}
                                ></Recommend>
                              </CardBody>
                            </Card>
                          </Collapse>
                        </div>
                        <div id="tab15">
                          <div
                            color="primary"
                            onClick={() => {
                              this.toggleCollapse("15");
                            }}
                            className="collapsediv resize-collapsediv"
                          >
                            <div
                              id="collapse_head"
                              style={{ minHeight: "102px", maxWidth: "28%" }}
                            >
                              <div className="arrow">
                                {!this.state.collapse.includes("15")
                                  ? CLOSE
                                  : OPEN}
                              </div>
                              <div id="overViewCollapseTitle">
                                Expected Benefits & Drawbacks of Recommendation
                              </div>{" "}
                            </div>{" "}
                            {!this.state.collapse.includes("15") ? (
                              <div className="overviewTextCompParent">
                                {this.state.data.conservative !== undefined &&
                                this.state.data.conservative.summary
                                  .costSummaryText !== undefined &&
                                this.state.data.conservative.summary
                                  .costSummaryText !== null ? (
                                  <div className="maindiv-text-overview maindiv-text-overview-grad">
                                    <ResingBenefitTextComponent
                                      title={
                                        this.state.data.conservative.summary
                                          .costSummaryText
                                      }
                                      data={
                                        this.state.data.conservative.summary
                                          .costSummaryDescription
                                      }
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                    />
                                  </div>
                                ) : null}
                                {this.state.data.conservative !== undefined &&
                                this.state.data.conservative.summary
                                  .perfSummaryText !== undefined &&
                                this.state.data.balanced.summary
                                  .perfSummaryText !== null ? (
                                  <div className="maindiv-text-overview maindiv-text-overview-grad">
                                    <ResingBenefitTextComponent
                                      data={
                                        this.state.data.conservative.summary
                                          .perfSummaryDescription
                                      }
                                      title={
                                        this.state.data.conservative.summary
                                          .perfSummaryText
                                      }
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                          <Collapse
                            isOpen={this.state.collapse.includes("15")}
                            className="collapseTransition"
                          >
                            <Card style={{ border: "none" }}>
                              <CardBody style={{ border: "none" }}>
                                <Resize
                                  data={this.state.data.conservative}
                                  isAsp={this.state.isAsp}
                                  entityType={this.state.entityType}
                                  userCurrency={this.state.data?.userCurrency}
                                  providerName={this.state.providerName}
                                ></Resize>
                              </CardBody>
                            </Card>
                          </Collapse>
                        </div>

                        {this.state.data &&
                        this.state.data.gaiValidationData &&
                        this.state.data.gaiValidationData.validationPerfType ===
                          "Conservative" ? (
                          <>
                            <div id="tab23">
                              <div
                                color="primary"
                                onClick={() => {
                                  this.toggleCollapse("23");
                                }}
                                className="collapsediv"
                              >
                                <div id="collapse_head">
                                  <div className="arrow">
                                    {!this.state.collapse.includes("23")
                                      ? CLOSE
                                      : OPEN}
                                  </div>
                                  <div id="overViewCollapseTitle">
                                    GAI Recommendation
                                  </div>
                                </div>{" "}
                                {!this.state.collapse.includes("23") ? (
                                  <div className="maindiv">
                                    <ColorComponent
                                      data={this.state.gaiIcondata[0].cpu}
                                      isResize={true}
                                      showInfo={false}
                                      isLine={true}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      showCostPerfMarker={true}
                                      fromOverview={true}
                                    ></ColorComponent>
                                    {this.state.entityType === "db" ? (
                                      <ColorComponent
                                        data={this.state.gaiIcondata[0].disks}
                                        isResize={true}
                                        showInfo={false}
                                        isLine={true}
                                        isAsp={this.state.isAsp}
                                        entityType={this.state.entityType}
                                        fromOverview={true}
                                      ></ColorComponent>
                                    ) : null}
                                    <ColorComponent
                                      data={this.state.gaiIcondata[0].memory}
                                      isResize={true}
                                      showInfo={false}
                                      isLine={true}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      hostOnly={hostOnly}
                                      fromOverview={true}
                                    ></ColorComponent>
                                    <ColorComponent
                                      data={this.state.gaiIcondata[0].network}
                                      isResize={true}
                                      showInfo={false}
                                      isLine={
                                        this.state.entityType === "db"
                                          ? false
                                          : true
                                      }
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      fromOverview={true}
                                    ></ColorComponent>
                                    {this.state.entityType !== "db" ? (
                                      <ColorComponent
                                        data={this.state.gaiIcondata[0].disks}
                                        isResize={true}
                                        showInfo={false}
                                        isLine={false}
                                        isAsp={this.state.isAsp}
                                        entityType={this.state.entityType}
                                        fromOverview={true}
                                      ></ColorComponent>
                                    ) : null}
                                  </div>
                                ) : null}{" "}
                              </div>
                              <Collapse
                                isOpen={this.state.collapse.includes("23")}
                                className="collapseTransition"
                              >
                                <Card style={{ border: "none" }}>
                                  <CardBody style={{ border: "none" }}>
                                    <GaiRecommend
                                      data={this.state.data.gaiValidationData}
                                      iconData={this.state.gaiIcondata}
                                      isResize={true}
                                      isLine={true}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      userCurrency={
                                        this.state.data?.userCurrency
                                      }
                                      providerName={this.state.providerName}
                                    ></GaiRecommend>
                                  </CardBody>
                                </Card>
                              </Collapse>
                            </div>
                            <div id="tab24">
                              <div
                                color="primary"
                                onClick={() => {
                                  this.toggleCollapse("24");
                                }}
                                className="collapsediv resize-collapsediv"
                              >
                                <div
                                  id="collapse_head"
                                  style={{
                                    minHeight: "102px",
                                    maxWidth: "28%",
                                  }}
                                >
                                  <div className="arrow">
                                    {!this.state.collapse.includes("24")
                                      ? CLOSE
                                      : OPEN}
                                  </div>
                                  <div id="overViewCollapseTitle">
                                    Expected Benefits & Drawbacks of GAI
                                    Recommendation
                                  </div>{" "}
                                </div>{" "}
                                {!this.state.collapse.includes("24") ? (
                                  <div className="overviewTextCompParent">
                                    {this.state.data.gaiValidationData !==
                                      undefined &&
                                    this.state.data.gaiValidationData.summary
                                      .costSummaryText !== undefined &&
                                    this.state.data.gaiValidationData.summary
                                      .costSummaryText !== null ? (
                                      <div className="maindiv-text-overview maindiv-text-overview-grad">
                                        <ResingBenefitTextComponent
                                          title={
                                            this.state.data.gaiValidationData
                                              .summary.costSummaryText
                                          }
                                          data={
                                            this.state.data.gaiValidationData
                                              .summary.costSummaryDescription
                                          }
                                          isAsp={this.state.isAsp}
                                          entityType={this.state.entityType}
                                        />
                                      </div>
                                    ) : null}
                                    {this.state.data.gaiValidationData !==
                                      undefined &&
                                    this.state.data.gaiValidationData.summary
                                      .perfSummaryText !== undefined &&
                                    this.state.data.balanced.summary
                                      .perfSummaryText !== null ? (
                                      <div className="maindiv-text-overview maindiv-text-overview-grad">
                                        <ResingBenefitTextComponent
                                          data={
                                            this.state.data.gaiValidationData
                                              .summary.perfSummaryDescription
                                          }
                                          title={
                                            this.state.data.gaiValidationData
                                              .summary.perfSummaryText
                                          }
                                          isAsp={this.state.isAsp}
                                          entityType={this.state.entityType}
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                ) : null}
                              </div>
                              <Collapse
                                isOpen={this.state.collapse.includes("24")}
                                className="collapseTransition"
                              >
                                <Card style={{ border: "none" }}>
                                  <CardBody style={{ border: "none" }}>
                                    <GaiResize
                                      data={this.state.data.gaiValidationData}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      userCurrency={
                                        this.state.data?.userCurrency
                                      }
                                      providerName={this.state.providerName}
                                    ></GaiResize>
                                  </CardBody>
                                </Card>
                              </Collapse>
                            </div>
                          </>
                        ) : null}

                        {this.state.isValidationGraphTable === false &&
                        this.state.data.validationData !== null &&
                        this.state.data.validationData !== undefined &&
                        this.state.data.validationData.perfType ===
                          "Conservative" ? (
                          <div id="tab16">
                            <div
                              onClick={() => {
                                this.toggleCollapse("16");
                              }}
                              className="collapsediv"
                            >
                              <div id="collapse_head">
                                <div className="arrow">
                                  {!this.state.collapse.includes("16")
                                    ? CLOSE
                                    : OPEN}
                                </div>
                                <div>
                                  <div id="overViewCollapseTitle">
                                    Validation :
                                  </div>
                                  <div>
                                    <b>Enhanced</b>
                                  </div>
                                  <div>{bvmStatus}</div>
                                </div>
                              </div>
                              {!this.state.collapse.includes("16") ? (
                                this.state.healthyIcondata.length > 0 ? (
                                  <div className="maindiv">
                                    <ColorComponent
                                      data={this.state.healthyIcondata[0].cpu}
                                      isResize={true}
                                      isLine={true}
                                      showInfo={false}
                                      isIcon={true}
                                      valStatus={bvmStatus}
                                      forValidation={true}
                                      valObjective="Enhanced"
                                      valData={this.state.data.validationData}
                                      showCostPerfMarker={true}
                                      fromOverview={true}
                                    ></ColorComponent>
                                    {this.state.entityType === "db" ? (
                                      <ColorComponent
                                        data={
                                          this.state.healthyIcondata[0].disks
                                        }
                                        isResize={true}
                                        isLine={true}
                                        showInfo={false}
                                        isIcon={true}
                                        valStatus={bvmStatus}
                                        forValidation={true}
                                        valObjective="Enhanced"
                                        valData={this.state.data.validationData}
                                        fromOverview={true}
                                      ></ColorComponent>
                                    ) : null}
                                    <ColorComponent
                                      data={
                                        this.state.healthyIcondata[0].memory
                                      }
                                      isResize={true}
                                      isLine={true}
                                      showInfo={false}
                                      isIcon={true}
                                      valStatus={bvmStatus}
                                      forValidation={true}
                                      valObjective="Enhanced"
                                      valData={this.state.data.validationData}
                                      hostOnly={hostOnly}
                                      fromOverview={true}
                                    ></ColorComponent>
                                    <ColorComponent
                                      data={
                                        this.state.healthyIcondata[0].network
                                      }
                                      isResize={true}
                                      isLine={
                                        this.state.entityType === "db"
                                          ? false
                                          : true
                                      }
                                      showInfo={false}
                                      isIcon={true}
                                      valStatus={bvmStatus}
                                      forValidation={true}
                                      valObjective="Enhanced"
                                      valData={this.state.data.validationData}
                                      fromOverview={true}
                                    ></ColorComponent>
                                    {this.state.entityType !== "db" ? (
                                      <ColorComponent
                                        data={
                                          this.state.healthyIcondata[0].disks
                                        }
                                        isResize={true}
                                        isLine={false}
                                        showInfo={false}
                                        isIcon={true}
                                        valStatus={bvmStatus}
                                        forValidation={true}
                                        valObjective="Enhanced"
                                        valData={this.state.data.validationData}
                                        fromOverview={true}
                                      ></ColorComponent>
                                    ) : null}
                                  </div>
                                ) : null
                              ) : null}
                            </div>
                            <Collapse
                              isOpen={this.state.collapse.includes("16")}
                              className="collapseTransition"
                            >
                              <Card style={{ border: "none" }}>
                                <CardBody style={{ border: "none" }}>
                                  <div>
                                    <ValidationTable
                                      data={
                                        this.state.recommendationData
                                          .conservative
                                      }
                                      valTableData={
                                        this.state.data.validationData
                                      }
                                      iconData={this.state.healthyIcondata}
                                      isResize={true}
                                      isLine={true}
                                      isIcon={true}
                                      valStatus={bvmStatus}
                                      valObjective="Enhanced"
                                      showValidationDetails={
                                        this.showValidationDetails
                                      }
                                    ></ValidationTable>
                                  </div>
                                </CardBody>
                              </Card>
                            </Collapse>
                          </div>
                        ) : null}
                        {this.state.isValidationGraphTable === false &&
                        this.state.data.validationData !== null &&
                        this.state.data.validationData !== undefined &&
                        this.state.data.validationData.perfType ===
                          "Conservative" &&
                        bvmStatus === "VALIDATED" ? (
                          <div id="tab17">
                            <div
                              onClick={() => {
                                this.toggleCollapse("17");
                              }}
                              className="collapsediv"
                            >
                              <div
                                id="collapse_head"
                                style={{ maxWidth: "29%" }}
                              >
                                <div className="arrow">
                                  {!this.state.collapse.includes("17")
                                    ? CLOSE
                                    : OPEN}
                                </div>
                                <div>
                                  <div id="overViewCollapseTitle">
                                    Validated Benefits & Drawbacks of
                                    Recommendation :{" "}
                                  </div>
                                  <div>
                                    <b>Enhanced</b>
                                  </div>
                                </div>
                              </div>
                              {!this.state.collapse.includes("17") ? (
                                <div className="overviewTextCompParent">
                                  {this.state.data.conservative !== undefined &&
                                  this.state.data.conservative
                                    .validation_summary.costSummaryText !==
                                    undefined &&
                                  this.state.data.conservative
                                    .validation_summary.costSummaryText !==
                                    null ? (
                                    <div className="maindiv-text-overview maindiv-text-overview-grad">
                                      <ResingBenefitTextComponent
                                        title={
                                          this.state.data.conservative
                                            .validation_summary.costSummaryText
                                        }
                                        data={
                                          this.state.data.conservative
                                            .validation_summary
                                            .costSummaryDescription
                                        }
                                        affectString={
                                          this.state.data.conservative
                                            .validation_summary.perfAffected
                                        }
                                        valResult={
                                          this.state.data.conservative
                                            .validation_summary
                                            .validation_cost_result
                                        }
                                        renderIcon={
                                          this.state.data.validationPerfType !==
                                            null &&
                                          this.state.data.validationPerfType ===
                                            "Conservative" &&
                                          this.state.data.bvmState !==
                                            "VALIDATING"
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                  ) : null}
                                  {this.state.data.conservative !== undefined &&
                                  this.state.data.conservative
                                    .validation_summary.perfSummaryText !==
                                    undefined &&
                                  this.state.data.conservative
                                    .validation_summary.perfSummaryText !==
                                    null ? (
                                    <div className="maindiv-text-overview maindiv-text-overview-grad">
                                      <ResingBenefitTextComponent
                                        data={
                                          this.state.data.conservative
                                            .validation_summary
                                            .perfSummaryDescription
                                        }
                                        title={
                                          this.state.data.conservative
                                            .validation_summary.perfSummaryText
                                        }
                                        affectString={
                                          this.state.data.conservative.summary
                                            .increasedCost
                                        }
                                        valResult={
                                          this.state.data.conservative
                                            .validation_summary
                                            .validation_perf_result
                                        }
                                        renderIcon={
                                          this.state.data.validationPerfType !==
                                            null &&
                                          this.state.data.validationPerfType ===
                                            "Conservative" &&
                                          this.state.data.bvmState !==
                                            "VALIDATING"
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                            <Collapse
                              isOpen={this.state.collapse.includes("17")}
                              className="collapseTransition"
                            >
                              <Card style={{ border: "none" }}>
                                <CardBody style={{ border: "none" }}>
                                  <div>
                                    <ValidationDetailsTable
                                      data={this.state.data.validationData}
                                      isValidationGraphTable={false}
                                      valSummary={
                                        this.state.data.conservative
                                          .validation_summary
                                      }
                                    />
                                  </div>
                                </CardBody>
                              </Card>
                            </Collapse>
                          </div>
                        ) : null}
                        {this.state.recommendationData.conservative !==
                          undefined &&
                        this.state.recommendationData.conservative
                          .isRecommendationSelected === true ? (
                          <div id="tab18">
                            <div
                              onClick={() => {
                                this.toggleCollapse("18");
                              }}
                              className="collapsediv"
                            >
                              <div id="collapse_head">
                                <div className="arrow">
                                  {!this.state.collapse.includes("18")
                                    ? CLOSE
                                    : OPEN}
                                </div>
                                <div id="overViewCollapseTitle">
                                  Resizing Status :{" "}
                                  <div
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {this.state.recommendationData.bvmState}{" "}
                                    {this.state.recommendationData.bvmState ===
                                    "OPTIMIZING" ? (
                                      <i
                                        style={{ margin: "auto 14px" }}
                                        className="fa fa-info-circle"
                                        aria-hidden="true"
                                        data-toggle="tooltip"
                                        data-placement="left"
                                        title="When manual resizing has been completed, please mark it as complete using Manage VMs page"
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              {!this.state.collapse.includes("18") &&
                              this.state.healthyIcondataStatus.length > 0 ? (
                                <div className="maindiv">
                                  <ColorComponent
                                    data={
                                      this.state.healthyIcondataStatus[0].cpu
                                    }
                                    isResize={true}
                                    isLine={true}
                                    showInfo={false}
                                    isIcon={true}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    showCostPerfMarker={true}
                                    fromOverview={true}
                                  ></ColorComponent>
                                  {this.state.entityType === "db" ? (
                                    <ColorComponent
                                      data={
                                        this.state.healthyIcondataStatus[0]
                                          .disks
                                      }
                                      isResize={true}
                                      isLine={true}
                                      showInfo={false}
                                      isIcon={true}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      fromOverview={true}
                                    ></ColorComponent>
                                  ) : null}
                                  <ColorComponent
                                    data={
                                      this.state.healthyIcondataStatus[0].memory
                                    }
                                    isResize={true}
                                    isLine={true}
                                    showInfo={false}
                                    isIcon={true}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    hostOnly={hostOnly}
                                    fromOverview={true}
                                  ></ColorComponent>
                                  <ColorComponent
                                    data={
                                      this.state.healthyIcondataStatus[0]
                                        .network
                                    }
                                    isResize={true}
                                    isLine={
                                      this.state.entityType === "db"
                                        ? false
                                        : true
                                    }
                                    showInfo={false}
                                    isIcon={true}
                                    isAsp={this.state.isAsp}
                                    entityType={this.state.entityType}
                                    fromOverview={true}
                                  ></ColorComponent>
                                  {this.state.entityType !== "db" ? (
                                    <ColorComponent
                                      data={
                                        this.state.healthyIcondataStatus[0]
                                          .disks
                                      }
                                      isResize={true}
                                      isLine={false}
                                      showInfo={false}
                                      isIcon={true}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      fromOverview={true}
                                    ></ColorComponent>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                            {this.state.healthyIcondataStatus.length > 0 ? (
                              <Collapse
                                isOpen={this.state.collapse.includes("18")}
                                className="collapseTransition"
                              >
                                <Card style={{ border: "none" }}>
                                  <CardBody style={{ border: "none" }}>
                                    <Recommendstatus
                                      data={
                                        this.state.recommendationData
                                          .conservative
                                      }
                                      iconData={
                                        this.state.healthyIcondataStatus
                                      }
                                      isResize={true}
                                      isLine={true}
                                      isIcon={true}
                                      isAsp={this.state.isAsp}
                                      entityType={this.state.entityType}
                                      providerName={this.state.providerName}
                                    ></Recommendstatus>
                                  </CardBody>
                                </Card>
                              </Collapse>
                            ) : null}
                          </div>
                        ) : null}

                        {this.state.data.conservative !== undefined &&
                        this.state.data.conservative.gaiInsightSummary !==
                          null ? (
                          <div id="tab27">
                            <div
                              color="primary"
                              onClick={() => {
                                this.toggleCollapse("27");
                              }}
                              className="collapsediv resize-collapsediv"
                            >
                              <div
                                id="collapse_head"
                                style={{ minHeight: "102px", maxWidth: "28%" }}
                              >
                                <div className="arrow">
                                  {!this.state.collapse.includes("27")
                                    ? CLOSE
                                    : OPEN}
                                </div>
                                <div id="overViewCollapseTitle">
                                  GAI Insight
                                </div>
                              </div>
                              {!this.state.collapse.includes("27") ? (
                                this.state.data.conservative !== undefined &&
                                this.state.data.conservative
                                  .gaiInsightSummary !== null ? (
                                  <div className="overviewTextCompParent w-50">
                                    <div className="maindiv-text-overview maindiv-text-overview-grad p-3 px-4 text-center">
                                      {
                                        this.state.data.conservative
                                          ?.gaiInsightSummary
                                      }
                                    </div>
                                  </div>
                                ) : null
                              ) : null}
                            </div>

                            <Collapse
                              isOpen={this.state.collapse.includes("27")}
                              className="collapseTransition"
                            >
                              <Card style={{ border: "none" }}>
                                <CardBody style={{ border: "none" }}>
                                  {this.state.data.conservative !== undefined &&
                                  this.state.data.conservative
                                    .gaiInsightDescription !== null ? (
                                    <div className="overviewTextCompParent w-75 mx-auto">
                                      <div className="maindiv-text-overview maindiv-text-overview-grad p-3 px-4">
                                        {
                                          this.state.data.conservative
                                            ?.gaiInsightDescription
                                        }
                                      </div>
                                    </div>
                                  ) : null}
                                </CardBody>
                              </Card>
                            </Collapse>
                          </div>
                        ) : null}
                      </TabPane>
                    </TabContent>
                  </div>
                )}

                {this.state.modal ? (
                  <div id="details">
                    {(() => {
                      switch (this.state.activeTab) {
                        case "1":
                          return (
                            <CostHealthModal
                              data={this.state.data.aggressive}
                              type={"Economical"}
                              isAsp={this.state.isAsp}
                              entityType={this.state.entityType}
                              userCurrency={this.state.data?.userCurrency}
                              providerName={this.state.providerName}
                            ></CostHealthModal>
                          );
                        case "2":
                          return (
                            <CostHealthModal
                              data={this.state.data.balanced}
                              type={"Balanced"}
                              isAsp={this.state.isAsp}
                              entityType={this.state.entityType}
                              userCurrency={this.state.data?.userCurrency}
                              providerName={this.state.providerName}
                            ></CostHealthModal>
                          );
                        case "3":
                          return (
                            <CostHealthModal
                              data={this.state.data.conservative}
                              type={"Enhanced"}
                              isAsp={this.state.isAsp}
                              entityType={this.state.entityType}
                              userCurrency={this.state.data?.userCurrency}
                              providerName={this.state.providerName}
                            ></CostHealthModal>
                          );
                      }
                    })()}
                  </div>
                ) : this.state.isValidationGraphTable ? (
                  <div id="details">
                    <ValidationDetailsTable
                      data={this.state.data.validationData}
                      isValidationGraphTable={this.state.isValidationGraphTable}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {this.state.errorPopup === true ? (
            <SerraError
              Errordata={this.state.errormessage}
              ErrorPopup={this.state.errorPopup}
              setErrorMethod={this.setErrorPopupState}
              isError={this.state.isErrorHeader}
            ></SerraError>
          ) : (
            ""
          )}
          {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}
        </div>
      </div>
    );
  }
}
export default Overview;
