import React, { Component } from "react";
import {
  Input,
  Button,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
} from "reactstrap";
import ReactGA from "react-ga4";
import Header from "../HeaderComponent/Header";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import SerraLoader from "../CommonComponent/Loader";
import SerraError from "../CommonComponent/Error";
import domtoimage from "dom-to-image";
import jstz from "jstz";
import {
  ColumnSeries,
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  Legend,
  Category,
  Tooltip,
  DataLabel,
  StackingBarSeries,
  Chart,
} from "@syncfusion/ej2-react-charts";
import { getStringDate, priceDisplay } from "../../Constants/CommonConstant";

class AssesmentReport extends Component {
  constructor(props) {
    super(props);
    this.handleNAvigatetoSummary = this.handleNAvigatetoSummary.bind(this);
    this.handleBacktoPPUvmlist = this.handleBacktoPPUvmlist.bind(this);
    this.tooglePDF = this.togglePDF.bind(this);
    this.handlePDF = this.handlePDF.bind(this);
    this.handleOverallSummary = this.handleOverallSummary.bind(this);
    this.handleIndividualSummary = this.handleIndividualSummary.bind(this);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);

    this.state = {
      currentDateTime: 0,
      ReportdataPayload:
        this.props.location.state.data !== undefined
          ? this.props.location.state.data
          : [],
      isAsp:
        this.props.location.state.isAsp !== undefined
          ? this.props.location.state.isAsp
          : false,
      entityType:
        this.props.location.state.entityType !== undefined
          ? this.props.location.state.entityType
          : "vm",
      listTb:
        this.props.location.state.listTb !== undefined
          ? this.props.location.state.listTb
          : "1",
      value: undefined,
      modalPDF: false,
      isOverall: false,
      isIndividual: false,
      errormessage: "",
      errorPopup: false,
      isFreeTrial: false,
      chartLeftImage: "",
      chartRightImage: "",
      resourceTypePalette: [
        "#C8FFFF",
        "#007FFF",
        "#C8C8C8",
        "#646464",
        "#9664FF",
        "#3200FF",
        "#00007F",
      ],
      costPalett: ["#FF9B29", "#84CD59", "#B2BB47", "#E0A835", "#008080"],
      perfPalett: ["#FF9B29", "#E0A835", "#B2BB47", "#84CD59", "#008080"],
      perfxAxis: {
        valueType: "Category",
        labelStyle: {
          size: "14px",
          fontWeight: "500",
          color: "#808080",
          fontFamily: "Poppins, sans-serif",
        },
      },
      perfyAxis: {
        labelFormat: "{value}%",
        labelStyle: {
          size: "14px",
          fontWeight: "500",
          color: "#808080",
          fontFamily: "Poppins, sans-serif",
        },
      },
      legendSettings: {
        visible: true,
        position: "Bottom",
        margin: { top: 20 },
        textStyle: {
          textOverflow: "Wrap",
          fontFamily: "Poppins, sans-serif",
          size: "14px",
        },
        alignment: "Center",
        toggleVisibility: true,
      },
      legendSettings1: {
        visible: false,
        position: "Bottom",
        margin: { top: 20 },
        alignment: "Center",
        toggleVisibility: false,
        fontFamily: "Poppins, sans-serif",
        size: "14px",
      },
      titleStyle: {
        color: "#393939",
        size: "14px",
        fontWeight: "500",
        location: { x: "277", y: "26.5" },
      },
      marker: {
        dataLabel: {
          position: "Auto",
          visible: true,
          font: {
            color: "white",
            size: "14px",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "500",
          },
        },
      },
      costPerfMarker: {
        dataLabel: {
          position: "Outer",
          visible: true,
          font: {
            color: "#393939",
            size: "14px",
            fontFamily: "Poppins, sans-serif",
            fontWeight: "500",
          },
        },
      },
      costxAxis: {
        valueType: "Category",
        labelStyle: {
          size: "14px",
          fontWeight: "500",
          color: "#808080",
          fontFamily: "Poppins, sans-serif",
        },
      },
      costyAxis: {
        labelStyle: {
          size: "14px",
          fontWeight: "500",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
      },
      chartTitleStyle: {
        color: "#393939",
        fontWeight: "500",
        size: "20px",
        fontFamily: "Poppins, sans-serif",
      },
      progressxAxis: {
        valueType: "Category",
        labelStyle: {
          size: "14px",
          fontWeight: "500",
          color: "#808080",
          fontFamily: "Poppins, sans-serif",
        },
      },
      progressyAxis: {
        labelFormat: "{value}%",
        minimum: 0,
        maximum: 100,
        interval: 25,
        labelStyle: {
          size: "12px",
          fontWeight: "400",
          color: "#393939",
          fontFamily: "Poppins, sans-serif",
        },
      },
    };
  }

  async componentDidMount() {
    ReactGA.initialize(message.MeasurementId);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Specific Report",
    });
    this.props.registerListners(true);
    console.log(this.state.ReportdataPayload);
    const payload = {
      recList: this.state.ReportdataPayload,
      duration: "current",
    };
    this.setState({ modalLoader: true });
    var reportUrl = this.state.isAsp
      ? Constdata.GETREPORTDATA_ASP_URL
      : Constdata.GETREPORTDATA_URL;
    if (this.state.entityType === "db") {
      reportUrl = Constdata.GETREPORTDATA_DB_URL;
    }
    fetch(reportUrl, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true && responseJson.data !== undefined) {
          this.setState({ providerName: responseJson.orgInfo.providerName });
          if (
            responseJson.data.vmCostGraph !== undefined &&
            responseJson.data.vmCostGraph.costChart !== undefined
          ) {
            let mxCost = Math.max.apply(
              Math,
              responseJson.data.vmCostGraph.costChart.map(function (o) {
                return o.cost;
              })
            );
            let interval = Math.round(mxCost / 4);
            let mxPerf = Math.max.apply(
              Math,
              responseJson.data.vmPerfGraph.perfChart.map(function (o) {
                return o.perf;
              })
            );
            let intervalPerf = Math.round(mxPerf / 4);
            await this.setState({
              costyAxis: {
                interval: interval,
                maximum: mxCost + interval,
                minimum: 0,
              },
              perfyAxis: {
                interval: intervalPerf,
                maximum: mxPerf + intervalPerf,
                minimum: 0,
              },
            });
          }

          //      Progress Chart
          let order = {
            percent_unanalyzed: "#C8FFFF",
            percent_analyzed: "#007FFF",
            percent_analysis_ineligible: "#C8C8C8",
            percent_analysis_error: "#646464",
            percent_validated: "#9664FF",
            percent_optimized: "#3200FF",
            percent_custom_sized: "#00007F",
          };

          let label = {
            percent_unanalyzed: "Unanalyzed",
            percent_analyzed: "Analyzed",
            percent_analysis_ineligible: "Analysis Ineligible",
            percent_analysis_error: "Analysis Issue",
            percent_validated: "Validated",
            percent_optimized: "Optimally Sized",
            percent_custom_sized: "Custom Sized",
          };

          if (this.state.isAsp || this.state.entityType === "db") {
            order = {
              percent_unanalyzed: "#C8FFFF",
              percent_analyzed: "#007FFF",
              percent_analysis_ineligible: "#C8C8C8",
              percent_analysis_error: "#646464",
              percent_optimized: "#3200FF",
              percent_custom_sized: "#00007F",
            };
          }

          let progressTitle = this.state.isAsp
            ? "Overall ASP Progress"
            : this.state.entityType === "db"
            ? "Overall DBaaS Progress"
            : "Overall VM Progress";

          let keysSorted = Object.keys(
            responseJson.data.vmProgressGraph.progressList[0]
          ).sort((a, b) => {
            return (
              Object.keys(order).indexOf(a) - Object.keys(order).indexOf(b)
            );
          });
          let fiterData = [];
          let data = responseJson.data.vmProgressGraph.progressList[0];

          keysSorted.map((value) => {
            if (
              parseInt(data[value]) !== 0 &&
              data[value] !== null &&
              value.startsWith("percent_")
            ) {
              let obj1 = {
                type: "StackingBar",
                dataSource: [
                  {
                    resourceName: data.resourceName,
                    [value]: data[value],
                    [`actual_${value}`]: data[`actual_${value}`],
                  },
                ],
                xName: "resourceName",
                yName: value,
                name: label[value],
                legendShape: "Rectangle",
                fill: order[value],
                marker: {
                  dataLabel: {
                    visible: true,
                    font: { size: "16px", fontFamily: " Poppins, sans-serif" },
                  },
                },
              };
              fiterData.push(obj1);
            }
          });

          Chart.Inject(
            Chart,
            StackingBarSeries,
            Category,
            Tooltip,
            Legend,
            DataLabel
          );

          let chart = new Chart({
            //Initializing Primary X Axis
            primaryXAxis: {
              valueType: "Category",
              labelStyle: {
                size: "16px",
                fontWeight: "500",
                color: "#808080",
                fontFamily: "Poppins, sans-serif",
              },
            },
            //Initializing Primary Y Axis
            primaryYAxis: {
              minimum: 0,
              maximum: 100,
              interval: 25,
              labelStyle: {
                size: "12px",
                fontWeight: "400",
                color: "#393939",
                fontFamily: "Poppins, sans-serif",
              },
              labelFormat: "{value}%",
            },
            chartArea: {
              border: {
                width: 1,
              },
            },
            series: fiterData,
            legendSettings: this.state.legendSettings,
            height: "300",
            title: progressTitle,
            titleStyle: this.state.chartTitleStyle,
            palettes: this.state.resourceTypePalette,
            pointRender: this.progressBarPointRenderer,
            axisLabelRender: this.axisLabelRender,
            marker: this.state.marker,
          });
          chart.appendTo("#Chart");

          //     End Progress Chart

          await this.setState({
            value: responseJson.data,
            modalLoader: false,
            isFreeTrial: responseJson.orgInfo.plan === "FREE_TRIAL",
            currentDateTime: responseJson.orgInfo.currentDateTime,
            loggedInUser: responseJson.orgInfo.loggedInUser,
          });
        } else {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.ServerError,
            isErrorHeader: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
        });
      });
  }

  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event });
    if (this.state.isRedirect) {
      this.props.processLogut();
      this.props.history.push("/");
    }
  };
  handleOverallSummary(e) {
    this.setState({
      isOverall: e.target.checked,
    });
  }
  handleIndividualSummary(e) {
    this.setState({
      isIndividual: e.target.checked,
    });
  }
  handlePDF = async () => {
    this.setState({ modalLoader: true });
    var timer = setInterval(() => {
      document.getElementById("LeftChart").click();
    }, 5000);
    let filename = "";
    const timezone = jstz.determine();
    const timezoneid = timezone.name();
    if (this.state.isOverall === true) {
      let chartLeftImage = "";
      await domtoimage
        .toPng(document.getElementById("costChart"), { quality: 1 })
        .then(function (dataUrl) {
          chartLeftImage = dataUrl;
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });

      this.chartLeftImage = chartLeftImage;
      let chartRightImage = "";
      await domtoimage
        .toPng(document.getElementById("perfChart"))
        .then(function (dataUrl) {
          chartRightImage = dataUrl;
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
      this.chartRightImage = chartRightImage;
    } else {
      this.chartRightImage = "";
      this.chartLeftImage = "";
    }
    const payload = {
      isSummaryPage: this.state.isOverall,
      isIndividualSummaryPage: this.state.isIndividual,
      recList: this.state.ReportdataPayload,
      duration: "current",
      userGtmId: timezoneid,
      leftChart: this.chartLeftImage,
      rightChart: this.chartRightImage,
    };
    var url = this.state.isAsp
      ? Constdata.GETPDFONREPORT_ASP_URL
      : Constdata.GETPDFONREPORT_URL;
    if (this.state.entityType === "db") {
      url = Constdata.GETPDFONREPORT_PDF_URL;
    }
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      responseType: "blob",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).catch((error) => {
      clearInterval(timer);
      this.setState({
        modalLoader: false,
        errorPopup: true,
        errormessage: message.CacheErrorText,
        isErrorHeader: false,
        isRedirect: false,
      });
    });
    if (response.status !== 500) {
      if (response.headers.get("Content-Disposition") === null) {
        clearInterval(timer);
        let js = await response.json();
        if (js.success === false) {
          this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: js.message,
            isErrorHeader: false,
            isRedirect: false,
          });
        }
      } else {
        filename = response.headers
          .get("Content-Disposition")
          .split("filename=")[1];
        let blob = await response.blob();
        if (blob !== undefined && blob !== null) {
          let url = window.URL.createObjectURL(blob);
          let a = document.getElementById("pdfid");
          a.href = url;
          a.download = filename;
          a.click();
          clearInterval(timer);
          this.setState({ modalLoader: false, modalPDF: false });
        } else {
          clearInterval(timer);
          this.setState({ modalLoader: false, modalPDF: false });
        }
      }
    } else {
      clearInterval(timer);
      this.setState({
        modalLoader: false,
        errorPopup: true,
        errormessage: message.CacheErrorText,
        isErrorHeader: false,
        isRedirect: false,
      });
    }
  };
  togglePDF = () => {
    this.setState({
      isIndividual: false,
      isOverall: false,
    });
    this.setState((prevState) => ({
      modalPDF: !prevState.modalPDF,
    }));
  };

  handleNAvigatetoSummary = (
    basicVmId,
    Vmname,
    analysisSeq,
    date,
    time,
    region,
    name,
    timetsamp,
    statusTimestamp,
    landOn
  ) => {
    const data = {
      vmname: Vmname,
      basicvmid: basicVmId,
      analysisseqnum: analysisSeq,
      progressnum: 0,
      providername: "Azure",
      orgname: "",
      accountName: name,
      region: region,
      analysisdatetimestamp: timetsamp,
      status_change_datetime: statusTimestamp,
    };
    var summaryPath = this.state.isAsp ? "/AspSummary" : "/summary";
    if (this.state.entityType === "db") {
      summaryPath = "/AspSummary";
    }
    this.props.history.push({
      pathname: summaryPath,
      state: {
        data: data,
        oldData: this.state.ReportdataPayload,
        lastView: "/report",
        key: this.props.location.state.UserAccount,
        landOn: landOn,
        isAsp: this.state.isAsp,
        entityType: this.state.entityType,
        listTb: this.state.listTb,
      },
    });
  };
  handleBacktoPPUvmlist = () => {
    this.props.history.push({
      pathname: "/manageOptimizations",
      state: { listTb: this.state.listTb },
    });
  };

  axisLabelRender = (args) => {
    if (!isNaN(args.value) && !isNaN(args.axis.endLabel)) {
      args.text = "";
    }
  };
  axisPointRender = (args) => {
    var isCost = args.series.properties.yName === "cost";
    if (args.point.x === "ECONOMICAL") {
      args.fill = isCost ? "#84CD59" : "#E0A835";
    }
    if (args.point.x === "BALANCED") {
      args.fill = isCost ? "#B2BB47" : "#B2BB47";
    }
    if (args.point.x === "ENHANCED") {
      args.fill = isCost ? "#E0A835" : "#84CD59";
    }
    if (args.point.x === "OPTIMALLY SIZED") {
      args.fill = isCost ? "#008080" : "#008080";
    }
    //        if(args.point.y == 0){
    //            args.cancel = true;
    //        } else {
    if (
      args.point.x === "CURRENT" ||
      args.point.x === "ECONOMICAL" ||
      args.point.x === "BALANCED" ||
      args.point.x === "ENHANCED" ||
      args.point.x === "OPTIMALLY SIZED"
    ) {
      if (isCost) {
        args.point.y = parseFloat(args.point.y).toFixed(2);
        args.point.text = priceDisplay(
          args.point.y,
          this.state.value?.userCurrency
        );
      }
    }
    //}
  };

  progressBarPointRenderer = (args) => {
    console.log("progressBarPointRenderer", args);
    if (args.point.y == 0) {
      args.cancel = true;
    } else {
      var text = "actual_" + args.series.properties.yName;
      if (
        args.series.properties.dataSource[0][text] !== undefined &&
        args.series.properties.dataSource[0][text] !== null
      ) {
        args.point.yValue = args.series.properties.dataSource[0][text];
      }
    }
  };

  render() {
    const Y = (
      <img
        className="no-box-sizing"
        src={require("../../images/ValidationResult_Y.png")}
        title="Good"
      />
    );
    const N = (
      <img
        className="no-box-sizing"
        src={require("../../images/ValidationResult_N.png")}
        title="Poor"
      />
    );
    const NA = (
      <img
        className="no-box-sizing-na"
        src={require("../../images/ValidationResult_NA.png")}
        title="Not Applicable"
      />
    );
    const M = (
      <img
        className="no-box-sizing"
        src={require("../../images/ValidationResult_M.png")}
        title="Fair"
      />
    );
    const S = (
      <img
        className="no-box-sizing"
        src={require("../../images/ValidationResult_Skipped.png")}
        title="Skipped"
      />
    );
    const Star = (
      <img
        className="no-box-sizing"
        src={require("../../images/ValidationResult_Star.png")}
        title="Insignificant Data"
      />
    );
    const Green = <img src={require("../../images/Green_01.png")} alt="" />;
    const Red = <img src={require("../../images/Red_01.png")} alt="" />;
    const Yellow = <img src={require("../../images/Yellow_01.png")} alt="" />;

    let costChartTitle = this.state.isAsp
      ? "Aggregate Monthly Cost"
      : "Aggregate Monthly Cost";
    let perfChartTitle = this.state.isAsp
      ? "Average Performance"
      : "Average Performance";
    let progressTitle = this.state.isAsp ? "Progress" : "Progress";
    let pgTtl = this.state.isAsp
      ? "Cost-Performance-Progress Report for ASP Rightsizing"
      : "Cost-Performance-Progress Report for VM Rightsizing";
    if (this.state.entityType === "db") {
      pgTtl = "Cost-Performance-Progress Report for DBaaS Rightsizing";
    }
    let resourceType = "VMs";
    switch (this.state.entityType) {
      case "asp":
        resourceType = "ASPs";
        break;
      case "db":
        resourceType = "DBs";
        break;
    }
    return (
      <div>
        <Header
          processLogout={this.props.processLogut}
          providerName={this.state.providerName}
          registerListners={this.props.registerListners}
          resourceType={resourceType}
          datetime={this.state.currentDateTime}
          loggedInUser={this.state.loggedInUser}
        ></Header>
        <div
          className="container-verf container-verf-padding"
          role="main"
          id="fillme"
          style={{ marginTop: "50px" }}
        >
          <div
            className="rowContainer pageBgWhite"
            style={{
              width: "100%",
              margin: "0px 0px 0px 0px",
              borderRadius: "10px",
            }}
          >
            <div className="container1 boxShadow">
              {/* <Row id="bottom_btn">
                            <Col style={{display: "flex", justifyContent: "flex-end", padding: "0px 0px 15px 0px"}}>
                            <Button id="PPU_list" style={{height: "44px"}} color="primary" onClick={this.togglePDF}>Save as PDF Report</Button>
                            <Button onClick={this.handleBacktoPPUvmlist} id="whiteBtn" style={{float: "left"}} color="primary" className="whiteBtn btn-close btn-detils" outline>Close</Button>
                            </Col>
                        </Row>*/}
              <Row style={{ alignItems: "center" }} className="pageBgHeader">
                <Col sm="12" style={{ display: "flex", alignItems: "center" }}>
                  <div id="spaceFiller" style={{ width: "10%" }}></div>
                  <div
                    style={{
                      width: "80%",
                      textAlign: "center",
                      color: "#ffffff",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    {pgTtl}
                  </div>
                  <div
                    style={{
                      width: "10%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <ButtonGroup>
                      <div
                        outline
                        onClick={this.togglePDF}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          style={{ margin: "5px" }}
                          src={require("../../images/PDF_Icon1.svg")}
                          title="Save as PDF Report"
                        />
                      </div>
                      <div
                        outline
                        onClick={this.handleBacktoPPUvmlist}
                        style={{ marginLeft: "5px", cursor: "pointer" }}
                      >
                        <img
                          src={require("../../images/Close_Icon1.svg")}
                          title="Close and go back to Manage Optimizations"
                        />
                      </div>
                    </ButtonGroup>
                  </div>
                </Col>
              </Row>
              <div
                id="list_account"
                style={{ width: "100%", justifyContent: "space-between" }}
              >
                <div
                  className="reportChart"
                  id="costChart"
                  style={{
                    padding: "0px",
                    width: "100%",
                    paddingBottom: "40px",
                  }}
                >
                  <div id="LeftChart">
                    <Row>
                      <Col style={{ padding: "10px" }}>
                        <span className="labelColor reportChartLabel">
                          {costChartTitle}
                        </span>
                      </Col>
                      <Col style={{ padding: "10px" }}>
                        <span className="labelColor reportChartLabel">
                          {perfChartTitle}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" style={{ height: "100%" }}>
                        <ChartComponent
                          id="cchart"
                          axisLabelRender={this.axisLabelRender}
                          pointRender={this.axisPointRender}
                          primaryXAxis={this.state.costxAxis}
                          primaryYAxis={this.state.costyAxis}
                          title=""
                          palettes={this.state.costPalett}
                          legendSettings={this.state.legendSettings1}
                        >
                          <Inject
                            services={[
                              ColumnSeries,
                              Legend,
                              DataLabel,
                              Category,
                            ]}
                          />
                          <SeriesCollectionDirective>
                            <SeriesDirective
                              dataSource={
                                this.state.value !== undefined
                                  ? this.state.value.vmCostGraph.costChart
                                  : []
                              }
                              xName="type"
                              yName="cost"
                              type="Column"
                              marker={this.state.costPerfMarker}
                            ></SeriesDirective>
                          </SeriesCollectionDirective>
                        </ChartComponent>
                      </Col>
                      <Col sm="6" style={{ height: "100%" }}>
                        <ChartComponent
                          id="pchart"
                          axisLabelRender={this.axisLabelRender}
                          pointRender={this.axisPointRender}
                          primaryXAxis={this.state.perfxAxis}
                          primaryYAxis={this.state.perfyAxis}
                          palettes={this.state.perfPalett}
                          title=""
                          legendSettings={this.state.legendSettings1}
                        >
                          <Inject
                            services={[
                              ColumnSeries,
                              Legend,
                              DataLabel,
                              Category,
                            ]}
                          />
                          <SeriesCollectionDirective>
                            <SeriesDirective
                              dataSource={
                                this.state.value !== undefined
                                  ? this.state.value.vmPerfGraph.perfChart
                                  : []
                              }
                              xName="type"
                              yName="perf"
                              type="Column"
                              marker={this.state.costPerfMarker}
                            ></SeriesDirective>
                          </SeriesCollectionDirective>
                        </ChartComponent>
                      </Col>
                    </Row>
                    <Row
                      style={{ paddingTop: "20px", justifyContent: "center" }}
                    >
                      <Col sm="9">
                        <div id="Chart"></div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <div id="chartReport"></div>
              <div style={{ padding: "20px 5px 20px 5px" }}>
                <div className="tableBgHeader">
                  <div
                    style={{
                      textAlign: "center",
                      color: "#ffffff",
                      fontSize: "22px",
                      paddingTop: "10px",
                      fontWeight: "700",
                    }}
                  >
                    Recommendation and Benefits Table
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      color: "#ffffff",
                      fontSize: "17px",
                      paddingBottom: "10px",
                    }}
                  >
                    {" "}
                    Costs assumptions:{" "}
                    {this.state.isAsp
                      ? message.ASPsRightsizingBenefitsMsg
                      : this.state.entityType === "db"
                      ? message.DBaasRightsizingBenefitsMsg
                      : message.VMsRightsizingBenefitsMsg}
                  </div>
                </div>
                <Table id="tableSizing" style={{ fontSize: "14px" }}>
                  <thead>
                    <tr>
                      <th rowSpan="2" style={{ width: "110px" }}>
                        {this.state.isAsp
                          ? "ASP"
                          : this.state.entityType === "db"
                          ? "Server Name_X_DB"
                          : "VM"}{" "}
                        Name
                      </th>
                      <th rowSpan="2" style={{ width: "110px" }}>
                        Metrics Data Source
                      </th>
                      <th rowSpan="2" style={{ width: "80px" }}>
                        {this.state.entityType === "db"
                          ? "Current Max Cost"
                          : this.state.entityType === "asp"
                          ? "Current Cost per ASP Instance"
                          : "Current Cost"}
                      </th>
                      <th colSpan="3" style={{ padding: "0px" }}>
                        Benefits & Drawbacks
                      </th>
                      <th rowSpan="2">Status</th>
                      <th rowSpan="2" style={{ width: "80px" }}>
                        When
                      </th>
                      <th rowSpan="2" style={{ width: "90px" }}>
                        Action
                      </th>
                    </tr>
                    <tr>
                      <th style={{ backgroundColor: "#E5E5E5" }}>
                        Economical
                        <label style={{ fontWeight: "300", fontSize: "14px" }}>
                          (&eta;90)
                        </label>
                      </th>
                      <th style={{ backgroundColor: "#E5E5E5" }}>
                        Balanced
                        <label style={{ fontWeight: "300", fontSize: "14px" }}>
                          (&eta;95)
                        </label>
                      </th>
                      <th style={{ backgroundColor: "#E5E5E5" }}>
                        Enhanced
                        <label style={{ fontWeight: "300", fontSize: "14px" }}>
                          (&eta;100)
                        </label>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.value !== undefined
                      ? this.state.value.summaryList.map((Vm, i) => {
                          let objective = "";
                          let statusLandOn = "analysis";
                          let isValidated =
                            Vm.validationPerfType === null ||
                            Vm.bvmState === "VALIDATING" ||
                            Vm.bvmState === "VALIDATION ERROR"
                              ? false
                              : true;
                          let agg_validated =
                            isValidated &&
                            Vm.validationPerfType === "Aggressive"
                              ? true
                              : false;
                          let bal_validated =
                            isValidated && Vm.validationPerfType === "Balanced"
                              ? true
                              : false;
                          let con_validated =
                            isValidated &&
                            Vm.validationPerfType === "Conservative"
                              ? true
                              : false;
                          let isResized = false;
                          let isValidating =
                            Vm.validationPerfType !== null &&
                            Vm.validationPerfType !== undefined
                              ? true
                              : false;
                          if (
                            Vm.bvmState === "VALIDATED" ||
                            Vm.bvmState === "VALIDATING" ||
                            Vm.bvmState === "VALIDATION ERROR"
                          ) {
                            statusLandOn = "validation";
                            if (Vm.bvmState === "VALIDATION ERROR") {
                              statusLandOn = null;
                            }

                            objective =
                              Vm.validationPerfType === "Aggressive"
                                ? "Economical"
                                : Vm.validationPerfType === "Conservative"
                                ? "Enhanced"
                                : Vm.validationPerfType;
                          } else if (
                            Vm.bvmState === "OPTIMALLY SIZED" ||
                            Vm.bvmState === "CUSTOM-SIZED" ||
                            Vm.bvmState === "OPTIMIZING"
                          ) {
                            objective =
                              Vm.resizeperftype === "Aggressive"
                                ? "Economical"
                                : Vm.resizeperftype === "Conservative"
                                ? "Enhanced"
                                : Vm.resizeperftype;
                            statusLandOn = "resize";
                            isResized = true;
                          }
                          let agg1_validated =
                            isValidating &&
                            Vm.validationPerfType === "Aggressive"
                              ? true
                              : false;
                          let bal1_validated =
                            isValidating && Vm.validationPerfType === "Balanced"
                              ? true
                              : false;
                          let con1_validated =
                            isValidating &&
                            Vm.validationPerfType === "Conservative"
                              ? true
                              : false;
                          let agg_resized =
                            isResized && Vm.resizeperftype === "Aggressive"
                              ? true
                              : false;
                          let bal_resized =
                            isResized && Vm.resizeperftype === "Balanced"
                              ? true
                              : false;
                          let con_resized =
                            isResized && Vm.resizeperftype === "Conservative"
                              ? true
                              : false;
                          let agg_costSummaryText = agg_validated
                            ? Vm.aggressive_validation_summary.costSummaryText
                            : Vm.aggressive_summary.costSummaryText;
                          let agg_perfAffected = agg_validated
                            ? Vm.aggressive_validation_summary.perfAffected
                            : null;
                          let agg_perfSummaryText = agg_validated
                            ? Vm.aggressive_validation_summary.perfSummaryText
                            : Vm.aggressive_summary.perfSummaryText;
                          let agg_increasedCost = agg_validated
                            ? Vm.aggressive_validation_summary.increasedCost
                            : null;
                          let agg_recommendationText = agg_validated
                            ? Vm.aggressive_validation_summary
                                .recommendationText
                            : Vm.aggressive_summary.recommendationText;

                          let bal_costSummaryText = bal_validated
                            ? Vm.balanced_validation_summary.costSummaryText
                            : Vm.balanced_summary.costSummaryText;
                          let bal_perfAffected = bal_validated
                            ? Vm.balanced_validation_summary.perfAffected
                            : null;
                          let bal_perfSummaryText = bal_validated
                            ? Vm.balanced_validation_summary.perfSummaryText
                            : Vm.balanced_summary.perfSummaryText;
                          let bal_increasedCost = bal_validated
                            ? Vm.balanced_validation_summary.increasedCost
                            : null;
                          let bal_recommendationText = bal_validated
                            ? Vm.balanced_validation_summary.recommendationText
                            : Vm.balanced_summary.recommendationText;

                          let con_costSummaryText = con_validated
                            ? Vm.conservative_validation_summary.costSummaryText
                            : Vm.conservative_summary.costSummaryText;
                          let con_perfAffected = con_validated
                            ? Vm.conservative_validation_summary.perfAffected
                            : null;
                          let con_perfSummaryText = con_validated
                            ? Vm.conservative_validation_summary.perfSummaryText
                            : Vm.conservative_summary.perfSummaryText;
                          let con_increasedCost = con_validated
                            ? Vm.conservative_validation_summary.increasedCost
                            : null;
                          let con_recommendationText = con_validated
                            ? Vm.conservative_validation_summary
                                .recommendationText
                            : Vm.conservative_summary.recommendationText;

                          let platform =
                            Vm.metricsPlatform === "Host"
                              ? "(Only Host Metrics Used)"
                              : "";
                          let metricDtAvlDiv = <div></div>;
                          /*if( Vm.metricDataAvalability !== undefined && Vm.metricDataAvalability !== null && Vm.metricDataAvalability !== ""){
                                                        metricDtAvlDiv = (<div style={{marginLeft:"5px"}}><i className="fa fa-exclamation-circle" aria-hidden="true" data-toggle="tooltip" data-placement="right" title={Vm.metricDataAvalability} /></div>);
                                                    }*/
                          const transformedMetricDataDurationUsed =
                            Vm.metricDataDurationUsed !== null &&
                            Vm.metricDataDurationUsed
                              .replace(
                                /Metric Duration: (\d+) days/,
                                "Duration $1d"
                              )
                              .replace(
                                /Collection Fidelity: (\d+) min/,
                                "Fidelity $1min"
                              );
                          return (
                            <tr
                              id="list_tablerow"
                              style={{
                                textAlign: "center",
                                backgroundColor: "white",
                              }}
                            >
                              <td
                                className="textfieldCentered"
                                key={Vm.basicvm_id}
                                style={{ fontWeight: "600" }}
                              >
                                <div className="textfieldCentered">
                                  <Row>
                                    <Col sm="12" style={{ padding: "0px" }}>
                                      <div
                                        style={{
                                          display: "inline-flex",
                                          gap: "8px",
                                        }}
                                      >
                                        <div>{Vm.name}</div>
                                        {Vm.isSameMetricPlatform === false &&
                                        this.state.entityType === "vm" ? (
                                          <i
                                            className="fa fa-info-circle"
                                            aria-hidden="true"
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title={message.ChangedMatrics}
                                          />
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </td>
                              <td className="textfieldCentered">
                                {Vm.accountName}
                              </td>
                              <td className="textfieldCentered">
                                <div style={{ display: "inline-flex" }}>
                                  <div>
                                    {priceDisplay(
                                      Vm.currentCost,
                                      this.state.value?.userCurrency
                                    )}
                                    /month
                                  </div>{" "}
                                  {Vm.priceChangeMsg !== null ? (
                                    <div
                                      style={{
                                        textAlign: "right",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      <i
                                        style={{ marginRight: "4px" }}
                                        className="fa fa-info-circle"
                                        aria-hidden="true"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title={Vm.priceChangeMsg}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </td>
                              <td
                                className={
                                  agg_resized || agg1_validated
                                    ? "textfield bgColor"
                                    : "textfield"
                                }
                              >
                                {agg_costSummaryText !== null ||
                                (agg_perfAffected !== null &&
                                  agg_perfAffected !== "") ? (
                                  <Row style={{ textAlign: "center" }}>
                                    <Col
                                      sm={agg_validated ? "10" : "12"}
                                      style={{ padding: "0px" }}
                                    >
                                      <div>
                                        <a
                                          href="#"
                                          style={{ fontSize: "14px" }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.handleNAvigatetoSummary(
                                              Vm.basicVMID,
                                              Vm.name,
                                              Vm.analysisSeqNum,
                                              Vm.analysisDate,
                                              Vm.analysisTime,
                                              Vm.region,
                                              Vm.accountName,
                                              Vm.analysisDatetimestamp,
                                              Vm.status_change_datetime,
                                              agg_validated
                                                ? "valsummary"
                                                : "analysisSummary"
                                            );
                                          }}
                                        >
                                          {agg_costSummaryText}
                                        </a>
                                      </div>
                                      <div>
                                        <a
                                          href="#"
                                          style={{ fontSize: "14px" }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.handleNAvigatetoSummary(
                                              Vm.basicVMID,
                                              Vm.name,
                                              Vm.analysisSeqNum,
                                              Vm.analysisDate,
                                              Vm.analysisTime,
                                              Vm.region,
                                              Vm.accountName,
                                              Vm.analysisDatetimestamp,
                                              Vm.status_change_datetime,
                                              agg_validated
                                                ? "valsummary"
                                                : "analysisSummary"
                                            );
                                          }}
                                        >
                                          {agg_perfAffected}
                                        </a>
                                      </div>
                                    </Col>
                                    {agg_costSummaryText !== null ||
                                    (agg_perfAffected !== null &&
                                      agg_perfAffected !== "") ? (
                                      <Col
                                        sm={agg_validated ? "2" : "0"}
                                        style={{
                                          alignSelf: "center",
                                          padding: "0px",
                                        }}
                                      >
                                        {Vm.validationPerfType !== null &&
                                        Vm.validationPerfType ===
                                          "Aggressive" &&
                                        Vm.bvmState !== "VALIDATING"
                                          ? Vm.aggressive_validation_summary
                                              .validation_cost_result === "Y"
                                            ? Y
                                            : Vm.aggressive_validation_summary
                                                .validation_cost_result === "N"
                                            ? N
                                            : Vm.aggressive_validation_summary
                                                .validation_cost_result ===
                                              "N/A"
                                            ? NA
                                            : Vm.aggressive_validation_summary
                                                .validation_cost_result === "M"
                                            ? M
                                            : Vm.aggressive_validation_summary
                                                .validation_cost_result === "*"
                                            ? Star
                                            : S
                                          : ""}
                                      </Col>
                                    ) : (
                                      ""
                                    )}
                                  </Row>
                                ) : null}
                                {Vm.isResourceChanged === true ? (
                                  <div style={{ textAlign: "right" }}>
                                    <i
                                      style={{ marginRight: "4px" }}
                                      className="fa fa-info-circle"
                                      aria-hidden="true"
                                      data-toggle="tooltip"
                                      data-placement="right"
                                      title={
                                        isValidated
                                          ? message.VMRersourceChangedFLagInfoTextValidated
                                          : this.state.isAsp
                                          ? message.ASPRersourceChangedFLagInfoTextAnalyzed
                                          : this.state.entityType === "db"
                                          ? message.DBRersourceChangedFLagInfoTextAnalyzed
                                          : message.VMRersourceChangedFLagInfoTextAnalyzed
                                      }
                                    />
                                  </div>
                                ) : agg_costSummaryText !== null ||
                                  (agg_perfAffected !== null &&
                                    agg_perfAffected !== "") ? (
                                  <br></br>
                                ) : null}

                                <Row
                                  style={{
                                    textAlign: "center",
                                    paddingBottom: "20px",
                                  }}
                                >
                                  <Col
                                    sm={agg_validated ? "10" : "12"}
                                    style={{ padding: "0px" }}
                                  >
                                    <div>
                                      <a
                                        href="#"
                                        style={{ fontSize: "14px" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleNAvigatetoSummary(
                                            Vm.basicVMID,
                                            Vm.name,
                                            Vm.analysisSeqNum,
                                            Vm.analysisDate,
                                            Vm.analysisTime,
                                            Vm.region,
                                            Vm.accountName,
                                            Vm.analysisDatetimestamp,
                                            Vm.status_change_datetime,
                                            agg_validated
                                              ? "valsummary"
                                              : "analysisSummary"
                                          );
                                        }}
                                      >
                                        {agg_perfSummaryText}
                                      </a>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        style={{ fontSize: "14px" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleNAvigatetoSummary(
                                            Vm.basicVMID,
                                            Vm.name,
                                            Vm.analysisSeqNum,
                                            Vm.analysisDate,
                                            Vm.analysisTime,
                                            Vm.region,
                                            Vm.accountName,
                                            Vm.analysisDatetimestamp,
                                            Vm.status_change_datetime,
                                            agg_validated
                                              ? "valsummary"
                                              : "analysisSummary"
                                          );
                                        }}
                                      >
                                        {agg_increasedCost}
                                      </a>
                                    </div>
                                  </Col>
                                  {agg_perfSummaryText !== null ||
                                  (agg_increasedCost !== null &&
                                    agg_increasedCost !== "") ? (
                                    <Col
                                      sm={agg_validated ? "2" : "0"}
                                      style={{
                                        alignSelf: "center",
                                        padding: "0px",
                                      }}
                                    >
                                      {Vm.validationPerfType !== null &&
                                      Vm.validationPerfType === "Aggressive" &&
                                      Vm.bvmState !== "VALIDATING"
                                        ? Vm.aggressive_validation_summary
                                            .validation_perf_result === "Y"
                                          ? Y
                                          : Vm.aggressive_validation_summary
                                              .validation_perf_result === "N"
                                          ? N
                                          : Vm.aggressive_validation_summary
                                              .validation_perf_result === "N/A"
                                          ? NA
                                          : Vm.aggressive_validation_summary
                                              .validation_perf_result === "M"
                                          ? M
                                          : Vm.aggressive_validation_summary
                                              .validation_perf_result === "*"
                                          ? Star
                                          : S
                                        : ""}
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                </Row>
                                {/* <Row style={{ textAlign: "center" }}>
                                  <Col
                                    sm={agg_validated ? "10" : "12"}
                                    style={{ padding: "0px" }}
                                  >
                                    <div>
                                      <a
                                        href="#"
                                        style={{ fontSize: "14px" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleNAvigatetoSummary(
                                            Vm.basicVMID,
                                            Vm.name,
                                            Vm.analysisSeqNum,
                                            Vm.analysisDate,
                                            Vm.analysisTime,
                                            Vm.region,
                                            Vm.accountName,
                                            Vm.analysisDatetimestamp,
                                            Vm.status_change_datetime,
                                            agg_validated
                                              ? "valsummary"
                                              : "analysisSummary"
                                          );
                                        }}
                                      >
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: agg_recommendationText
                                              ? agg_recommendationText.replace(
                                                  /->/g,
                                                  "<span style='white-space:nowrap'>-></span>"
                                                )
                                              : agg_recommendationText,
                                          }}
                                        ></div>
                                      </a>
                                    </div>
                                    <div>
                                      <a
                                        href="#"
                                        style={{ fontSize: "14px" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleNAvigatetoSummary(
                                            Vm.basicVMID,
                                            Vm.name,
                                            Vm.analysisSeqNum,
                                            Vm.analysisDate,
                                            Vm.analysisTime,
                                            Vm.region,
                                            Vm.accountName,
                                            Vm.analysisDatetimestamp,
                                            Vm.status_change_datetime,
                                            agg_validated
                                              ? "valsummary"
                                              : "analysisSummary"
                                          );
                                        }}
                                      >
                                        {agg_increasedCost}
                                      </a>
                                    </div>
                                  </Col>
                                </Row> */}
                              </td>
                              <td
                                className={
                                  bal_resized || bal1_validated
                                    ? "textfield bgColor"
                                    : "textfield"
                                }
                              >
                                <Row style={{ textAlign: "center" }}>
                                  <Col
                                    sm={bal_validated ? "10" : "12"}
                                    style={{ padding: "0px" }}
                                  >
                                    <div>
                                      <a
                                        href="#"
                                        style={{ fontSize: "14px" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleNAvigatetoSummary(
                                            Vm.basicVMID,
                                            Vm.name,
                                            Vm.analysisSeqNum,
                                            Vm.analysisDate,
                                            Vm.analysisTime,
                                            Vm.region,
                                            Vm.accountName,
                                            Vm.analysisDatetimestamp,
                                            Vm.status_change_datetime,
                                            bal_validated
                                              ? "valsummary"
                                              : "analysisSummary"
                                          );
                                        }}
                                      >
                                        {bal_costSummaryText}
                                      </a>
                                    </div>
                                    <div>
                                      <a
                                        href="#"
                                        style={{ fontSize: "14px" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleNAvigatetoSummary(
                                            Vm.basicVMID,
                                            Vm.name,
                                            Vm.analysisSeqNum,
                                            Vm.analysisDate,
                                            Vm.analysisTime,
                                            Vm.region,
                                            Vm.accountName,
                                            Vm.analysisDatetimestamp,
                                            Vm.status_change_datetime,
                                            bal_validated
                                              ? "valsummary"
                                              : "analysisSummary"
                                          );
                                        }}
                                      >
                                        {bal_perfAffected}
                                      </a>
                                    </div>
                                  </Col>
                                  {bal_costSummaryText !== null ||
                                  (bal_perfAffected !== null &&
                                    bal_perfAffected !== "") ? (
                                    <Col
                                      sm={bal_validated ? "2" : "0"}
                                      style={{
                                        alignSelf: "center",
                                        padding: "0px",
                                      }}
                                    >
                                      {Vm.validationPerfType !== null &&
                                      Vm.validationPerfType === "Balanced" &&
                                      Vm.bvmState !== "VALIDATING"
                                        ? Vm.balanced_validation_summary
                                            .validation_cost_result === "Y"
                                          ? Y
                                          : Vm.balanced_validation_summary
                                              .validation_cost_result === "N"
                                          ? N
                                          : Vm.balanced_validation_summary
                                              .validation_cost_result === "N/A"
                                          ? NA
                                          : Vm.balanced_validation_summary
                                              .validation_cost_result === "M"
                                          ? M
                                          : Vm.balanced_validation_summary
                                              .validation_cost_result === "*"
                                          ? Star
                                          : S
                                        : ""}
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                </Row>
                                {Vm.isResourceChanged === true ? (
                                  <div style={{ textAlign: "right" }}>
                                    <i
                                      style={{ marginRight: "4px" }}
                                      className="fa fa-info-circle"
                                      aria-hidden="true"
                                      data-toggle="tooltip"
                                      data-placement="right"
                                      title={
                                        isValidated
                                          ? message.VMRersourceChangedFLagInfoTextValidated
                                          : this.state.isAsp
                                          ? message.ASPRersourceChangedFLagInfoTextAnalyzed
                                          : this.state.entityType === "db"
                                          ? message.DBRersourceChangedFLagInfoTextAnalyzed
                                          : message.VMRersourceChangedFLagInfoTextAnalyzed
                                      }
                                    />
                                  </div>
                                ) : bal_costSummaryText !== null ||
                                  (bal_perfAffected !== null &&
                                    bal_perfAffected !== "") ? (
                                  <br></br>
                                ) : null}

                                <Row
                                  style={{
                                    textAlign: "center",
                                    paddingBottom: "20px",
                                  }}
                                >
                                  <Col
                                    sm={bal_validated ? "10" : "12"}
                                    style={{ padding: "0px" }}
                                  >
                                    <div>
                                      <a
                                        href="#"
                                        style={{ fontSize: "14px" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleNAvigatetoSummary(
                                            Vm.basicVMID,
                                            Vm.name,
                                            Vm.analysisSeqNum,
                                            Vm.analysisDate,
                                            Vm.analysisTime,
                                            Vm.region,
                                            Vm.accountName,
                                            Vm.analysisDatetimestamp,
                                            Vm.status_change_datetime,
                                            bal_validated
                                              ? "valsummary"
                                              : "analysisSummary"
                                          );
                                        }}
                                      >
                                        {bal_perfSummaryText}
                                      </a>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        style={{ fontSize: "14px" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleNAvigatetoSummary(
                                            Vm.basicVMID,
                                            Vm.name,
                                            Vm.analysisSeqNum,
                                            Vm.analysisDate,
                                            Vm.analysisTime,
                                            Vm.region,
                                            Vm.accountName,
                                            Vm.analysisDatetimestamp,
                                            Vm.status_change_datetime,
                                            bal_validated
                                              ? "valsummary"
                                              : "analysisSummary"
                                          );
                                        }}
                                      >
                                        {bal_increasedCost}
                                      </a>
                                    </div>
                                  </Col>
                                  {bal_perfSummaryText !== null ||
                                  (bal_increasedCost !== null &&
                                    bal_increasedCost !== "") ? (
                                    <Col
                                      sm={bal_validated ? "2" : "0"}
                                      style={{
                                        alignSelf: "center",
                                        padding: "0px",
                                      }}
                                    >
                                      {Vm.validationPerfType !== null &&
                                      Vm.validationPerfType === "Balanced" &&
                                      Vm.bvmState !== "VALIDATING"
                                        ? Vm.balanced_validation_summary
                                            .validation_perf_result === "Y"
                                          ? Y
                                          : Vm.balanced_validation_summary
                                              .validation_perf_result === "N"
                                          ? N
                                          : Vm.balanced_validation_summary
                                              .validation_perf_result === "N/A"
                                          ? NA
                                          : Vm.balanced_validation_summary
                                              .validation_perf_result === "M"
                                          ? M
                                          : Vm.balanced_validation_summary
                                              .validation_perf_result === "*"
                                          ? Star
                                          : S
                                        : ""}
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                </Row>
                                {/* <Row style={{ textAlign: "center" }}>
                                  <Col
                                    sm={bal_validated ? "10" : "12"}
                                    style={{ padding: "0px" }}
                                  >
                                    <div>
                                      <a
                                        href="#"
                                        style={{ fontSize: "14px" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleNAvigatetoSummary(
                                            Vm.basicVMID,
                                            Vm.name,
                                            Vm.analysisSeqNum,
                                            Vm.analysisDate,
                                            Vm.analysisTime,
                                            Vm.region,
                                            Vm.accountName,
                                            Vm.analysisDatetimestamp,
                                            Vm.status_change_datetime,
                                            bal_validated
                                              ? "valsummary"
                                              : "analysisSummary"
                                          );
                                        }}
                                      >
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: bal_recommendationText
                                              ? bal_recommendationText.replace(
                                                  /->/g,
                                                  "<span style='white-space:nowrap'>-></span>"
                                                )
                                              : bal_recommendationText,
                                          }}
                                        ></div>
                                      </a>
                                    </div>
                                    <div>
                                      <a
                                        href="#"
                                        style={{ fontSize: "14px" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleNAvigatetoSummary(
                                            Vm.basicVMID,
                                            Vm.name,
                                            Vm.analysisSeqNum,
                                            Vm.analysisDate,
                                            Vm.analysisTime,
                                            Vm.region,
                                            Vm.accountName,
                                            Vm.analysisDatetimestamp,
                                            Vm.status_change_datetime,
                                            bal_validated
                                              ? "valsummary"
                                              : "analysisSummary"
                                          );
                                        }}
                                      >
                                        {bal_increasedCost}
                                      </a>
                                    </div>
                                  </Col>
                                </Row> */}
                              </td>
                              <td
                                className={
                                  con_resized || con1_validated
                                    ? "textfield bgColor"
                                    : "textfield"
                                }
                              >
                                <Row style={{ textAlign: "center" }}>
                                  <Col
                                    sm={con_validated ? "10" : "12"}
                                    style={{ padding: "0px" }}
                                  >
                                    <div>
                                      <a
                                        href="#"
                                        style={{ fontSize: "14px" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleNAvigatetoSummary(
                                            Vm.basicVMID,
                                            Vm.name,
                                            Vm.analysisSeqNum,
                                            Vm.analysisDate,
                                            Vm.analysisTime,
                                            Vm.region,
                                            Vm.accountName,
                                            Vm.analysisDatetimestamp,
                                            Vm.status_change_datetime,
                                            con_validated
                                              ? "valsummary"
                                              : "analysisSummary"
                                          );
                                        }}
                                      >
                                        {con_costSummaryText}
                                      </a>
                                    </div>
                                    <div>
                                      <a
                                        href="#"
                                        style={{ fontSize: "14px" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleNAvigatetoSummary(
                                            Vm.basicVMID,
                                            Vm.name,
                                            Vm.analysisSeqNum,
                                            Vm.analysisDate,
                                            Vm.analysisTime,
                                            Vm.region,
                                            Vm.accountName,
                                            Vm.analysisDatetimestamp,
                                            Vm.status_change_datetime,
                                            con_validated
                                              ? "valsummary"
                                              : "analysisSummary"
                                          );
                                        }}
                                      >
                                        {con_perfAffected}
                                      </a>
                                    </div>
                                  </Col>
                                  {con_costSummaryText !== null ||
                                  (con_perfAffected !== null &&
                                    con_perfAffected !== "") ? (
                                    <Col
                                      sm={con_validated ? "2" : "0"}
                                      style={{
                                        alignSelf: "center",
                                        padding: "0px",
                                      }}
                                    >
                                      {Vm.validationPerfType !== null &&
                                      Vm.validationPerfType ===
                                        "Conservative" &&
                                      Vm.bvmState !== "VALIDATING"
                                        ? Vm.conservative_validation_summary
                                            .validation_cost_result === "Y"
                                          ? Y
                                          : Vm.conservative_validation_summary
                                              .validation_cost_result === "N"
                                          ? N
                                          : Vm.conservative_validation_summary
                                              .validation_cost_result === "N/A"
                                          ? NA
                                          : Vm.conservative_validation_summary
                                              .validation_cost_result === "M"
                                          ? M
                                          : Vm.conservative_validation_summary
                                              .validation_cost_result === "*"
                                          ? Star
                                          : S
                                        : ""}
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                </Row>
                                {Vm.isResourceChanged === true ? (
                                  <div style={{ textAlign: "right" }}>
                                    <i
                                      style={{ marginRight: "4px" }}
                                      className="fa fa-info-circle"
                                      aria-hidden="true"
                                      data-toggle="tooltip"
                                      data-placement="right"
                                      title={
                                        isValidated
                                          ? message.VMRersourceChangedFLagInfoTextValidated
                                          : this.state.isAsp
                                          ? message.ASPRersourceChangedFLagInfoTextAnalyzed
                                          : this.state.entityType === "db"
                                          ? message.DBRersourceChangedFLagInfoTextAnalyzed
                                          : message.VMRersourceChangedFLagInfoTextAnalyzed
                                      }
                                    />
                                  </div>
                                ) : con_costSummaryText !== null ||
                                  (con_perfAffected !== null &&
                                    con_perfAffected !== "") ? (
                                  <br></br>
                                ) : null}

                                <Row
                                  style={{
                                    textAlign: "center",
                                    paddingBottom: "20px",
                                  }}
                                >
                                  <Col
                                    sm={con_validated ? "10" : "12"}
                                    style={{ padding: "0px" }}
                                  >
                                    <div>
                                      <a
                                        href="#"
                                        style={{ fontSize: "14px" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleNAvigatetoSummary(
                                            Vm.basicVMID,
                                            Vm.name,
                                            Vm.analysisSeqNum,
                                            Vm.analysisDate,
                                            Vm.analysisTime,
                                            Vm.region,
                                            Vm.accountName,
                                            Vm.analysisDatetimestamp,
                                            Vm.status_change_datetime,
                                            con_validated
                                              ? "valsummary"
                                              : "analysisSummary"
                                          );
                                        }}
                                      >
                                        {con_perfSummaryText}
                                      </a>
                                    </div>

                                    <div>
                                      <a
                                        href="#"
                                        style={{ fontSize: "14px" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleNAvigatetoSummary(
                                            Vm.basicVMID,
                                            Vm.name,
                                            Vm.analysisSeqNum,
                                            Vm.analysisDate,
                                            Vm.analysisTime,
                                            Vm.region,
                                            Vm.accountName,
                                            Vm.analysisDatetimestamp,
                                            Vm.status_change_datetime,
                                            con_validated
                                              ? "valsummary"
                                              : "analysisSummary"
                                          );
                                        }}
                                      >
                                        {con_increasedCost}
                                      </a>
                                    </div>
                                  </Col>
                                  {con_perfSummaryText !== null ||
                                  (con_increasedCost !== null &&
                                    con_increasedCost !== "") ? (
                                    <Col
                                      sm={con_validated ? "2" : "0"}
                                      style={{
                                        alignSelf: "center",
                                        padding: "0px",
                                      }}
                                    >
                                      {Vm.validationPerfType !== null &&
                                      Vm.validationPerfType ===
                                        "Conservative" &&
                                      Vm.bvmState !== "VALIDATING"
                                        ? Vm.conservative_validation_summary
                                            .validation_perf_result === "Y"
                                          ? Y
                                          : Vm.conservative_validation_summary
                                              .validation_perf_result === "N"
                                          ? N
                                          : Vm.conservative_validation_summary
                                              .validation_perf_result === "N/A"
                                          ? NA
                                          : Vm.conservative_validation_summary
                                              .validation_perf_result === "M"
                                          ? M
                                          : Vm.conservative_validation_summary
                                              .validation_perf_result === "*"
                                          ? Star
                                          : S
                                        : ""}
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                </Row>
                                {/* <Row style={{ textAlign: "center" }}>
                                  <Col
                                    sm={con_validated ? "10" : "12"}
                                    style={{ padding: "0px" }}
                                  >
                                    <div>
                                      <a
                                        href="#"
                                        style={{ fontSize: "14px" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleNAvigatetoSummary(
                                            Vm.basicVMID,
                                            Vm.name,
                                            Vm.analysisSeqNum,
                                            Vm.analysisDate,
                                            Vm.analysisTime,
                                            Vm.region,
                                            Vm.accountName,
                                            Vm.analysisDatetimestamp,
                                            Vm.status_change_datetime,
                                            con_validated
                                              ? "valsummary"
                                              : "analysisSummary"
                                          );
                                        }}
                                      >
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: con_recommendationText
                                              ? con_recommendationText.replace(
                                                  /->/g,
                                                  "<span style='white-space:nowrap'>-></span>"
                                                )
                                              : con_recommendationText,
                                          }}
                                        ></div>
                                      </a>
                                    </div>
                                    <div>
                                      <a
                                        href="#"
                                        style={{ fontSize: "14px" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.handleNAvigatetoSummary(
                                            Vm.basicVMID,
                                            Vm.name,
                                            Vm.analysisSeqNum,
                                            Vm.analysisDate,
                                            Vm.analysisTime,
                                            Vm.region,
                                            Vm.accountName,
                                            Vm.analysisDatetimestamp,
                                            Vm.status_change_datetime,
                                            con_validated
                                              ? "valsummary"
                                              : "analysisSummary"
                                          );
                                        }}
                                      >
                                        {con_increasedCost}
                                      </a>
                                    </div>
                                  </Col>
                                </Row> */}
                              </td>

                              {statusLandOn !== null ? (
                                <td
                                  className="textfieldCentered"
                                  style={{ fontWeight: "600" }}
                                >
                                  {" "}
                                  <a
                                    href="#"
                                    style={{
                                      fontSize: "14px",
                                      display: "inline-block",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.handleNAvigatetoSummary(
                                        Vm.basicVMID,
                                        Vm.name,
                                        Vm.analysisSeqNum,
                                        Vm.analysisDate,
                                        Vm.analysisTime,
                                        Vm.region,
                                        Vm.accountName,
                                        Vm.analysisDatetimestamp,
                                        Vm.status_change_datetime,
                                        statusLandOn
                                      );
                                    }}
                                  >
                                    <div style={{ display: "inline-flex" }}>
                                      <div>{Vm.bvmState}</div>
                                      {metricDtAvlDiv}
                                    </div>
                                    <div>{objective}</div>
                                    {Vm.isResourceChanged === true ? (
                                      <div>
                                        <i
                                          className="fa fa-info-circle"
                                          aria-hidden="true"
                                          data-toggle="tooltip"
                                          data-placement="right"
                                          title={
                                            isValidated
                                              ? message.VMRersourceChangedFLagInfoTextValidated
                                              : this.state.isAsp
                                              ? message.ASPRersourceChangedFLagInfoTextAnalyzed
                                              : this.state.entityType === "db"
                                              ? message.DBRersourceChangedFLagInfoTextAnalyzed
                                              : message.VMRersourceChangedFLagInfoTextAnalyzed
                                          }
                                        />
                                      </div>
                                    ) : null}
                                  </a>
                                  {platform !== "" ? (
                                    <div style={{ fontSize: "12px" }}>
                                      {platform}
                                    </div>
                                  ) : null}
                                  <div style={{ fontSize: "12px" }}>
                                    {Vm.bvmState === "ANALYZED" ||
                                    Vm.bvmState === "VERIFIED" ||
                                    Vm.bvmState === "OPTIMIZED"
                                      ? Vm.metricDataDurationUsed !== null
                                        ? `(${transformedMetricDataDurationUsed})`
                                        : null
                                      : null}
                                  </div>
                                  {Vm.isSameResizePreference === false ? (
                                    <i
                                      style={{ marginLeft: "10px" }}
                                      className="fa fa-info-circle"
                                      aria-hidden="true"
                                      data-toggle="tooltip"
                                      data-placement="right"
                                      title={message.AnalyzedPreferenceMsg}
                                    />
                                  ) : null}
                                </td>
                              ) : (
                                <td
                                  className="textfieldCentered"
                                  style={{ fontWeight: "600" }}
                                >
                                  <div style={{ display: "inline-flex" }}>
                                    <div>{Vm.bvmState}</div>
                                    {metricDtAvlDiv}
                                  </div>
                                  <div>{objective}</div>
                                  {platform !== "" ? (
                                    <div style={{ fontSize: "12px" }}>
                                      {platform}
                                    </div>
                                  ) : null}
                                </td>
                              )}
                              <td className="textfieldCentered dateclass">
                                {getStringDate(Vm.status_change_datetime)}
                              </td>
                              <td
                                className="textfieldCentered"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                <Button
                                  color="link"
                                  onClick={() =>
                                    this.handleNAvigatetoSummary(
                                      Vm.basicVMID,
                                      Vm.name,
                                      Vm.analysisSeqNum,
                                      Vm.analysisDate,
                                      Vm.analysisTime,
                                      Vm.region,
                                      Vm.accountName,
                                      Vm.analysisDatetimestamp,
                                      Vm.status_change_datetime,
                                      null
                                    )
                                  }
                                >
                                  {this.state.isAsp
                                    ? "View ASP Summary"
                                    : this.state.entityType === "db"
                                    ? "View DB Summary"
                                    : "View VM Summary"}{" "}
                                  {Vm.isResourceChanged === true ? (
                                    <i
                                      className="fa fa-info-circle"
                                      aria-hidden="true"
                                      data-toggle="tooltip"
                                      data-placement="right"
                                      title={
                                        isValidated
                                          ? message.VMRersourceChangedFLagInfoTextValidated
                                          : this.state.isAsp
                                          ? message.ASPRersourceChangedFLagInfoTextAnalyzed
                                          : this.state.entityType === "db"
                                          ? message.DBRersourceChangedFLagInfoTextAnalyzed
                                          : message.VMRersourceChangedFLagInfoTextAnalyzed
                                      }
                                    />
                                  ) : null}
                                </Button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </Table>
              </div>
              <Modal
                isOpen={this.state.modalPDF}
                toggle={this.togglePDF}
                size="lg"
                backdrop={"static"}
              >
                <ModalHeader
                  id="modalheader_list"
                  cssModule={{ "modal-title": "modalHeader" }}
                  toggle={this.togglePDF}
                >
                  <div
                    className="modalCompanyName"
                    dangerouslySetInnerHTML={{
                      __html: message.SerraLabsModalHeader,
                    }}
                  />{" "}
                  <div className="modalTitle">Generate PDF</div>
                </ModalHeader>
                <ModalBody>
                  <div style={{ marginLeft: "40%", display: "inline-flex" }}>
                    <div>
                      <div className="chk_container">
                        <Input
                          type="checkbox"
                          className="form-check-input-nontable"
                          onChange={(event) => this.handleOverallSummary(event)}
                        />
                        <span className="custom-checkbox"></span>
                      </div>
                    </div>
                    <div>Overall Summary</div>
                  </div>
                  <div style={{ marginLeft: "40%", display: "inline-flex" }}>
                    <div>
                      <div className="chk_container">
                        <Input
                          type="checkbox"
                          className="form-check-input-nontable"
                          onChange={(event) =>
                            this.handleIndividualSummary(event)
                          }
                        />
                        <span className="custom-checkbox"></span>
                      </div>
                    </div>
                    <div>With individual summary and details</div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    style={{ height: "auto" }}
                    id={
                      this.state.isIndividual === false &&
                      this.state.isOverall === false
                        ? "PPU_listdisabled"
                        : "PPU_list"
                    }
                    onClick={() => this.handlePDF()}
                    disabled={!this.state.isOverall && !this.state.isIndividual}
                  >
                    Generate PDF
                  </Button>
                  <Button className="btn-cancel" onClick={this.togglePDF}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
              <a id="pdfid" />
            </div>
          </div>
        </div>
        {this.state.errorPopup === true ? (
          <SerraError
            Errordata={this.state.errormessage}
            ErrorPopup={this.state.errorPopup}
            setErrorMethod={this.setErrorPopupState}
            isError={this.state.isErrorHeader}
          ></SerraError>
        ) : (
          ""
        )}
        {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}
      </div>
    );
  }
}
export default AssesmentReport;
