import React, { Component } from "react";
import { Alert } from "reactstrap";
import Signin from "./component/SinginComponent/Signin";
import Signup from "./component/SignupComponent/Signup";
import AccountCreation from "./component/AccountComponent/AccountCreation";
import Terms from "./component/TermsComponent/TermsConditions";
import PrivacyPolicy from "./component/TermsComponent/PrivacyPolicy";
import Help from "./component/TermsComponent/Help";
import HelpAWS from "./component/TermsComponent/HelpAWS";
import Signupsuccess from "./component/SignupSuccessComponent/Signupsuccess";
import Successmail from "./component/SuccessverifyComponent/Successmail";
import Subscription from "./component/CardComponet/Cards";
import Summary from "./component/AnalysisComponent/Summary";
import AspSummary from "./component/AspAnalysisComponents/AspSummary";
import Overview from "./component/AnalysisComponent/Overview";
import CostHealthDetails from "./component/AnalysisComponent/CostHealthDetails";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import "./App.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import { Router, Switch, Route } from "react-router-dom";
import Forgot from "./component/SinginComponent/ForgotPassword";
import { createBrowserHistory as createHistory } from "history";
import ReactGA from "react-ga4";
import ManageOptimiations from "./component/PPUComponent/ManageOptimizations";
import ManageCleanup from "./component/Cleanup/ManageCleanup";
import ManageParking from "./component/Parking/ManageParking";
import Dashboard from "./component/Dashboard/Dashboard";
import ManageAccount from "./component/PPUComponent/MetricsDataSource";
import Report from "./component/PPUComponent/AssesmentReport";
import CleanupReport from "./component/Cleanup/CleanupReport";
import ParkingReport from "./component/Parking/ParkingReport";
import SerraError from "./component/CommonComponent/Error";
import CacheBuster from "./CacheBuster";
import * as Constdata from "./Constants/ApiConstants";
import * as message from "./Constants/MessageConstant";
import * as SockJS from "sockjs-client";
import * as Stomp from "@stomp/stompjs";
import "./component/SinginComponent/style.css";
import SerraLoader from "./component/CommonComponent/Loader";
import { connect } from "react-redux";
import { reset } from "./reducers/dataSourceResSlice";
import { setCompanyDetails } from "./reducers/companyDetailsSlice";
import OrganizationAnalysisHistory from "./component/Organization/OrganizationAnalysisHistory";
import GetStarted from "./component/free_trail_sign_up/GetStarted";
import StartAnalysis from "./component/free_trail_sign_up/StartAnalysis";

const history = createHistory();
ReactGA.initialize(message.MeasurementId);
history.listen((location, action) => {
  ReactGA.send({
    hitType: "pageview",
    page: location.pathname + location.search,
    title: "",
  });
});
class App extends Component {
  constructor(props) {
    super(props);
    this.processLogin = this.processLogin.bind(this);
    this.processLogout = this.processLogout.bind(this);
    this.state = {
      hidden: false,
      isLogin: false,
      errorPopup: false,
      showPlanUpgradeModal: false,
      showFreeTrialPlanUpgradeModal: false,
      showForcedLogoutModal: false,
      isDesktopwidth: false,
      isDesktopHeight: false,
      alertMsg: false,
      showWarning: false,
      lastSessionFetch: 0,
      modalLoader: false,
      errorPopupDataSource: false,
      errormessage: "",
      isErrorHeader: true,
      subTitle: "",
      orgInfo: null,
    };
    this.logout = this.logout.bind(this);
    this.warn = this.warn.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);
    this.setNewTimeout = this.setNewTimeout.bind(this);
    this.clearOldTimeout = this.clearOldTimeout.bind(this);
    this.destroy = this.destroy.bind(this);
    this.registerListners = this.registerListners.bind(this);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.setWarningPopupState = this.setWarningPopupState.bind(this);
    this.setForceLogoutPopupState = this.setForceLogoutPopupState.bind(this);
    this.connectSocket = this.connectSocket.bind(this);
    this.disconnectSocket = this.disconnectSocket.bind(this);
    this.planUpgraded = this.planUpgraded.bind(this);
    this.forceLogout = this.forceLogout.bind(this);

    this.updatePredicate = this.updatePredicate.bind(this);
  }
  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event });
    window.location.pathname = "/";
  };
  setWarningPopupState = (event) => {
    this.setState({ showWarning: event });
    if (event === false) {
      this.resetTimeout();
      clearTimeout(this.logoutTimeout);
    }
  };
  setPPUUpgradePopupState = (event) => {
    this.setState({ showPlanUpgradeModal: event });
    window.location.pathname = "/";
  };

  setFreeTrialUpgradePopupState = (event) => {
    this.setState({ showForcedLogoutModal: event });
    window.location.pathname = "/";
  };
  setForceLogoutPopupState = (event) => {
    this.setState({ showFreeTrialPlanUpgradeModal: event });
    window.location.pathname = "/";
  };
  planUpgraded = (notification) => {
    console.log("Notification: " + notification.body);
    if (notification !== undefined && notification.body !== undefined) {
      if (notification.body === "plan_upgraded") {
        this.logout(false);

        this.setState({ showPlanUpgradeModal: true });
      }
      if (notification.body === "plan_upgraded_free") {
        this.logout(false);
        this.setState({ showFreeTrialPlanUpgradeModal: true });
      }
      if (notification.body === "force_logout") {
        this.setState({ showForcedLogoutModal: true });
        setTimeout(this.forceLogout, 10000);
        console.log("force logout setstate done");
      }
    }
  };

  forceLogout() {
    console.log("logout function calling");
    this.logout(false);
  }

  componentDidMount() {
    const companyDetails = {
      LOGO: require(`./images/${process.env.REACT_APP_LOGO}`),
      CompanyName: process.env.REACT_APP_CompanyName,
      Favicon: require(`./images/${process.env.REACT_APP_Favicon}`),
      Website: process.env.REACT_APP_Website,
      Support: process.env.REACT_APP_Support,
      ServiceName: process.env.REACT_APP_ServiceName,
      ServiceNameFullName: process.env.REACT_APP_ServiceNameFullName,
      TermsAndConditions: process.env.REACT_APP_TermsAndConditions,
      PrivacyPolicy: process.env.REACT_APP_PrivacyPolicy,
    };

    var link = document.createElement("link");
    link.id = "favicon";
    link.type = "image/x-icon";
    link.rel = "icon";
    link.href = companyDetails.Favicon + "?=" + Math.random();
    document.head.appendChild(link);
    document.title = companyDetails.CompanyName;
    this.props.setCompanyDetailsRes(companyDetails);

    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
    this.processLogin();

    if (window.location.pathname !== "/signup") {
      this.resetTimeout("onload");
    }
  }

  componentWillUnmount() {
    this.disconnectSocket();
    this.destroy();
    window.removeEventListener("resize", this.updatePredicate);
  }
  updatePredicate() {
    let isResolution = false;
    if (window.innerWidth < 1024) {
      isResolution = true;
    }
    //this.setState({ isDesktopwidth: window.innerWidth >= 1024,isDesktopHeight:window.innerHeight <=768, isResolution:isResolution,alertMsg:isResolution});
    this.setState({
      isDesktopwidth: true,
      isDesktopHeight: false,
      isResolution: false,
      alertMsg: false,
    });
  }
  connectSocket() {
    const socket = new SockJS("/live");
    var callback = this.planUpgraded;
    this.stompClient = new Stomp.Client({
      webSocketFactory: function () {
        return socket;
      },
      debug: function (str) {},
      onConnect: function () {
        this.subscribe("/user/plan/notify", callback);
      },
      reconnectDelay: 1000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });
    this.stompClient.activate();
  }
  disconnectSocket() {
    if (this.stompClient !== undefined) {
      this.stompClient.deactivate();
    }
  }

  registerListners = async (extendSession) => {
    //alert(window.location.pathname);
    if (!extendSession || extendSession === undefined) {
      this.events = [
        "load",
        //"mousemove",
        //"mousedown",
        "click",
        "scroll",
      ];
      for (var i in this.events) {
        window.addEventListener(this.events[i], this.resetTimeout);
      }
    } else {
      this.resetTimeout();
    }
  };
  clearOldTimeout() {
    if (this.warnTimeout) {
      clearTimeout(this.warnTimeout);
    }
    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
    }
  }
  setNewTimeout() {
    this.clearOldTimeout();
    this.warnTimeout = setTimeout(this.warn, 14 * 60 * 1000);
    this.logoutTimeout = setTimeout(this.logout, 15 * 60 * 1000);
  }
  resetTimeout(onloadCall = false) {
    if (
      (this.state.isLogin && !this.state.showWarning) ||
      onloadCall === "onload"
    ) {
      var diff = new Date().getTime() - this.state.lastSessionFetch;
      if (diff > 600000 || onloadCall === "onload") {
        //10 minutes
        fetch(Constdata.SESSIONACTIVE_URL, {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
          },
        })
          .then((response) => {
            console.log("SESSIONACTIVE_URL response", response);
            if (response?.redirected && window.location.pathname === "/signup") {
              window.location.pathname = response.url + "signup";
            }
            return response.json();
          })
          .then(async (responseJson) => {
            if (responseJson.success === true) {
              this.setState({ orgInfo: responseJson.orgInfo });
              await this.setNewTimeout();
              await this.setState({
                isLogin: true,
                lastSessionFetch: new Date().getTime(),
              });
            } else {
              await this.setState({ isLogin: false });
            }
          })
          .catch((error) => {
            this.setState({ isLogin: false });
          });
      }
    } else {
      //alert("NOt Logged In");
    }
  }
  warn() {
    if (this.state.showWarning === false) {
      this.setState({ showWarning: true });
    }
  }
  logout(showErrorPopup) {
    if (this.state.isLogin) {
      this.disconnectSocket();
      window.localStorage.removeItem("loggedInUser");
      window.localStorage.removeItem("resendEmail");
      fetch(Constdata.LOGOUT_URL, {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          let showPopup =
            showErrorPopup !== undefined && showErrorPopup === false;
          if (responseJson.success === true) {
            this.clearOldTimeout();
            this.setState({ isLogin: false, showWarning: false });
            localStorage.setItem("errorPopup", showPopup ? false : true);
            window.location.pathname = "/";
          } else if (responseJson.success === false) {
            this.clearOldTimeout();
            this.setState({ isLogin: false });
          } else if (
            responseJson.status === 403 ||
            responseJson.status === 401
          ) {
            this.clearOldTimeout();
            this.setState({ isLogin: false, showWarning: false });
            localStorage.setItem("errorPopup", showPopup ? false : true);
            window.location.pathname = "/";
          } else {
            this.clearOldTimeout();
            this.setState({ isLogin: false, showWarning: false });
            localStorage.setItem("errorPopup", showPopup ? false : true);
            window.location.pathname = "/";
          }
        })
        .catch((error) => {
          this.clearOldTimeout();
          this.setState({ isLogin: false, showWarning: false });
          window.location.pathname = "/login";
        });
    }
  }
  destroy() {
    this.clearOldTimeout();
    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }
  processLogin = async (fromSignIn) => {
    if (fromSignIn !== undefined && fromSignIn === true) {
      await this.setState({ isLogin: true });
      await this.setNewTimeout();
      await this.connectSocket();
    }
  };
  processLogout = async () => {
    this.disconnectSocket();
    await this.logout(false);
    await this.setState({ isLogin: false });
  };

  login = () => {
    const payload = JSON.parse(window.localStorage.getItem("userPayload"));
    fetch(Constdata.LOGIN_URL, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          await this.state.processLogin(true);

          window.localStorage.removeItem("userPayload");
          this.setState({
            visible: responseJson.success,
            data: responseJson.data,
            modalLoader: false,
          });
          this.state.aliasUsername !== ""
            ? this.setState({ loggedInUser: this.state.aliasUsername })
            : this.setState({ loggedInUser: this.state.loggedInUser });

          if (
            (responseJson.orgInfo.plan === "Free Trial" ||
              responseJson.orgInfo.plan === "FREE_TRIAL") &&
            responseJson.orgInfo.loggedInUser !== "support@serralabs.com"
          ) {
            this.props.history.push({
              pathname: `/free-trial`,
              state: {
                providerName: responseJson.orgInfo.providerName,
                step: 1,
              },
            });
          } else {
            if (responseJson.data.landingPage === "report") {
              this.props.history.push({
                pathname: `/${
                  responseJson.orgInfo.loggedInUser === "support@serralabs.com"
                    ? "dashboard"
                    : responseJson.data.landingPage
                }`,
                state: { data: this.state.data.data },
              });
            } else {
              this.props.history.push({
                pathname: `/${
                  responseJson.orgInfo.loggedInUser === "support@serralabs.com"
                    ? "dashboard"
                    : responseJson.data.landingPage
                }`,
                state: { data: this.state.data },
              });
            }
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  static getDerivedStateFromProps(props, state) {
    var response = props.dataSourceResponse;
    if (response) {
      if (response.success === true) {
        if (response.testConfig === true) {
          const capitalizeFirstLetter = response.message.split(" ");
          for (let i = 0; i < capitalizeFirstLetter.length; i++) {
            capitalizeFirstLetter[i] =
              capitalizeFirstLetter[i][0].toUpperCase() +
              capitalizeFirstLetter[i].substr(1);
          }
          state.isErrorHeader = true;
          state.errorPopupDataSource = true;
          state.errormessage = capitalizeFirstLetter.join(" ");
          state.subTitle = "Test Successful";
        } else {
          state.errorPopupDataSource = true;
          state.errormessage = response.message;
          state.subTitle = "";
        }
      } else if (response.success === false) {
        if (response.message.includes("Application (ClientID) Role Issue")) {
          state.errorPopupDataSource = true;
          state.errormessage =
            response.orgInfo.providerName === "AZURE"
              ? message.AzureTestErrorMsg
              : message.AwsTestErrorMsg;
          state.subTitle = "";
        } else {
          state.errorPopupDataSource = true;
          state.errormessage = response.message;
          state.subTitle = "";
        }
      } else if (response.status === 403 || response.status === 401) {
        state.isRedirect = true;
        state.errorPopupDataSource = true;
        state.errormessage = message.SessionExpiredText;
        state.isErrorHeader = false;
        state.subTitle = "";
      } else {
        state.errorPopupDataSource = true;
        state.errormessage = message.ServerError;
        state.isErrorHeader = false;
        state.subTitle = "";
      }
    }
  }

  setErrorPopupStateDataSource = (event) => {
    this.props.resetDataSourceRes();
    this.setState({ errorPopup: event, errorPopupDataSource: false });
    if (this.state.isRedirect) {
      this.props.history.push("/");
    }
  };

  render() {
    if (localStorage.getItem("errorPopup")) {
      this.setState({ errorPopup: true });
      localStorage.removeItem("errorPopup");
    }
    return (
      <>
        {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}

        <Router history={history}>
          <CacheBuster />
          {this.state.showWarning === true ? (
            <SerraError
              Errordata={message.SessionExpireWarnText}
              ErrorPopup={this.state.showWarning}
              setErrorMethod={this.setWarningPopupState}
              btnName="Continue"
              subTitle="Warning Message"
              isError={true}
            ></SerraError>
          ) : (
            ""
          )}
          {this.state.errorPopup === true ? (
            <SerraError
              Errordata={message.SessionExpiredText}
              ErrorPopup={this.state.errorPopup}
              setErrorMethod={this.setErrorPopupState}
              isError={true}
            ></SerraError>
          ) : (
            ""
          )}
          {this.state.showPlanUpgradeModal === true ? (
            <SerraError
              Errordata={message.PPUApprovedLogoutMessage}
              ErrorPopup={this.state.showPlanUpgradeModal}
              setErrorMethod={this.setPPUUpgradePopupState}
              isError={true}
              subTitle="Successful Paid Plan Authorization"
            ></SerraError>
          ) : (
            ""
          )}
          {this.state.showFreeTrialPlanUpgradeModal === true ? (
            <SerraError
              Errordata={message.FreeTrialApprovedLogoutMessage}
              ErrorPopup={this.state.showFreeTrialPlanUpgradeModal}
              setErrorMethod={this.setFreeTrialUpgradePopupState}
              isError={true}
              subTitle="Successful Free Trial Authorization"
              modalSize="lg"
            ></SerraError>
          ) : (
            ""
          )}
          {this.state.errorPopupDataSource === true ? (
            <SerraError
              Errordata={this.state.errormessage}
              ErrorPopup={this.state.errorPopupDataSource}
              setErrorMethod={this.setErrorPopupStateDataSource}
              isError={this.state.isErrorHeader}
              subTitle={this.state.subTitle}
            ></SerraError>
          ) : (
            ""
          )}
          {this.state.isResolution === false ? (
            <Switch>
              <Route
                history={history}
                exact
                path="/"
                render={(routeProps) => (
                  <Signin
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    processLogin={this.processLogin}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                exact
                path="/login"
                render={(routeProps) => (
                  <Signin
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    processLogin={this.processLogin}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                path="/signup"
                registerListners={this.registerListners}
                processLogut={this.processLogout}
                component={Signup}
              />
              <Route
                history={history}
                path="/accnt"
                render={(routeProps) => (
                  <AccountCreation
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    {...routeProps}
                  />
                )}
              />
              {/* <Route history={history}  path="/select" render={(routeProps) => (<Selection registerListners={this.registerListners} processLogut={this.processLogout} {...routeProps}/>)}/>         */}
              <Route history={history} path="/term" component={Terms} />
              <Route
                history={history}
                path="/privacy"
                component={PrivacyPolicy}
              />
              <Route history={history} path="/help" component={Help} />
              <Route history={history} path="/helpAWS" component={HelpAWS} />
              <Route
                history={history}
                path="/success"
                render={(routeProps) => (
                  <Signupsuccess
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                path="/verify"
                render={(routeProps) => (
                  <Successmail
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                path="/card"
                render={(routeProps) => (
                  <Subscription
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                path="/summary"
                render={(routeProps) => (
                  <Summary
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                path="/AspSummary"
                render={(routeProps) => (
                  <AspSummary
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                path="/overview"
                render={(routeProps) => (
                  <Overview
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                path="/costhealthdetails"
                render={(routeProps) => (
                  <CostHealthDetails
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                path="/updatepassword"
                render={(routeProps) => (
                  <Forgot
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                path="/dashboard"
                render={(routeProps) => (
                  <Dashboard
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                path="/manageOptimizations"
                render={(routeProps) => (
                  <ManageOptimiations
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                path="/manageCleanup"
                render={(routeProps) => (
                  <ManageCleanup
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                path="/manageParking"
                render={(routeProps) => (
                  <ManageParking
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                path="/manageDataSource"
                render={(routeProps) => (
                  <ManageAccount
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                path="/report"
                render={(routeProps) => (
                  <Report
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                path="/cleanupReport"
                render={(routeProps) => (
                  <CleanupReport
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                path="/parkingReport"
                render={(routeProps) => (
                  <ParkingReport
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                path="/orgAnalysisHistory"
                render={(routeProps) => (
                  <OrganizationAnalysisHistory
                    registerListners={this.registerListners}
                    processLogut={this.processLogout}
                    {...routeProps}
                  />
                )}
              />
              <Route
                history={history}
                exact
                path="/free-trial"
                render={(routeProps) => (
                  <GetStarted
                    {...routeProps}
                    orgInfo={this.state.orgInfo}
                    processLogut={this.processLogout}
                    registerListners={this.registerListners}
                  />
                )}
              />
              <Route
                history={history}
                exact
                path="/start-analysis"
                render={(routeProps) => (
                  <StartAnalysis
                    {...routeProps}
                    orgInfo={this.state.orgInfo}
                    processLogut={this.processLogout}
                    registerListners={this.registerListners}
                  />
                )}
              />
            </Switch>
          ) : (
            <Alert color="danger" isOpen={this.state.alertMsg}>
              <div
                dangerouslySetInnerHTML={{ __html: message.ResoltionMessage }}
              />
            </Alert>
          )}
        </Router>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dataSourceResponse: state.dataSourceRes.dataSourceResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetDataSourceRes: (data) => dispatch(reset(data)),
    setCompanyDetailsRes: (data) => dispatch(setCompanyDetails(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
