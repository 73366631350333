
import React, { Component } from "react";
import { Button, Row, Label } from "reactstrap";
import * as Constdata from "../../Constants/ApiConstants";
import * as message from "../../Constants/MessageConstant";
import SerraLoader from "../CommonComponent/Loader";
import SerraError from "../CommonComponent/Error";

class CurrencySelection extends Component {
  constructor(props) {
    super(props);
    this.saveCurrencyCode = this.saveCurrencyCode.bind(this);
    this.setErrorPopupState = this.setErrorPopupState.bind(this);
    this.changecurrencyCode = this.changecurrencyCode.bind(this);
    this.state = {
      enableSave: false,
      errorPopup: false,
      modalLoader: false,
      isErrorHeader: true,
      errormessage: "",
      isRedirect: false,
      provider: props.providerName,
      userName: props.userName,
      isFreeTrial: props.isFreeTrial,
      currencyCode: "USD",
    };
  }

  setErrorPopupState = (event) => {
    this.setState({ errorPopup: event });
    if (this.state.isRedirect) {
      this.props.history.push("/");
    }
  };

  saveCurrencyCode() {
    this.setState({ modalLoader: true });

    const reqPayload = new URLSearchParams({
      currencyCode: this.state.currencyCode,
    });

    fetch(`${Constdata.SET_USER_CURRENCY_URL}?${reqPayload}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({ modalLoader: false });
        this.props.handleCurrencySelectionRequest(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async componentDidMount() {
    await this.setState({ modalLoader: true });
    this.props.registerListners(true);
    fetch(Constdata.GET_USER_CURRENCY_URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.success === true) {
          await this.setState({
            modalLoader: false,
            currencyCode: responseJson.data,
          });
        } else if (responseJson.status === 403 || responseJson.status === 401) {
          await this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.SessionExpiredText,
            isErrorHeader: false,
          });
        } else {
          await this.setState({
            modalLoader: false,
            errorPopup: true,
            errormessage: message.ServerError,
            isErrorHeader: false,
            isRedirect: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          modalLoader: false,
          errorPopup: true,
          errormessage: message.CacheErrorText,
          isErrorHeader: false,
          isRedirect: false,
        });
      });
  }
  changecurrencyCode(e) {
    const value = e.target.value;
    this.setState({ currencyCode: value });
  }

  render() {
    return (
      <div>
        <div
          className="container-verf container-verf-margin pageBgWhite"
          style={{ borderRadius: "10px" }}
          role="main"
        >
          <div className="frm-container">
            <Row
              style={{
                justifyContent: "center",
                borderBottom: "1px solid #c9c9c9",
                paddingBottom: "30px",
              }}
            >
              <div className="w-50 text-center">
                <Label className="pr-2">Currency Selection :</Label>
                <select
                  value={this.state.currencyCode}
                  onChange={this.changecurrencyCode}
                  style={{ height: "40px", width: "15%" }}
                >
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="GBP">GBP</option>
                  <option value="INR">INR</option>
                </select>
              </div>
            </Row>

            <div
              class="btn-toolbar pageBgWhite"
              role="toolbar"
              aria-label="Toolbar with button groups"
              style={{
                display: "flex",
                padding: "20px",
                marginTop: "20px",
                justifyContent: "flex-end",
                borderRadius: "0px 0px 10px 10px",
              }}
            >
              <div class="mr-2" role="group" aria-label="Third group">
                <Button
                  id="confirmbtn_list"
                  onClick={this.saveCurrencyCode}
                  className="btn-detils"
                >
                  Save
                </Button>
              </div>
              <div role="group" aria-label="First group">
                <Button
                  className="btn-cancel btn btn-light"
                  onClick={this.props.toggle}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
        {this.state.errorPopup === true ? (
          <SerraError
            Errordata={this.state.errormessage}
            ErrorPopup={this.state.errorPopup}
            setErrorMethod={this.setErrorPopupState}
            isError={this.state.isErrorHeader}
          ></SerraError>
        ) : (
          ""
        )}
        {this.state.modalLoader === true ? <SerraLoader></SerraLoader> : ""}
      </div>
    );
  }
}

export default CurrencySelection;
